import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ChannelIcon } from "../channels/components/channel-icon";

import { MomentCardActions } from "../moment-card/components/moment-card-actions";
import { SegmentChip } from "../moment-card/components/moment-card-chip";
import { momentWhen, momentWhenIcon } from "../moment-card/components/moment-card-when";
import { statusToPill } from "../moment-card/utils";
import { AvailableColumns, MomentTableProps } from "./types";
import { whenComparator } from "./when-comparator";

const columnFactories: Record<
  keyof AvailableColumns,
  (reload?: () => void) => GridColDef<MomentTableProps>
> = {
  status: (): GridColDef<MomentTableProps, MomentTableProps["status"]> => ({
    field: "status",
    headerName: "Status",
    width: 100,
    sortable: false,
    disableColumnMenu: true,

    renderCell: ({ value }) => {
      return value ? <Box sx={{ zIndex: "mobileStepper" }}>{statusToPill(value)}</Box> : undefined;
    },
  }),
  title: (): GridColDef<MomentTableProps, MomentTableProps["title"]> => ({
    field: "title",
    headerName: "Title",
    minWidth: 200,
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    valueGetter: (_, row) => {
      if (row.type === "moment") {
        return row.data.title || "";
      }
    },
  }),
  when: (): GridColDef<MomentTableProps, MomentTableProps["when"]> => ({
    field: "when",
    headerName: "When",
    minWidth: 250,
    flex: 2,
    sortable: true,
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (_, row) => row.data,
    sortComparator: whenComparator,
    renderCell: ({ row }) => {
      return (
        row.type === "moment" && (
          <Box sx={{ display: "inline-block" }}>
            {momentWhenIcon(row.data)}
            {momentWhen(row.data)}
          </Box>
        )
      );
    },
  }),
  segment: (): GridColDef<MomentTableProps, MomentTableProps["segment"]> => ({
    field: "segment",
    headerName: "Segment",
    minWidth: 120,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      if (row.type === "moment") {
        const { segment, target_manager } = row.data;
        return <SegmentChip segmentName={segment?.name} sendToManager={target_manager} />;
      }
    },
  }),
  channel: (): GridColDef<MomentTableProps, MomentTableProps["channel"]> => ({
    field: "channel",
    headerName: "Channel",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => <ChannelIcon channel={value} />,
  }),
  actions: (reload): GridColDef<MomentTableProps, MomentTableProps["actions"]> => ({
    field: "actions",
    headerName: "",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
    renderCell: ({ row }) => {
      if (row.type === "moment") {
        return (
          <MomentCardActions
            moment={row.data}
            small
            switchable
            justifyContent="flex-start"
            showSequenceIcon
            {...(reload && { reload })}
          />
        );
      }
    },
  }),
};

export const useColumns = (
  {
    reload,
    columns,
  }: {
    reload?: () => void;
    columns: (keyof AvailableColumns)[];
  } = { columns: [] },
): GridColDef<MomentTableProps>[] => columns.map((column) => columnFactories[column](reload));
