import { Box, Skeleton } from "@mui/material";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useAnalytics } from "../analytics/analytics";
import { Dimensions } from "../design-huddle/types";

export type EditHandler = (params: { page: number }) => void;

const useContainerDimensions = (
  myRef: RefObject<HTMLDivElement>,
): { width: number; height: number } => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = (): Dimensions => ({
      width: myRef.current?.offsetWidth || 0,
      height: myRef.current?.offsetHeight || 0,
    });

    const handleResize = (): void => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export const ContentPreviewImage: FC<{
  image: Loadable<Promise<string | null>>;
  ratio: number;
  page: number;
  onEdit: EditHandler;
}> = ({ image, page, onEdit, ratio }) => {
  const { gaEvent } = useAnalytics();
  const ref = useRef<HTMLDivElement>(null);

  const dimensions = useContainerDimensions(ref);

  const launchProject = useCallback(async () => {
    gaEvent("multiformat_button", { action: "click", value: `Edit Position ${page}` });

    return new Promise(() => {
      onEdit({
        page,
      });
    });
  }, [gaEvent, page, onEdit]);

  if (image.state === "hasError") {
    return null;
  }

  return (
    <Box
      ref={ref}
      data-testid="create-content-preview-image"
      sx={[
        (theme) => ({
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: "transparent",
          position: "relative",
          "&:hover": {
            borderColor: theme.palette.primary.main,
          },
          backgroundImage: `url(${image.state === "hasData" ? image.data : ""})`,
          boxSizing: "content-box",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundOrigin: "content-box",
          ...(image &&
            image.state === "hasData" &&
            image.data && {
              cursor: "pointer",
            }),
          height: dimensions.width * ratio + "px",
        }),
      ]}
      role={image.state === "loading" ? "button" : undefined}
      onClick={() => void launchProject()}
    >
      {image.state === "loading" && (
        <Skeleton
          sx={{ width: "100%", height: "100%" }}
          data-testid="skeleton"
          variant="rectangular"
        />
      )}
    </Box>
  );
};
