import { Alert, AlertTitle, LinearProgress, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteTabs } from "../../modules/generic/components/route-tabs";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { ManageDefaultSegmentButton } from "../../modules/segment/components/manager/default-segment-manager";
import { SegmentCrudDataGrid } from "../../modules/segment/components/manager/segment-crud-data-grid";
import { useSegmentsWithRelatedMoments } from "../../modules/segment/server/use-segments";
import { usePermissions } from "../../server/auth/use-permissions";

const SegmentPage: FC<{ is_recipient_type: boolean }> = ({ is_recipient_type }) => {
  const { t } = useTranslation();
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const {
    error,
    loading,
    data: segments,
    reload,
  } = useSegmentsWithRelatedMoments({ is_recipient_type });

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>{t("Something went wrong")}</AlertTitle>
      </Alert>
    );
  }

  if (loading) return <LinearProgress />;

  return (
    <Stack direction="column" spacing={1}>
      {!is_recipient_type && (
        <Stack direction="row-reverse">
          <ManageDefaultSegmentButton />
        </Stack>
      )}
      <SegmentCrudDataGrid
        segments={segments}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
        is_recipient_type={is_recipient_type}
        reload={reload}
      />
    </Stack>
  );
};

const ManageSegmentsPage: FC = () => {
  const { t } = useTranslation();
  const perms = usePermissions();

  return (
    <Page title={t("Segments | ChangeEngine")}>
      <PageHeader>
        <PageHeading
          heading={t("Manage your Segments")}
          subheading={t("Add new and manage existing Segments")}
        />
      </PageHeader>
      <PageContent>
        <Stack>
          {perms?.ce_admin ? (
            <RouteTabs
              routeDepth={3}
              defaultPage={<SegmentPage is_recipient_type={false} />}
              tabs={[
                {
                  type: "explicit",
                  data: { name: "Segments", route: "standard" },
                  component: <SegmentPage is_recipient_type={false} />,
                },
                {
                  type: "inferred",
                  data: { name: "Audiences" },
                  component: <SegmentPage is_recipient_type={true} />,
                },
              ]}
            />
          ) : (
            <SegmentPage is_recipient_type={false} />
          )}
        </Stack>
      </PageContent>
    </Page>
  );
};

export default ManageSegmentsPage;
