import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useToken } from "../../../server/auth/use-token";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { authHeader } from "../../../utils/auth-header";
import { useAsyncStateCallback } from "../../../utils/hooks/use-async-state-callback";
import { UUID } from "../../../utils/uuid";
import yup from "../../../utils/yup";
import { CeTextInput } from "../../generic/components/form-fields/ce-text-field";
import { selfDriving } from "../../self-driving/self-driving";
import { BrandTone, SupabaseBrandToneService } from "../server/brand-tone-service";

export const BrandScriptDialog: FC<{
  open: boolean;
  onClose: () => void;
  hasExistingTones: boolean;
  reloadTones: (id?: UUID) => void;
  existingData?: BrandTone;
}> = ({ open, onClose, hasExistingTones, reloadTones, existingData }) => {
  const { t } = useTranslation();
  const getToken = useToken();

  const methods = useForm({
    defaultValues: { name: existingData?.name ?? "", data: existingData?.data ?? "" },
    resolver: yupResolver(
      yup.object({
        name: yup
          .string()
          .required(t("Enter the name you will use to select this tone."))
          .label(t("Tone Name")),
        data: yup
          .string()
          .required(t("Enter the example script we will analyse to understand the tone."))
          .max(14000)
          .min(10)
          .label(t("Script")),
      }),
    ),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({
      name: existingData?.name ?? "",
      data: existingData?.data ?? "",
    });
  }, [existingData, reset]);

  const generateSummary = useCallback(
    async (summary: string) => {
      if (existingData?.summary !== undefined && summary === existingData?.data)
        return existingData?.summary;

      const { data, error } = await selfDriving.POST("/summarise_tone", {
        body: { data: summary },
        ...authHeader(await getToken()),
      });

      if (!data || error) {
        console.error(error || "Failed to generate tone summary");
        toast.error(t("Error summarising script"), { position: "top-right" });
        return;
      }

      return data.response;
    },
    [t, getToken, existingData],
  );

  const { loading: saving, load: submit } = useAsyncStateCallback(
    useSupabaseCallback(
      async ({ supabase }, { name, data }: { name: string; data: string }) => {
        const summary = await generateSummary(data);

        if (!summary) return;

        const service = new SupabaseBrandToneService(supabase);

        const { data: saved, error } = existingData
          ? await service.update(existingData.id, { name, data, summary })
          : await service.insert({ name, data, is_default: !hasExistingTones, summary });

        if (error) {
          console.error(error);
          toast.error(t(`Error ${existingData ? "editing" : "creating"} tone`), {
            position: "top-right",
          });
          return;
        }

        toast.success(t(`Tone ${existingData ? "edited" : "created"}`), { position: "top-right" });
        reloadTones(saved.id);
        onClose();
        reset();
        return true;
      },
      [t, onClose, hasExistingTones, reloadTones, existingData, generateSummary, reset],
    ),
    [],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form id="custom-script-form" onSubmit={handleSubmit(submit)}>
          <DialogTitle>{t(`${existingData ? "Edit" : "Create"} Tone`)}</DialogTitle>
          <DialogContent>
            {saving && (
              <Stack spacing={1} alignItems="center">
                <CircularProgress />
                <Typography variant="caption" color="text.secondary">
                  {t("Generating Tone...")}
                </Typography>
              </Stack>
            )}
            {!saving && (
              <>
                <CeTextInput name="name" margin="dense" label={t("Tone Name")} fullWidth />
                <CeTextInput
                  name="data"
                  margin="dense"
                  type="text"
                  fullWidth
                  minRows={5}
                  maxRows={8}
                  multiline
                  variant="outlined"
                  placeholder={t("Add example script here...")}
                  label={t("Script")}
                  sx={{ minWidth: 400 }}
                  {...(errors["data"] && { helperText: errors["data"]?.message })}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disabled={saving}>
              {t("Cancel")}
            </Button>
            <LoadingButton type="submit" variant="contained" color="primary" loading={saving}>
              {t("Generate Tone")}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
