import { Box } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useResetBrandingImageType } from "../../modules/brand-kit/hooks/use-reset-branding-image-type";
import { BrandingBar } from "../../modules/discover/branding-bar/branding-bar";
import { DiscoverSearchResults } from "../../modules/discover/discover-search-results";
import { useDiscoverLibraryLoader } from "../../modules/discover/hooks/use-libary-loader";
import { Page } from "../../modules/layout/components/page";
import { PageActions } from "../../modules/layout/components/page-actions";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { LiveSearchField } from "../../modules/search/live-search-field";
import { Breadcrumbs } from "../../modules/tabbed-programs/breadcrumbs";

const DiscoverSearchPage: FC = () => {
  const { t } = useTranslation();

  useResetBrandingImageType();
  useDiscoverLibraryLoader();

  return (
    <Page title={t("Discover: Search | ChangeEngine")}>
      <PageHeader>
        <Breadcrumbs crumbs={[{ to: "/discover", title: t("Discover") }, { title: t("Search") }]} />
        <PageHeading heading={t("Discover")} />
        <PageActions></PageActions>
      </PageHeader>
      <BrandingBar visualStyleSelector />
      <PageContent>
        <Box sx={{ minWidth: 200, maxWidth: 510, mt: 4 }}>
          <LiveSearchField />
        </Box>
        <DiscoverSearchResults mt={4} />
      </PageContent>
    </Page>
  );
};

export default DiscoverSearchPage;
