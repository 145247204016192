import { ApiResponse, ApiService } from "../../../server/api/client";
import { UUID } from "../../../utils/uuid";

export class MomentService extends ApiService {
  module = "moments";

  async setActiveStatus(
    moment_id: UUID,
    active: boolean,
    validate_additional_recipients: boolean,
  ): Promise<ApiResponse<undefined>> {
    return await this.post(
      `${moment_id}/${active ? "" : "un"}publish?validate_additional_recipients=${validate_additional_recipients ? 1 : 0}`,
    );
  }

  async sendTest(
    moment_id: UUID,
    destinations: string[],
    validate_additional_recipients: boolean,
  ): Promise<ApiResponse<undefined>> {
    return await this.post(
      `${moment_id}/send-test?validate_additional_recipients=${validate_additional_recipients ? 1 : 0}`,
      { destinations },
    );
  }
}
