import {
  $getRoot,
  DecoratorNode,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import { ReactNode } from "react";

export type SerializedPaperColorNode = Spread<
  {
    hex?: string;
    type: "paper-color";
    version: number;
  },
  SerializedLexicalNode
>;

export class PaperColorNode extends DecoratorNode<ReactNode> {
  __hex?: string;

  decorate(editor: LexicalEditor): ReactNode {
    const root = editor.getRootElement();
    if (root) root.style.backgroundColor = this.__hex ?? "";
    return <></>;
  }

  static getType(): string {
    return "paper-color";
  }

  static clone(node: PaperColorNode): PaperColorNode {
    return new this(node.__hex, node.__key);
  }

  constructor(hex?: string, key?: NodeKey) {
    super(key);
    this.__hex = hex;
  }

  isSelected(): boolean {
    return false;
  }

  createDOM(): HTMLElement {
    return document.createElement("span");
  }

  updateDOM(): boolean {
    return true;
  }

  setNewHex(hex?: string): void {
    const writable = this.getWritable();
    writable.__hex = hex;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  remove(preserveEmptyParent?: boolean | undefined): void {
    return;
  }

  static importJSON(serializedNode: SerializedPaperColorNode): PaperColorNode {
    return new this(serializedNode.hex);
  }

  exportJSON(): SerializedPaperColorNode {
    return {
      hex: this.__hex,
      type: "paper-color",
      version: 1,
    };
  }
}

export function $isPaperColorNode(node: LexicalNode): node is PaperColorNode {
  return node instanceof PaperColorNode;
}

export function $createPaperColorNode(hex?: string): PaperColorNode {
  return new PaperColorNode(hex);
}

export function $getPaperColorNode(): PaperColorNode | undefined {
  const root = $getRoot();

  const children = root.getChildren();
  for (const child of children) {
    if ($isPaperColorNode(child)) {
      return child;
    }
  }
  return;
}
