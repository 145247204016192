import { MenuBook } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlProps,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash-es";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeEachWord } from "../../../utils/capitalize";
import { formatDate, fromISO8601_DATE } from "../../../utils/iso8601";
import { EventKey } from "../server/supabase-event-service";
import { EventForm } from "./event-form";

export const AddButton: FC<{ onClick: () => void; buttonAction: string }> = ({
  onClick,
  buttonAction,
}) => {
  return (
    <>
      <ButtonBase
        onClick={onClick}
        sx={{
          width: "100%",
          "&:hover": {
            backgroundColor: "action.hover",
          },
          py: 1,
          px: 3,
          justifyContent: "flex-start",
        }}
      >
        <Typography sx={{ color: "primary.main", fontWeight: "bold" }}>{buttonAction}</Typography>
      </ButtonBase>
    </>
  );
};

/** Select from events */
export const EventSelector: FC<
  FormControlProps & {
    value: EventKey | null;
    events: EventKey[];
    onChangeEvent: (event: EventKey | null, events: EventKey[]) => void;
    targetManager: boolean;
  }
> = ({ value, onChangeEvent, events, targetManager, ...props }) => {
  const { t } = useTranslation();
  const [createEventOpen, setCreateEventOpen] = useState(false);

  return (
    <>
      {events.length > 0 ? (
        <FormControl {...props}>
          <Autocomplete
            id="event-select"
            sx={{ minWidth: 390 }}
            disabled={props.disabled}
            value={events.find((e) => e.key === value?.key) ?? null}
            options={[
              ...events.filter((e) => !e.calendar),
              ...(targetManager
                ? []
                : [
                    ...events.filter((e) => e.calendar && (e.has_schedule_event || e.library)),
                    ...events.filter(
                      (e) => e.calendar && !e.has_schedule_event && value?.key === e.key,
                    ),
                    {
                      key: "create-new-event",
                      title: "",
                      calendar: true,
                      date: "",
                      has_schedule_event: true,
                    } as EventKey,
                  ]),
            ]}
            renderInput={(params) => (
              <TextField label={t("Event")} variant="standard" {...params} />
            )}
            getOptionLabel={(option) => displayText(option)}
            groupBy={(option) => (option.calendar ? "Custom Events" : "Automated Events")}
            onChange={(event, v) => onChangeEvent(v ?? null, events)}
            renderOption={(renderProps, option) =>
              option.key === "create-new-event" ? (
                <AddButton
                  key="create-new-event-button"
                  buttonAction={t("Create new event")}
                  onClick={() => setCreateEventOpen(true)}
                />
              ) : (
                <Box component="li" {...renderProps} key={option.key}>
                  {displayText(option)}
                  {option.key.endsWith("_library") && (
                    <MenuBook sx={{ paddingLeft: 1 }} color="primary" />
                  )}
                </Box>
              )
            }
          />
        </FormControl>
      ) : null}
      <Dialog open={createEventOpen} onClose={() => setCreateEventOpen(false)} maxWidth={"lg"}>
        <DialogTitle>{t("Create new event")}</DialogTitle>
        <DialogContent>
          <EventForm
            onChange={(event) => {
              const newEvent = {
                calendar: true,
                key: event.key,
                title: event.title ?? "",
                date: event.date,
                library: false,
                repeat: event.repeat === "never" ? null : event.repeat,
                allow_initial: true,
                allow_repeats: event.repeat !== "never",
                has_schedule_event: true,
              };
              const newEvents = [...events, newEvent];
              setCreateEventOpen(false);
              onChangeEvent(newEvent, newEvents);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const displayText = (event: EventKey | undefined): string => {
  if (!event) return "";
  if (!event.calendar) return capitalizeEachWord(event.title);

  const date = !isEmpty(event.date) ? formatDate(fromISO8601_DATE(event.date)!) : "";

  return event.has_schedule_event ? `${capitalizeEachWord(event.title)} (${date})` : date;
};
