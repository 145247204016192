import { Edit } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

export const TemplateImageButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<Edit />}
      sx={{
        display: "none",
        position: "absolute",
        bottom: "16px",
        right: "16px",
        zIndex: 1,
      }}
      {...props}
    />
  );
};
