import { UseApiLoaderResponse } from "../../../server/api/hooks/use-api-loader";
import { useMultiFormat } from "./use-multi-format";

export const useContentTypeOptions = (): UseApiLoaderResponse<
  { label: string; value: string }[]
> => {
  const multiformat = useMultiFormat();
  if (multiformat.loading || multiformat.error) return multiformat;
  return {
    ...multiformat,
    data: multiformat.data["content-types"].map((contentType) => ({
      label: contentType["CE App Name"] ?? contentType["Content Type"],
      value: contentType["Content Type"],
    })),
  };
};
