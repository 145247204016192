/* eslint-disable jsx-a11y/no-autofocus */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BlockerFunction, useBeforeUnload, useBlocker } from "react-router-dom";

export const LeavePageConfirm: FC<{
  children?: React.ReactNode;
  enabled: ({
    currentLocation,
    nextLocation,
    historyAction,
  }?: BlockerFunction["arguments"]) => boolean;
}> = ({ children, enabled }) => {
  const [t] = useTranslation();
  const blocker = useBlocker(enabled);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (enabled()) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      },
      [enabled],
    ),
  );

  if (blocker.state === "blocked") {
    return (
      <Dialog open={true} onClose={() => blocker.reset?.()}>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => blocker.reset?.()}>
            {t("Stay")}
          </Button>
          <Button variant="outlined" autoFocus onClick={() => blocker.proceed?.()}>
            {t("Proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};
