import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { partial } from "lodash-es";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { config } from "../../../../config";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { ChannelCard, ChannelProps } from "../../../channels/components/channel-card";
import { SupabaseCustomEmailService } from "../../../channels/email/server/supabase-custom-email-service";
import { ChannelName } from "../../../channels/types";
import { prettyChannelName } from "../../../channels/utils";
import { EmailMomentLoader } from "../../../email-moment/server/email-moment-loader";
import { SerialisedEditorDesign } from "../../../message/editor/types/serialised-editor-design";
import { SupabaseSlackAliasDefaultService } from "../../../slack/server/slack-supabase-service";
import { MomentFormMoment } from "../../types/moment-form-moment";

export const CHAT_CHANNELS: (ChannelName | undefined)[] = ["slack", "teams"];
export const EMAIL_CHANNELS: (ChannelName | undefined)[] = ["personal_email", "work_email"];
export const TEXT_CHANNELS: (ChannelName | undefined)[] = ["twilio"];

export const isEmailChannel = (channel?: ChannelName): channel is "personal_email" | "work_email" =>
  EMAIL_CHANNELS.includes(channel);

export const isChatChannel = (channel?: ChannelName): channel is "slack" | "teams" =>
  CHAT_CHANNELS.includes(channel);

export const isTextChannel = (channel?: ChannelName): channel is "twilio" =>
  TEXT_CHANNELS.includes(channel);

export const Channel: FC<{
  channels: ChannelProps[];
  disabled: boolean;
  newsletter?: boolean;
}> = ({ channels, disabled, newsletter }) => {
  const { t } = useTranslation();
  const [confirmModal, setConfirmModal] = useState<ChannelName>();

  const availableChannels = useMemo(
    () => channels.filter((c) => (newsletter ? isEmailChannel(c.name) : true)),
    [channels, newsletter],
  );

  const { control, getValues, reset } = useFormContext<MomentFormMoment>();
  useWatch({ control, name: "channel" });
  const selectedChannel = getValues("channel");

  const channel = prettyChannelName(selectedChannel);

  const handleReset = useSupabaseCallback(
    async ({ supabase }, newChannel?: ChannelName) => {
      let slack_alias_id = null;
      if (newChannel === "slack") {
        const { data } = await new SupabaseSlackAliasDefaultService(supabase).getSingle();

        if (data) slack_alias_id = data.slack_alias_id;
      }

      let from: string | null = null;
      if (newChannel && !newsletter && EMAIL_CHANNELS.includes(newChannel)) {
        const customEmailService = new SupabaseCustomEmailService(supabase);
        from = (await customEmailService.getDefault()).data?.address || config.defaultEmailFrom;
      }
      if (newChannel && CHAT_CHANNELS.includes(newChannel)) {
        if (CHAT_CHANNELS.includes(newChannel)) {
          from = slack_alias_id;
        }
      }

      const newValues = {
        channel: newChannel ?? null,
        email_moment:
          newChannel && EMAIL_CHANNELS.includes(newChannel) && newsletter
            ? await new EmailMomentLoader(supabase).loadNew()
            : null,
        chat_moment: null,
        additional_recipients: [],
        from: from,
        use_lexical_editor: !newsletter,
        design: {} as SerialisedEditorDesign,
        email_subject: null,
        body: null,
      };

      reset((values) => ({ ...values, ...newValues }), { keepDirty: true });
    },
    [reset, newsletter],
  );

  const doSwitch = useCallback(
    async (name: ChannelName) => {
      const chat_to_chat = CHAT_CHANNELS.includes(selectedChannel) && CHAT_CHANNELS.includes(name);
      const email_to_email =
        EMAIL_CHANNELS.includes(selectedChannel) && EMAIL_CHANNELS.includes(name);
      const text_to_text = TEXT_CHANNELS.includes(selectedChannel) && TEXT_CHANNELS.includes(name);
      if (selectedChannel) {
        if (name === selectedChannel) setConfirmModal(null);
        else if (chat_to_chat || email_to_email || text_to_text) {
          setConfirmModal(undefined);
          reset((values) => ({
            ...values,
            channel: name,
            additional_recipients: [],
          }));
        } else {
          setConfirmModal(name);
        }
      } else {
        await handleReset(name);
      }
    },
    [selectedChannel, setConfirmModal, handleReset, reset],
  );

  const handleChannelChange = async (name: ChannelName | null): Promise<void> => {
    await handleReset(name);
    setConfirmModal(undefined);
  };

  return (
    <>
      {availableChannels.map(({ iconSmall, buttonText, name }, key) => (
        <Box key={key} sx={{ mr: 1 }}>
          <ChannelCard
            buttonText={buttonText}
            onClick={partial((p) => {
              void doSwitch(p).catch(console.error);
            }, name)}
            name={name}
            disabled={disabled}
            selected={name === selectedChannel}
            iconSmall={iconSmall}
          />
        </Box>
      ))}

      <Dialog
        open={confirmModal !== undefined}
        onClose={() => setConfirmModal(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!confirmModal
              ? `You are about to de-select the '${channel}' content type for this Moment. You will lose your existing ${channel} content if you go ahead with this. Are you sure you wish to continue?`
              : `You are about to change the content type of this Moment from '${channel}' to '${prettyChannelName(
                  confirmModal,
                )}'. You will lose any existing ${channel} content if you go ahead with
                  this. Are you sure you wish to continue?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmModal(undefined)}>{t("Cancel")}</Button>
          <LoadingButton
            type="submit"
            variant="outlined"
            onClick={() => void handleChannelChange(confirmModal || null).catch(console.error)}
          >
            {t("Yes, I'm sure")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
