import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Tooltip,
} from "@mui/material";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useApiServiceCallback } from "../../../../server/api/client";
import { UUID } from "../../../../utils/uuid";
import { useAnalytics } from "../../../analytics/analytics";
import { MomentService } from "../../../moment/client";

export const PublishToggleAction: FC<{
  id: UUID | null;
  status: "draft" | "paused" | "finished" | "active" | "archived" | null;
  reload: (active: boolean) => void;
}> = ({ id, status, reload }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(status === "active" || status === "finished");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { gaEvent } = useAnalytics();

  const handleActiveStateChange = useApiServiceCallback(
    MomentService,
    async ({ apiService }): Promise<void> => {
      if (!id) return;
      setButtonLoading(true);
      const checked = !active;
      const response = await apiService.setActiveStatus(id, checked, true);
      if (!response.error) {
        setActive(checked);
        if (checked) {
          toast.success(t("Moment published"));
          gaEvent("publish", { event_category: "moment", moment_id: id, content_type: "card" });
        } else {
          toast.success(t("Moment un-published"));
          gaEvent("unpublish", { event_category: "moment", moment_id: id, content_type: "card" });
        }
      } else {
        toast.error(
          checked
            ? t("Something went wrong when setting the moment as active")
            : t("Something went wrong when pausing the moment"),
        );
      }
      setButtonLoading(false);
      setOpenConfirmationDialog(false);
      reload(checked);
    },
    [active, id, t],
  );

  return (
    <>
      <Tooltip title={active ? t("Unpublish moment") : t("Publish moment")}>
        <Switch
          sx={{ mt: 0.3 }}
          data-testid="moment-active-toggle"
          checked={active}
          onClick={() => setOpenConfirmationDialog(true)}
          color="success"
        />
      </Tooltip>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Update Moment")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {active
              ? t("Are you sure you wish to pause this moment?")
              : t("Are you sure you wish to make this moment active?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>{t("Cancel")}</Button>
          <LoadingButton
            type="submit"
            variant="outlined"
            loading={buttonLoading}
            onClick={() => {
              void handleActiveStateChange().catch(console.error);
            }}
          >
            {t("Yes, I'm sure")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
