import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect } from "react";
import { setOptimizelyUser } from "../../server/optimizely";
import { OrgUser } from "../../server/supabase/hooks/types";
import { getMomentEditStatus } from "../moment/hooks/use-moment-edit-status";
import { MomentFormMoment } from "../moment/types/moment-form-moment";
interface AccordionState<T> {
  channel?: T;
  details?: T;
  to?: T;
  when?: T;
  message?: T;
}

type EventNames =
  | Gtag.EventNames
  | "save_draft"
  | "save_draft_error"
  | "send_test"
  | "send_test_error"
  | "publish"
  | "publish_error"
  | "unpublish"
  | "create_moment"
  | "edit_moment"
  | "view_moment"
  | "pause_moment"
  | "archive_moment"
  | "unarchive_moment"
  | "copy_moment"
  | "accordion"
  | "launch_library_moment"
  | "discover_search"
  | "discover_branding"
  | "discover_carousel"
  | "discover_hero"
  | "discover_preview"
  | "discover_programs"
  | "carousel"
  | "inspire_me"
  | "content_type_generation"
  | "multiformat_button";

type AccordionEventParams = {
  accordion?: keyof AccordionState<boolean>;
  open?: boolean;
};

type AuthEventParams = {
  user_id?: string;
  org_id?: string;
};

type MomentEventParams = {
  moment_id?: string;
  program_slug?: string;
  template_slug?: string;
  content_type?: string;
};

interface GenericEventParams {
  type?: string;
  value?: string | number | boolean;
  action?: string;
}

type EventParams = Omit<Gtag.EventParams, "value"> &
  GenericEventParams &
  (AccordionEventParams | AuthEventParams | MomentEventParams);

type AnalyticsHelpers = {
  gaEvent: (name: EventNames, eventParams?: EventParams) => void;
};

export const useAnalytics = (): AnalyticsHelpers => {
  const gaEvent: AnalyticsHelpers["gaEvent"] = (name, eventParams): void => {
    try {
      window.gtag("event", name, eventParams);
    } catch {
      // ignore
    }
  };

  return { gaEvent };
};

export const useMomentAnalytics = (moment: MomentFormMoment): void => {
  const { gaEvent } = useAnalytics();

  const status = getMomentEditStatus(moment);

  useEffect(() => {
    switch (status) {
      case "new":
        gaEvent("create_moment", { event_category: "moment", moment_id: moment.id });
        break;
      case "draft":
        gaEvent("edit_moment", { event_category: "moment", moment_id: moment.id });
        break;
      case "published":
        gaEvent("view_moment", { event_category: "moment", moment_id: moment.id });
        break;
    }
  }, [status, moment.id, gaEvent]);
};

export const AnalyticsMetadata: FC = () => {
  const { user, isLoading } = useAuth0<OrgUser>();
  useEffect(() => {
    if (user && !isLoading) {
      setOptimizelyUser({
        id: user.sub || null,
        attributes: {
          userId: user.sub,
          accountId: user["http://changeengine.com/account_id"],
          ce_admin: user["http://changeengine.com/ce_admin"],
          super_admin: user["http://changeengine.com/super_admin"],
        },
      });

      gtag("config", "G-DQGMRLDQSN", {
        user_id: user.sub ?? null,
        user_properties: {
          org_id: user["http://changeengine.com/account_id"],
          ce_admin: user["http://changeengine.com/ce_admin"],
          super_admin: user["http://changeengine.com/super_admin"],
        },
        traffic_type: user.email?.includes("@changeengine.com") ? "internal" : "external",
      });
      // NB if the user is logged in before pendo script has downloaded + instantiated
      // pendo won't activate for them. We can address this in prod if we need to
      if (typeof window.pendo !== "undefined") {
        window.pendo.initialize({
          visitor: {
            id: user.sub,
            email: user.email || null,
            firstName: user.given_name || null,
            lastName: user.family_name || null,
          },
          account: {
            id: user["http://changeengine.com/account_id"],
          },
        });
      }
    }
  }, [user, isLoading]);
  return null;
};
