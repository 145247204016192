import { AllInclusiveRounded, CalendarMonth, TodayRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { toZonedTime } from "date-fns-tz";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TimelinePill } from "../../../modules/timeline/components/timeline-pill";
import { formatInterval } from "../../../utils/interval";
import { INTERVAL, ISO8601, formatDatetime } from "../../../utils/iso8601";

export const When: FC<{
  moment: {
    immediate: boolean | null;
    published_at: ISO8601 | null;
    timezone: string | null;
    schedule?: {
      when: string | null;
      event_key: string | null;
      interval: INTERVAL | null;
    } | null;
  };
}> = ({ moment }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography align="left" fontSize={12} color="rgb(180, 180, 180)">
        {t("When")}
      </Typography>
      <Box data-testid="when" sx={{ display: "inline-block" }}>
        {momentWhenIcon(moment)}
        {momentWhen(moment)}
      </Box>
    </>
  );
};

export function momentWhenIcon(moment: {
  immediate: boolean | null;
  published_at: ISO8601 | null;
  timezone: string | null;
  schedule?: {
    when: string | null;
    event_key: string | null;
    interval: INTERVAL | null;
  } | null;
}): ReactElement {
  if (!!moment?.schedule?.event_key && !moment.schedule.when) {
    return <AllInclusiveRounded sx={{ verticalAlign: "middle", mr: 1 }} />;
  } else if (moment.timezone) {
    return <TodayRounded sx={{ verticalAlign: "middle", mr: 1 }} />;
  } else {
    return <CalendarMonth sx={{ verticalAlign: "middle", mr: 1 }} />;
  }
}

export function momentWhen(moment: {
  immediate: boolean | null;
  published_at: ISO8601 | null;
  timezone: string | null;
  schedule?: {
    when: string | null;
    event_key: string | null;
    interval: INTERVAL | null;
  } | null;
}): string | ReactElement {
  // Immediate Moments
  if (moment.immediate) {
    const sendDate = moment.published_at;
    if (!sendDate) return "Not scheduled";

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `${formatDatetime(new Date(toZonedTime(sendDate, userTimeZone)))} (${userTimeZone})`;
  }
  // End Immediate Moments

  const timezone = moment.timezone ? ` (${moment.timezone})` : " (Recipient's local time zone)";

  // Scheduled/Event Moments
  if (!moment?.schedule) return "Not scheduled";
  if (moment.schedule.when) {
    const value = formatDatetime(new Date(moment.schedule.when));
    return value + timezone;
  }

  if (!moment.schedule?.event_key) return "Not scheduled";
  // END Scheduled/Event moments

  // Triggered Moments
  const whenPill = (
    <Button href={`/timeline/${moment.schedule.event_key}`} sx={{ m: 0, p: 0 }}>
      <TimelinePill>{moment.schedule.event_key}</TimelinePill>
    </Button>
  );

  return (
    <Box sx={{ display: "inline-block" }} component="span">
      {formatInterval(moment.schedule.interval)}&nbsp;{whenPill}
    </Box>
  );
  // END Trigger Moments
}
