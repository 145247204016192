import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToken } from "../../server/auth/use-token";
import { extractMergeTags } from "../../utils/extract-merge-tags";
import { notEmpty } from "../../utils/not-empty";
import { UUID } from "../../utils/uuid";
import { DesignHuddleCustomisations } from "../message/editor/nodes/design-huddle-node";
import { DesignHuddleEditorFrame } from "./design-huddle-editor-frame";
import { Editor, Page } from "./types";
import { useGetDesignHuddleAccessToken } from "./use-design-huddle";
import { useDesignHuddleProjectClone } from "./use-design-huddle-project-clone";
import { useDesignHuddlePublish } from "./use-design-huddle-publish-callback";
import { copyImage, getDesignHuddleRender, imageURL } from "./utils";

export const DesignHuddleEditor: FC<{
  open: boolean;
  setOpen: (v: boolean) => void;
  project_id: string;
  account_id: string;
  onSave?: (data: {
    page?: number;
    clonedProjectId?: string;
    customisations: DesignHuddleCustomisations | undefined;
  }) => void;
  page_number?: number;
  must_be_cloned?: boolean;
}> = ({ open, setOpen, project_id, account_id, onSave, page_number, must_be_cloned }) => {
  const { t } = useTranslation();
  const [editor, setEditor] = useState<Editor>();
  const [pages, setPages] = useState<Page[]>();
  const [currentPage, setCurrentPage] = useState(page_number);
  const [currentProject, setCurrentProject] = useState<{ id: string; mustBeCloned: boolean }>({
    id: project_id,
    mustBeCloned: Boolean(must_be_cloned),
  });
  const [loading, setLoading] = useState(false);
  const getToken = useToken();
  const publishTemplate = useDesignHuddlePublish();
  const cloneProject = useDesignHuddleProjectClone();
  const getAccessToken = useGetDesignHuddleAccessToken();

  const handleClose = useCallback(
    async (customisations?: DesignHuddleCustomisations) => {
      if (!editor) return setOpen(false);

      await getDesignHuddleRender({
        getToken,
        accessToken: await getAccessToken([currentProject.id]),
        accountId: account_id as UUID,
        projectId: currentProject.id,
        pageNumber: currentPage,
      });
      setOpen(false);
      setLoading(false);
      onSave?.({
        page: currentPage,
        clonedProjectId: project_id !== currentProject.id ? currentProject.id : undefined,
        customisations,
      });
      editor?.remove();
      setEditor(undefined);
    },
    [
      editor,
      getToken,
      currentPage,
      setOpen,
      onSave,
      currentProject,
      project_id,
      account_id,
      getAccessToken,
    ],
  );

  const handleContinue = useCallback(() => {
    if (!editor) return setOpen(false);

    setLoading(true);
    editor.getProjectData({}, function (_, project) {
      const page = project?.pages?.find(
        (p) => p.page_id === pages?.find((x) => x.page_number === (currentPage ?? 1))?.page_id,
      );
      const mergeTags = page?.elements
        ? extractMergeTags(
            Object.values(page.elements)
              .map((x) => x.text)
              .filter(notEmpty),
          )
        : [];
      if (mergeTags.length > 0) {
        publishTemplate(currentProject.id)
          .then((templateCode) => {
            handleClose({ templateCode, mergeTags }).catch(console.error);
          })
          .catch(console.error);
      } else {
        handleClose(undefined).catch(console.error);
      }
    });
  }, [editor, handleClose, publishTemplate, currentPage, currentProject, pages, setOpen]);

  useEffect(() => {
    if (!editor) return;

    if (currentProject.mustBeCloned) {
      cloneProject(currentProject.id)
        .then((newId) => {
          void copyImage(getToken, imageURL(account_id, currentProject.id), newId).catch(
            console.error,
          );
          editor.changeProject(newId);
          setCurrentProject({ id: newId, mustBeCloned: false });
        })
        .catch(console.error);
      return;
    }

    if (!currentProject.mustBeCloned) {
      DSHDLib.getProject(currentProject.id, (projectErr, project) => {
        if (projectErr) return console.log(projectErr);

        setPages(project.pages);
        const page = project.pages.find((p) => currentPage && p.page_number === currentPage);

        if (page) {
          editor.changePage({ page_id: page.page_id });
        }
      });
    }
  }, [editor, currentPage, currentProject, cloneProject, account_id, getToken]);

  useEffect(() => {
    if (!editor) return;

    editor.handlePageChange((_, { page_id }) => {
      setCurrentPage(pages?.find((p) => p.page_id === page_id)?.page_number);
    });
  }, [editor, pages]);

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleContinue}>
      <DialogTitle>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loading || currentProject.mustBeCloned}
            onClick={handleContinue}
          >
            {t("Continue")}
          </LoadingButton>
        </Stack>
      </DialogTitle>
      <DialogContent style={{ height: "75vh" }}>
        <DesignHuddleEditorFrame
          setEditor={setEditor}
          project_id={currentProject.id}
          state={currentProject.mustBeCloned ? "cloning" : loading ? "disabled" : "active"}
        />
      </DialogContent>
    </Dialog>
  );
};
