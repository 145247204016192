import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSupabase } from "../../../../server/supabase/hooks";
import { UUID, uuidv4 } from "../../../../utils/uuid";
import { MomentFormMoment } from "../../../moment/types/moment-form-moment";
import { useSegmentFields } from "../../hooks/use-segment-fields";
import { SupabaseSegmentService } from "../../server/supabase-segment-service";
import { Segment, makeDefaultSegment } from "../../types";
import { SegmentFormSaveErrors, SegmentModalForm } from "./segment-modal-form";

export const UnarchiveWithNewNameModal: FC<{
  open: boolean;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  segmentId: UUID | undefined;
  segmentName: string | null | undefined;
}> = ({ open, onSave, onCancel, segmentId, segmentName }) => {
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const methods = useForm<Pick<MomentFormMoment, "segment">>({
    defaultValues: { segment: { name: segmentName ?? "", id: segmentId } },
    context: {},
  });

  const { setError, control } = methods;

  const handleSave = useCallback(
    async (segment: Pick<MomentFormMoment, "segment">): Promise<void> => {
      setSaving(true);
      const errs = await onSave(segment.segment);
      if (errs) {
        Object.entries(errs).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(key, {
            type: "manual",
            message: value,
          });
        });
      }
      setSaving(false);
    },
    [onSave, setError],
  );

  return (
    <Dialog open={open} fullWidth>
      <FormProvider {...methods}>
        <DialogTitle>{t("Update Segment Name and Unarchive")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {t(
              "A Segment with this name already exists. Please update the Segment name below to a unique value.",
            )}
          </DialogContentText>
          <Controller
            name="segment.name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label={t("Segment name")}
                fullWidth
                value={field.value}
                helperText={
                  fieldState.error?.message ? t("This Segment name is already in use") : ""
                }
                error={Boolean(fieldState.error)}
                variant="standard"
                onChange={field.onChange}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={saving}>
            {t("Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={saving}
            onClick={() => {
              void methods
                .handleSubmit((segment) => handleSave(segment))()
                .catch(console.error);
            }}
          >
            {t("Rename & Unarchive")}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export const NewSegmentModal: FC<{
  open: boolean;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  isRecipientType: boolean;
}> = ({ open, onSave, onCancel, isRecipientType }) => {
  const segmentFields = useSegmentFields();
  const [segment, setSegment] = useState<{
    loading: boolean;
    data?: Pick<MomentFormMoment, "segment">;
  }>({ loading: true });

  useSupabase(
    async ({ supabase }) => {
      if (isRecipientType) {
        setSegment({
          loading: false,
          data: {
            segment: { ...makeDefaultSegment(uuidv4()), is_recipient_type: isRecipientType },
          },
        });
        return;
      }

      const service = new SupabaseSegmentService(supabase);
      const { data, error } = await service.getDefaultSegment();

      if (error) {
        console.error(error);
        return;
      }

      if (!data) {
        setSegment({
          loading: false,
          data: {
            segment: { ...makeDefaultSegment(uuidv4()), is_recipient_type: isRecipientType },
          },
        });
        return;
      }

      setSegment({
        loading: false,
        data: { segment: { ...service.cloneSegment(data, uuidv4()), description: "" } },
      });
    },
    [isRecipientType],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      {segmentFields.data && !segment.loading && (
        <SegmentModalForm
          fields={segmentFields.data}
          onSave={onSave}
          onCancel={onCancel}
          initialValues={segment.data}
          isRecipientType={isRecipientType}
        />
      )}
    </Dialog>
  );
};

export const EditSegmentModal: FC<{
  open: boolean;
  segmentId: UUID | undefined;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  disabled: boolean;
}> = ({ segmentId, open, onCancel, onSave, disabled }) => {
  const segmentFields = useSegmentFields();
  const [segment, setSegment] = useState<{
    loading: boolean;
    data: Pick<MomentFormMoment, "segment"> | undefined;
  }>(segmentId ? { loading: true, data: undefined } : { loading: false, data: undefined });

  useSupabase(
    async ({ supabase }) => {
      if (!segmentId) return;
      const { data, error } = await new SupabaseSegmentService(supabase).getSegmentData(segmentId);

      if (error) console.error(error);

      setSegment({ loading: false, data: { segment: data } });
    },
    [segmentId],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onCancel}>
      {segment.data && segmentFields.data && (
        <SegmentModalForm
          fields={segmentFields.data}
          onSave={onSave}
          onCancel={onCancel}
          disabled={disabled}
          initialValues={segment.data}
          isRecipientType={segment.data.segment?.is_recipient_type ?? false}
        />
      )}
    </Dialog>
  );
};

export const CloneSegmentModal: FC<{
  open: boolean;
  segmentId: UUID | undefined;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
}> = ({ segmentId, open, onCancel, onSave }) => {
  const segmentFields = useSegmentFields();
  const [segment, setSegment] = useState<{
    loading: boolean;
    data: Pick<MomentFormMoment, "segment"> | undefined;
  }>(segmentId ? { loading: true, data: undefined } : { loading: false, data: undefined });

  useSupabase(
    async ({ supabase }): Promise<void> => {
      if (!segmentId) return;
      const service = new SupabaseSegmentService(supabase);
      const { data, error } = await service.getSegmentData(segmentId);

      if (error || !data) {
        console.error(error);
        return;
      }

      setSegment({
        loading: false,
        data: { segment: service.cloneSegment(data, uuidv4()) },
      });
    },
    [segmentId],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      {segment.data && segmentFields.data && (
        <SegmentModalForm
          fields={segmentFields.data}
          onSave={onSave}
          onCancel={onCancel}
          initialValues={segment.data}
          isRecipientType={segment.data.segment?.is_recipient_type ?? false}
        />
      )}
    </Dialog>
  );
};
