import { Box, CardMedia, Skeleton } from "@mui/material";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { FC } from "react";
import { BrandInfoSelected } from "../../brand-kit/types";
import { TemplateType } from "../../generic/atoms/types/template";
import { LibrarySequence, LibraryTemplate } from "../library/types";
import { discoverTemplateImageFamily } from "../store";
import { DiscoverTemplate } from "../types";

interface DiscoverCardImageProps {
  template: DiscoverTemplate;
  brandKitSelections?: BrandInfoSelected;
}

export const DiscoverCardImage: FC<DiscoverCardImageProps> = ({ template, brandKitSelections }) => {
  switch (template.type) {
    case TemplateType.sequence:
      return <SequenceImage template={template} />;
    case TemplateType.moment:
      return <MomentImage template={template} brandKitSelections={brandKitSelections} />;
  }
};

const MomentImage: FC<{
  template: LibraryTemplate;
  brandKitSelections: BrandInfoSelected | undefined;
}> = ({ template, brandKitSelections }) => {
  const imageLoader = useAtomValue(
    loadable(
      discoverTemplateImageFamily({
        slug: template.slug,
        img: template.img,
        style: brandKitSelections?.branding_type || "bold",
      }),
    ),
  );

  const url =
    imageLoader?.state === "hasData" && imageLoader.data ? imageLoader.data.url : undefined;

  return (
    <>
      {url?.state === "hasData" && url.data ? (
        <CardMedia
          component="img"
          image={url.data}
          sx={{ position: "absolute", width: "100%", height: "100%" }}
          draggable={false}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{ position: "absolute", width: "100%", height: "100%" }}
        />
      )}
    </>
  );
};

const SequenceImage: FC<{ template: LibrarySequence }> = ({ template }) => {
  return (
    <Box
      sx={{ position: "absolute", left: "5%", width: "95%", height: "95%" }}
      className="sequence-image"
    >
      <Box
        className="sequence-bg-1"
        sx={(theme) => ({
          position: "absolute",
          top: "5%",
          left: "5%",
          width: "95%",
          height: "95%",
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.background.paper,
          transition: "all .3s ease-in-out",
        })}
      />
      <Box
        className="sequence-bg-2"
        sx={(theme) => ({
          position: "absolute",
          top: "2.5%",
          left: "2.5%",
          width: "95%",
          height: "95%",
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
          transition: "all .3s ease-in-out",
        })}
      />
      <CardMedia
        component="img"
        image={template.img.thumbnail}
        sx={{
          zIndex: 2,
          position: "absolute",
          width: "95%",
          height: "95%",
        }}
        draggable={false}
      />
    </Box>
  );
};
