import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const ArchiveDialog: FC<{
  title: string;
  content: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}> = ({ title, content, open, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button
          data-testid="confirm-delete-segment-button"
          color="error"
          onClick={() => {
            void onConfirm().catch(console.error);
          }}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
