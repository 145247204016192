import { createSvgIcon } from "@mui/material";

export const EmployeesManager = createSvgIcon(
  <svg height="24" width="24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 6.7767135,17.114017 q -0.774844,0 -1.267926,-0.493082 -0.493082,-0.493082 -0.493082,-1.267924 0,-0.774845 0.493082,-1.267926 0.493082,-0.493082 1.267926,-0.493082 0.774843,0 1.267925,0.493082 0.493082,0.493082 0.493082,1.267926 0,0.774842 -0.493082,1.267924 -0.493082,0.493082 -1.267925,0.493082 z m -3.756817,3.768557 v -1.103565 q 0,-0.446121 0.223061,-0.763103 0.223061,-0.316981 0.575262,-0.481341 0.786584,-0.352202 1.508597,-0.528304 0.722013,-0.1761 1.449897,-0.1761 0.727883,0 1.444025,0.181971 0.716144,0.181971 1.502727,0.522433 0.3639415,0.164359 0.5870025,0.481341 0.223061,0.316982 0.223061,0.763103 v 1.103565 z"
      opacity={0.5}
      strokeWidth={0.234801}
    />
    <path
      d="m 12,6.6394415 q -0.774844,0 -1.267925,-0.493083 -0.493083,-0.493082 -0.493083,-1.267925 0,-0.774843 0.493083,-1.267926 Q 11.225157,3.1174255 12,3.1174255 q 0.774843,0 1.267926,0.493082 0.493082,0.493083 0.493082,1.267926 0,0.774843 -0.493082,1.267925 Q 12.774843,6.6394415 12,6.6394415 Z M 8.2431835,10.407998 V 9.3044325 q 0,-0.446121 0.223061,-0.763103 0.22306,-0.316982 0.575262,-0.481342 0.786584,-0.352202 1.5085965,-0.528303 0.722013,-0.1761 1.449897,-0.1761 0.727883,0 1.444025,0.181971 0.716144,0.18197 1.502727,0.522432 0.363942,0.16436 0.587003,0.481342 0.223061,0.316982 0.223061,0.763103 v 1.1035645 z"
      strokeWidth={0.234801}
    />
    <path
      d="m 17.223286,17.114017 q -0.774844,0 -1.267926,-0.493082 -0.493082,-0.493082 -0.493082,-1.267924 0,-0.774845 0.493082,-1.267926 0.493082,-0.493082 1.267926,-0.493082 0.774843,0 1.267925,0.493082 0.493083,0.493082 0.493083,1.267926 0,0.774842 -0.493083,1.267924 -0.493082,0.493082 -1.267925,0.493082 z m -3.756817,3.768557 v -1.103565 q 0,-0.446121 0.223062,-0.763103 0.223061,-0.316981 0.575263,-0.481341 0.786583,-0.352202 1.508597,-0.528304 0.722011,-0.1761 1.449897,-0.1761 0.727882,0 1.444025,0.181971 0.716143,0.181971 1.502726,0.522433 0.363941,0.164359 0.587003,0.481341 0.223062,0.316982 0.223062,0.763103 v 1.103565 z"
      opacity={0.5}
      strokeWidth={0.234801}
    />
  </svg>,
  "EmployeesManager",
);
