import { BaseSelectProps, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BrandKitStyle } from "../server/brand-kit-service";

interface VisualStyleSelectProps extends Omit<BaseSelectProps, "onChange"> {
  brandingStyle: string | undefined;
  onChange: (style: BrandKitStyle) => void;
}

export const VisualStyleSelect: FC<VisualStyleSelectProps> = ({
  brandingStyle,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Select
      id="visual-style-select"
      labelId="style-label"
      value={brandingStyle ?? "bold"}
      onChange={(e) => {
        const val = e.target.value;
        onChange(val as BrandKitStyle);
      }}
      sx={{ minWidth: "300px", maxWidth: "500px", mt: 4, display: "block" }}
      label={t("Visual Style")}
      inputProps={{
        "aria-label": t("Visual Style"),
      }}
      {...props}
    >
      <MenuItem value="bold">{t("Bold")}</MenuItem>
      <MenuItem value="sophisticated">{t("Sophisticated")}</MenuItem>
      <MenuItem value="organic">{t("Organic")}</MenuItem>
      <MenuItem value="formal">{t("Formal")}</MenuItem>
      <MenuItem value="minimalist">{t("Minimalist")}</MenuItem>
    </Select>
  );
};
