import { UseApiLoaderResponse } from "../../../server/api/hooks/use-api-loader";
import { notEmpty } from "../../../utils/not-empty";
import { ContentType } from "../types";
import { useMultiFormat } from "./use-multi-format";

export const usePrompts = (): UseApiLoaderResponse<Record<string, string[]> | null> => {
  const { loading, error, data, reload } = useMultiFormat();

  if (loading) return { loading, error, data: null, reload };
  if (error) return { loading, error, data: null, reload };

  const prompts = data?.["content-types"].reduce(
    (acc: Record<string, string[]>, c: ContentType) => {
      const contentType = c["Content Type"];
      const promptsForContentType =
        c["Example Prompts"]
          ?.split(",")
          .map((prompt) => prompt.trim())
          .filter(notEmpty) ?? [];

      if (promptsForContentType.length > 0 && contentType) {
        return { ...acc, [contentType]: promptsForContentType };
      }
      return acc;
    },
    {},
  );

  return { loading, error, data: prompts, reload };
};
