import { sortBy } from "lodash-es";
import { Brand } from "../brandfetch/types";
import { BrandFetchChoices } from "./types";

export function brandfetchResultToChoices(result: Brand): BrandFetchChoices {
  return {
    logos: sortLogos(filterLogos(result.logos)),
    colors: result.colors
      .filter((x) => !tooWhite(x.hex))
      .map((color) => ({
        ...color,
        is_default_primary_color: false,
      })),
  };
}

const formatPriority = ["svg", "png", "jpeg"];

const filterLogos = (logos: Brand["logos"]): BrandFetchChoices["logos"] => {
  return logos.map(({ formats, ...rest }) => {
    const format = formats.sort((a, b) => {
      const aIndex = formatPriority.indexOf(a.format);
      const bIndex = formatPriority.indexOf(b.format);
      return aIndex - bIndex;
    })[0];
    return { ...format, ...rest, background: format.background ?? null, is_default_logo: false };
  });
};

export function tooWhite(hex: string): boolean {
  const whiteLimit = 230;

  const r = parseInt("0x" + hex.substring(1, 3));
  const g = parseInt("0x" + hex.substring(3, 5));
  const b = parseInt("0x" + hex.substring(5, 7));
  return !(r < whiteLimit || b < whiteLimit || g < whiteLimit);
}

export function sortLogos(logos: BrandFetchChoices["logos"]): BrandFetchChoices["logos"] {
  const priority = ["icon", "logo", "symbol", "other"];

  return sortBy(logos, (logo) => priority.indexOf(logo.type));
}
