import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PermissionsTable } from "./permissions-table";

export const Permissions: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6">{t("Manage your Permissions")}</Typography>
      <Typography variant="subtitle2" color="text.secondary">
        <Trans>
          Users can add and remove user permissions here. Super Admins have site wide access,
          however Contributors can only view, edit and manage Moments in the Program assigned to
          them.{" "}
          <strong>
            New users permissions may not be immediate as they will be reviewed before being
            granted.
          </strong>{" "}
          Learn more here.
        </Trans>
      </Typography>
      <Box sx={{ mt: 3 }}>
        <PermissionsTable />
      </Box>
    </>
  );
};
