import { config } from "../../config";
import { R2Service } from "../../server/cloudflare/r2-service";
import { serviceURL } from "../../utils/service-url";
import { NameSpaceURL, UUID, uuidv5 } from "../../utils/uuid";
import { BrandKitStyle } from "../brand-kit/server/brand-kit-service";
import { BrandInfoSelected, TemplateInfo } from "../brand-kit/types";

export async function copyImage(
  getToken: () => Promise<string>,
  src: string,
  id: string,
): Promise<void> {
  await new R2Service().upload(
    new File([await (await fetch(src)).blob()], id),
    uuidv5(NameSpaceURL, `dh:${id}`),
    await getToken(),
  );
}

export function imageURL(
  account_id: string,
  project_id: string,
  cacheBustNumber: number = 1,
): string {
  return `${serviceURL("images")}${account_id}/${uuidv5(
    NameSpaceURL,
    `dh:${project_id}`,
  )}?v=${cacheBustNumber}`;
}

function optionalMergeTag(
  key: string,
  text?: string,
): { [x: string]: { auto_fit: boolean; text: string } } {
  return text || text === "" ? { [key]: { auto_fit: true, text } } : {};
}

export function brandInfoToCustomizations(
  brandInfo?: BrandInfoSelected,
  templateInfo?: TemplateInfo,
): {
  [key: string]: unknown;
} {
  return {
    classes: {
      ...(brandInfo?.logo
        ? {
            [["icon", "logo", "symbol"].includes(brandInfo.logo.type)
              ? brandInfo.logo.type
              : "logo"]: {
              url: brandInfo.logo.src,
            },
          }
        : {}),
      ...(brandInfo?.color ? { accentcolor: { color: brandInfo.color.hex } } : {}),
      ...(templateInfo?.Photo ? { Photo: { masked_media: { url: templateInfo.Photo } } } : {}),
      ...optionalMergeTag("header", templateInfo?.header),
      ...optionalMergeTag("subheader", templateInfo?.subheader),
      ...optionalMergeTag("tagline", templateInfo?.tagline),
      ...optionalMergeTag("description", templateInfo?.description),
      ...optionalMergeTag("hashtag-1", templateInfo?.["hashtag-1"]),
      ...optionalMergeTag("hashtag-2", templateInfo?.["hashtag-2"]),
      ...optionalMergeTag("footer", templateInfo?.footer),
      ...optionalMergeTag("logo-program-name", templateInfo?.["logo-program-name"]),
      ...optionalMergeTag("logo-tagline", templateInfo?.["logo-tagline"]),
      ...optionalMergeTag("certificate-title", templateInfo?.["certificate-title"]),
      ...optionalMergeTag("certificate-description", templateInfo?.["certificate-description"]),
    },
  };
}

const pages: Record<NonNullable<BrandKitStyle>, number> = {
  bold: 1,
  sophisticated: 2,
  organic: 3,
  formal: 4,
  minimalist: 5,
};

export function getPageNumber(brandInfo?: BrandInfoSelected): number {
  return pages[brandInfo?.branding_type ?? "bold"];
}

const pageAspect: Record<NonNullable<BrandKitStyle>, "landscape" | "portrait" | "squarish"> = {
  bold: "portrait",
  sophisticated: "portrait",
  organic: "portrait",
  formal: "landscape",
  minimalist: "squarish",
};

export function getPageAspect(
  brandInfo?: BrandInfoSelected,
): "landscape" | "portrait" | "squarish" {
  return pageAspect[brandInfo?.branding_type ?? "bold"];
}

export async function getDesignHuddleRender({
  getToken,
  accessToken,
  accountId,
  projectId,
  pageNumber,
  format = "png",
  filename = "file",
}: {
  getToken: () => Promise<string>;
  accessToken: string;
  accountId: UUID;
  projectId: string;
  pageNumber?: number;
  format?: string;
  filename?: string;
}): Promise<string> {
  // Get project
  const projectResp = await fetch(
    `https://${config.design_huddle.domain}/api/projects/${projectId}`,
    {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      headers: { authorization: `Bearer ${accessToken}` },
    },
  );

  const { success: projectSuccess, data: projectData } = (await projectResp.json()) as {
    success: boolean;
    data: {
      pages: { page_id: string; page_number: number }[];
    };
  };

  if (!projectSuccess || !projectData) throw new Error("Project Error");

  // Get page
  const page_id = projectData.pages.find((p) => p.page_number === pageNumber)?.page_id;

  // start export
  const resp = await fetch(
    `https://${config.design_huddle.domain}/api/projects/${projectId}/export`,
    {
      method: "POST",
      mode: "cors",
      credentials: "omit",
      headers: { authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({
        format,
        page_id,
        filename,
      }),
    },
  );

  const { success, data } = (await resp.json()) as {
    success: boolean;
    data: { job_id: string };
  };
  if (!success || !data || !data.job_id) throw new Error("Export Error");

  // poll export
  const download_url = await waitForUrl(accessToken, projectId, data.job_id, 1);

  if (format === "pdf") return download_url;

  await copyImage(getToken, download_url, projectId);
  return imageURL(accountId, projectId, Date.now());
}

async function waitForUrl(
  accessToken: string,
  project_id: string,
  job_id: string,
  attempt: number,
): Promise<string> {
  const resp = await fetch(
    `https://${config.design_huddle.domain}/api/projects/${project_id}/export/jobs/${job_id}`,
    {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      headers: { authorization: `Bearer ${accessToken}` },
    },
  );

  const { success, data } = (await resp.json()) as {
    success: boolean;
    data: { completed: boolean; download_url: string };
  };
  if (!success || !data || (!data.completed && attempt < 10)) {
    await new Promise((resolve) => setTimeout(resolve, attempt * 1000));
    return waitForUrl(accessToken, project_id, job_id, attempt + 1);
  } else if (data.completed) return data.download_url;

  throw new Error("Export Job Timeout");
}
