import { LinearProgress } from "@mui/material";
import { sortBy } from "lodash-es";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { CrudDataGrid } from "../../modules/generic/components/crud-data-grid/crud-data-grid";
import { Row } from "../../modules/generic/components/crud-data-grid/types";
import {
  AccountLimitKey,
  AdminAccountLimit,
  CeAdminSupabaseAccountService,
} from "../../modules/generic/server/supabase-account-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useSupabase, useSupabaseCallback } from "../../server/supabase/hooks";
import { UUID } from "../../utils/uuid";
import CeAdminPageHeader from "./ce-admin-page-header";

interface NamedColumns {
  id: UUID;
  name: string;
}

interface Keys {
  [key: string]: string | number;
}

type Columns = NamedColumns & Keys;

const booleanColumns: AccountLimitKey[] = ["allow_all_and_upcoming_segment"];

const CeAdminPage: FC = () => {
  const [accountState, setAccountState] = useState<
    | {
        accountLimitInfo: AdminAccountLimit[];
        limitKeys: string[];
      }
    | "loading"
  >("loading");

  useSupabase<"ce_admin">(
    async ({ supabase }) => {
      if (accountState !== "loading") return;

      const service = new CeAdminSupabaseAccountService(supabase);

      const [
        { data: accountLimitInfo, error: accountLimitInfoError },
        { data: limitKeys, error: limitKeysError },
      ] = await Promise.all([service.getAccountLimits(), service.getKeys()]);

      if (accountLimitInfoError || limitKeysError) {
        toast.error("Could not load account limit info");
      }

      setAccountState({
        accountLimitInfo: accountLimitInfo ?? [],

        limitKeys: (limitKeys ?? []).map((x) => x.key!).sort(),
      });
    },
    [accountState],
    "ce_admin",
  );

  const persist = useSupabaseCallback(
    async ({ supabase }, row: Columns) => {
      const { id, name, ...limits } = row;

      const { error } = await new CeAdminSupabaseAccountService(supabase).setAccountLimits(
        id,
        limits as { [key: string]: number },
      );
      if (error) {
        toast.error("Could not save account limit info");
        return;
      }

      toast.success(`Saved account limit info for ${name}`);

      return row;
    },
    [],
    "ce_admin",
  );

  const accountData: Columns[] =
    accountState === "loading"
      ? []
      : sortBy(
          accountState.accountLimitInfo.map(({ id, name, limits }) => ({
            id: id!,
            name: name as string,
            ...Object.fromEntries(accountState.limitKeys.map((key) => [key, limits?.[key] ?? 0])),
          })),
          "name",
        );

  return (
    <Page title="Adminstration | ChangeEngine">
      <PageHeader display="flex">
        <PageHeading heading={<CeAdminPageHeader />} subheading={"Manage Account Limits"} />
      </PageHeader>
      <PageContent>
        {accountState === "loading" && <LinearProgress />}
        {accountState !== "loading" && (
          <CrudDataGrid<Row<(typeof accountData)[0]>>
            initialRows={accountData}
            columns={{
              name: {
                headerName: "Account Name",
                flex: 1,
                editable: false,
              },
              ...Object.fromEntries(
                accountState.limitKeys.map((key) => [
                  key,
                  {
                    headerName: key
                      .split("_")
                      .map((x) => x[0].toUpperCase() + x.slice(1))
                      .join(" "),
                    flex: 1,
                    editable: true,
                    ...(booleanColumns.includes(key as AccountLimitKey)
                      ? {
                          type: "boolean",
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          valueSetter: (value: boolean, row: any) =>
                            ({ ...row, [key]: +value }) as Row<Columns>,
                        }
                      : { type: "number" }),
                  },
                ]),
              ),
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onUpdate={persist}
          />
        )}
      </PageContent>
    </Page>
  );
};

export default CeAdminPage;
