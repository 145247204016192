import { atom } from "jotai";
import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { isEqual } from "lodash-es";
import { getTokenAtom } from "../../server/auth/store";
import { authHeader } from "../../utils/auth-header";
import { UUID } from "../../utils/uuid";
import { brandingAtom } from "../brand-kit/store";
import { TemplateInfo } from "../brand-kit/types";
import { brandInfoToCustomizations } from "../design-huddle/utils";
import { debugLabel, gptModelAtom, unsplashSearchFamily } from "../generate/store";
import { selfDriving } from "../self-driving/self-driving";
import { DhTemplateConfig } from "./types";

export const previewInitialContextFamily = atomFamily(
  ({ formatId, answers }: { formatId: string; answers: { answer: string }[] }) =>
    debugLabel(
      `initialContextFamily(${formatId}, ${answers[0].answer})`,
      atomWithDefault<Promise<{ chatId: UUID } | null>>(async (get) => {
        const { getToken } = get(getTokenAtom);
        const gptModel = get(gptModelAtom);
        const token = await getToken();
        if (!gptModel || !token) return null;
        const { data, error } = await selfDriving.POST("/multiformat/initial_context", {
          body: {
            description: answers[0].answer,
          },
          params: {
            query: { model: gptModel },
          },
          ...authHeader(token),
        });
        if (error) {
          throw error;
        }
        return data;
      }),
    ),
  isEqual,
);

export const layoutConfiguration = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum: string;
  }) =>
    atomWithDefault(async (get) => {
      const initialContext = await get(previewInitialContextFamily({ formatId, answers }));

      if (!initialContext) return null;
      const { getToken } = get(getTokenAtom);
      const gptModel = get(gptModelAtom);
      const token = await getToken();

      if (!token || !gptModel) return;

      const { data, error } = await selfDriving.POST(
        "/multiformat/{formatId}/follow_up/image/{imgNum}",
        {
          body: {
            description: answers[0].answer,
            chatId: initialContext.chatId,
          },
          params: {
            path: { formatId, imgNum },
            query: { model: gptModel },
          },
          ...authHeader(token),
        },
      );

      if (error) {
        throw error;
      }

      return data;
    }),
  isEqual,
);

export const previewFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
    templateConfig: templateConfig,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum: string;
    templateConfig: DhTemplateConfig;
  }) =>
    debugLabel(
      `previewFamily(${imgNum})`,
      atom(async (get) => {
        const layoutConfig = await get(layoutConfiguration({ formatId, answers, imgNum }));
        if (!layoutConfig) return null;

        const branding = get(brandingAtom);

        const unsplashResults = layoutConfig.response.search
          ? await get(
              unsplashSearchFamily({
                query: layoutConfig.response.search,
                orientation: "landscape",
              }),
            )
          : undefined;

        const Photo = unsplashResults?.[0]?.urls.regular ?? layoutConfig.response.url;

        const info: TemplateInfo = {
          template_code: templateConfig.templateCode,
          page_number: parseInt(imgNum),
          stableDiffusionImage: false,
          header: layoutConfig.response.header ?? "",
          subheader: layoutConfig.response.subheader ?? "",
          tagline: layoutConfig.response.tagline ?? "",
          description: layoutConfig.response.description ?? "",
          "hashtag-1": layoutConfig.response["hashtag-1"] ?? "",
          "hashtag-2": layoutConfig.response["hashtag-2"] ?? "",
          footer: layoutConfig.response.footer ?? "",
          "logo-program-name": layoutConfig.response["logo-program-name"] ?? "",
          "logo-tagline": layoutConfig.response["logo-tagline"] ?? "",
          "certificate-title": layoutConfig.response["certificate-title"] ?? "",
          "certificate-description": layoutConfig.response["certificate-description"] ?? "",
          Photo,
        };

        const customizations = brandInfoToCustomizations(branding, info);

        return {
          template_code: info.template_code,
          customizations,
          page_number: info.page_number,
          ...templateConfig.dimensions,
        };
      }),
    ),
  isEqual,
);

export const previewUrlFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
    templateConfig: templateConfig,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum: string;
    templateConfig: DhTemplateConfig;
  }) =>
    debugLabel(
      `previewFamily(${imgNum})`,
      atom(async (get) => {
        const preview = await get(previewFamily({ formatId, answers, imgNum, templateConfig }));
        if (!preview) return null;
        return await new Promise<string>((resolve, reject) => {
          DSHDLib.getVariableTemplatePreviewURL(preview, (err, url) => {
            if (err) {
              reject(err);
            } else {
              resolve(url);
            }
          });
        });
      }),
    ),
  isEqual,
);

export const previewsFamily = atomFamily(
  ({
    formatId,
    answers,
    num,
    templateConfig,
  }: {
    formatId: string;
    answers: { answer: string }[];
    num: number;
    templateConfig: DhTemplateConfig;
  }) =>
    debugLabel(
      `previewsFamily(${num})`,
      atomWithDefault((get) =>
        Array(num)
          .fill(undefined)
          .map((_, imgNum) => ({
            preview: get(
              loadable(
                previewFamily({
                  formatId,
                  answers,
                  imgNum: `${imgNum + 1}`,
                  templateConfig,
                }),
              ),
            ),
            url: get(
              loadable(
                previewUrlFamily({
                  formatId,
                  answers,
                  imgNum: `${imgNum + 1}`,
                  templateConfig,
                }),
              ),
            ),
          })),
      ),
    ),
  isEqual,
);
