import { PostgrestClient } from "@supabase/postgrest-js";
import { omit } from "lodash-es";
import { Database } from "../../../server/supabase/types/database-definitions";
import { ZERO_INTERVAL } from "../../../utils/interval";
import { ISO8601_TIME, toISO8601 } from "../../../utils/iso8601";
import { UUID, uuidv4 } from "../../../utils/uuid";
import { ChatMomentLoader } from "../../chat-moment/server/chat-moment-loader";
import { EmailMomentLoader } from "../../email-moment/server/email-moment-loader";
import { EventKey, SupabaseEventService } from "../../event/server/supabase-event-service";
import { SupabaseAccountService } from "../../generic/server/supabase-account-service";
import { MomentStatus } from "../../moment-card/types";
import { ScheduleLoader } from "../../schedule/server/schedule-loader";
import { SupabaseSegmentService } from "../../segment/server/supabase-segment-service";
import {
  personalEmailConfig,
  slackConfig,
  teamsConfig,
  twilioConfig,
  workEmailConfig,
} from "../component/config";
import { MomentFormMoment } from "../types/moment-form-moment";
import { SupabaseMomentRunningService, SupabaseMomentService } from "./supabase-moment-service";

export type MomentLoaderResponse =
  | {
      error?: never;
      data: MomentFormMoment;
    }
  | {
      error: string;
      data?: never;
    };
export class MomentLoader {
  private readonly supabase: PostgrestClient<Database>;
  private readonly accountId: UUID;
  constructor(supabase: PostgrestClient<Database>, accountId: UUID) {
    this.supabase = supabase;
    this.accountId = accountId;
  }

  async load(
    id: UUID,
    options?: { program_id?: UUID; newsletter?: boolean },
  ): Promise<MomentLoaderResponse> {
    const { data: account } = await new SupabaseAccountService(this.supabase).get(this.accountId);
    const composeMethods = options?.newsletter
      ? [workEmailConfig, personalEmailConfig]
      : [
          ...(account && account.enable_slack ? [slackConfig] : []),
          workEmailConfig,
          personalEmailConfig,
          ...(account && account.enable_teams ? [teamsConfig] : []),
          ...(account && account.enable_twilio ? [twilioConfig] : []),
        ];

    const { error, data, status } = await new SupabaseMomentService(this.supabase).get(id);
    if (status === 406) {
      return {
        data: {
          id,
          account_id: this.accountId,
          created_at: toISO8601(new Date()),
          title: "",
          target_manager: false,
          target_channel: false,
          program_id: options?.program_id,
          immediate: false,
          sent: false,
          running: false,
          valid_on_save: false,
          additional_recipients: [],
          cover_image_path: null,
          description: null,
          only_additional_recipients: false,
          timezone: null,
          channel: options?.newsletter ? "work_email" : null,
          composeMethods,
          status: "draft",
          deleted_at: null,
          cc: [],
          bcc: [],
          notify_channel: false,
          source_moment_id: null,
          email_moment:
            composeMethods.length === 1 || options?.newsletter
              ? await new EmailMomentLoader(this.supabase).loadNew()
              : null,
          chat_moment: null,
          schedule: makeDefaultSchedule(),
          segment: null,
          design: null,
          body_text: null,
          email_subject: null,
          from: null,
          use_lexical_editor: options?.newsletter ? false : true,
          discover_moment_launched_from: null,
          email_design_id: null,
          unrecoverable_error: false,
          teams_channel_message_notify: false,
        },
      };
    }
    if (error || !data) return { error: error?.message };
    const running = await new SupabaseMomentRunningService(this.supabase).getByMomentID(id);
    if (running.error || !running.data) return { error: running.error?.message };

    const { data: statusData, error: statusError } = await new SupabaseMomentService(
      this.supabase,
    ).getMomentWithSchedule(id);

    const [{ data: schedule }, { data: segment }, { data: email_moment }, { data: chat_moment }] =
      await Promise.all([
        new ScheduleLoader(this.supabase).load(statusData?.schedule_id),
        statusData?.segment_id
          ? new SupabaseSegmentService(this.supabase).getSegmentData(statusData?.segment_id)
          : { data: null },
        statusData?.email_moment_id
          ? new EmailMomentLoader(this.supabase).load(statusData?.email_moment_id)
          : { data: null },
        statusData?.chat_moment_id
          ? new ChatMomentLoader(this.supabase).load(statusData?.chat_moment_id)
          : { data: null },
      ]);

    if (statusError || !statusData) return { error: statusError?.message ?? "Not found" };

    const { data: eventsData } = await new SupabaseEventService(this.supabase).getKeys({
      mustIncludeKey: schedule?.event_key,
      includeLibrary: false,
    });

    const scheduleData = data.immediate
      ? undefined
      : schedule
        ? {
            ...omit(schedule, "event_key"),
            event: (eventsData?.find((e) => e.key === schedule?.event_key) as EventKey) ?? null,
          }
        : makeDefaultSchedule();

    return {
      data: {
        ...data,
        schedule: scheduleData ?? null,
        segment: segment ?? null,
        running: running.data.length > 0,
        composeMethods,
        status: (statusData.status ?? "draft") as MomentStatus,
        email_moment: email_moment ?? null,
        chat_moment: chat_moment ?? null,
        unrecoverable_error: false,
      },
    };
  }
}

function makeDefaultSchedule(): MomentFormMoment["schedule"] {
  return {
    id: uuidv4(),
    send_at: "12:00" as ISO8601_TIME,
    event: null,
    event_initial: true,
    event_repeat: false,
    on_event_key: null,
    dow: {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
    interval: ZERO_INTERVAL,
    interval_json: null,
  };
}
