import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BadgeCheckOutlined } from "../../../../styles/icons/badge-check-outlined";
import { DotsHorizontal } from "../../../../styles/icons/dots-horizontal";
import { XCircle } from "../../../../styles/icons/x-circle";

export type HasIntegration = true | false | "loading";

interface IntegrationCardProps {
  title: string;
  subtitle: string;
  hasIntegration: HasIntegration;
  disableIntegration: () => Promise<void>;
  connectButton: React.JSX.Element | null;
  icon: React.JSX.Element;
  extra?: React.JSX.Element;
  extraAction?: React.JSX.Element;
}

export const IntegrationCard: FC<IntegrationCardProps> = ({
  title,
  subtitle,
  hasIntegration,
  disableIntegration,
  connectButton,
  icon,
  extra,
  extraAction,
}) => {
  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Avatar
            component="a"
            sx={{
              background: "transparent",
              mr: 2,
              mb: {
                xs: 2,
                md: 0,
              },
            }}
            variant="rounded"
          >
            {icon}
          </Avatar>
          <div>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body2">{subtitle}</Typography>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                ml: -3,
                "& > *": {
                  ml: 3,
                  mt: 1,
                },
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <IntegrationIcon hasIntegration={hasIntegration} />
              </Box>
            </Box>
            {extra}
          </div>
        </Box>
      </CardContent>
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        <IntegrationButton
          hasIntegration={hasIntegration}
          connectButton={connectButton}
          disableIntegration={disableIntegration}
          icon={icon}
        />
        {extraAction}
      </CardActions>
    </Card>
  );
};

export const IntegrationIcon: FC<Pick<IntegrationCardProps, "hasIntegration">> = ({
  hasIntegration,
}) => {
  const { t } = useTranslation();
  switch (hasIntegration) {
    case true:
      return (
        <>
          <BadgeCheckOutlined color="success" fontSize="small" sx={{ mr: 1 }} />
          <Typography color="success" noWrap variant="overline">
            {t("Connected")}
          </Typography>
        </>
      );
    case false:
      return (
        <>
          <XCircle color="error" fontSize="small" sx={{ mr: 1 }} />
          <Typography color="error" noWrap variant="overline">
            {t("Not Connected")}
          </Typography>
        </>
      );
    case "loading":
      return (
        <>
          <DotsHorizontal color="info" fontSize="small" sx={{ mr: 1 }} />
          <Typography color="info" noWrap variant="overline">
            {t("Loading")}
          </Typography>
        </>
      );
    default:
      throw new Error("Invalid hasIntegtaion");
  }
};

const IntegrationButton: FC<
  Pick<IntegrationCardProps, "hasIntegration" | "connectButton" | "disableIntegration" | "icon">
> = ({ hasIntegration, connectButton, disableIntegration, icon }) => {
  switch (hasIntegration) {
    case true:
      return <DisconnectButton disableIntegration={disableIntegration} icon={icon} />;
    case false:
      return connectButton;
    case "loading":
      return <LoadingButton loading={true} />;
    default:
      throw new Error("Invalid has Integration");
  }
};

const DisconnectButton: FC<{
  disableIntegration: () => Promise<void>;
  icon: React.JSX.Element;
}> = ({ disableIntegration, icon }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const disconnect = useCallback(async (): Promise<void> => {
    setLoading(true);
    await disableIntegration();
    setLoading(false);
  }, [disableIntegration]);

  return (
    <Button
      startIcon={icon}
      variant="outlined"
      color={"info"}
      onClick={() => {
        void disconnect().catch(console.error);
      }}
      disabled={loading}
    >
      {loading ? <CircularProgress /> : t("Disconnect")}
    </Button>
  );
};
