import { usePermissions } from "../../../../server/auth/use-permissions";
import { UseMomentProperties } from "../../../moment-card/types";
import { getToTarget } from "../../../to/use-to-target";
import { isEmailChannel } from "../channel/channel";

type EmployeeNewsletterMomentInfo = Pick<
  UseMomentProperties,
  | "use_lexical_editor"
  | "channel"
  | "only_additional_recipients"
  | "target_manager"
  | "target_channel"
  | "notify_channel"
>;

export function isNonEmployeeNewsletter(moment: EmployeeNewsletterMomentInfo): boolean {
  const toTarget = getToTarget(moment);

  return (
    !moment.use_lexical_editor &&
    (!moment.channel || isEmailChannel(moment.channel)) &&
    toTarget !== "employee"
  );
}

export function useMomentClonable(moment?: EmployeeNewsletterMomentInfo):
  | {
      loading: true;
      clonable: null;
    }
  | { loading: false; clonable: boolean | "warn_first" } {
  const { ce_admin } = usePermissions();

  if (!moment) return { loading: true, clonable: null };

  // Only ce_admins can clone non-employee newsletters
  const nonEmployeeNewsletter = isNonEmployeeNewsletter(moment);

  return {
    loading: false,
    clonable: !nonEmployeeNewsletter ? true : ce_admin ? "warn_first" : false,
  };
}
