import { Box, Button, LinearProgress, Link, Stack } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { MomentFilter } from "../../modules/moment-filter/components/moment-filter";
import { LiveSearchField } from "../../modules/search/live-search-field";
import { useCombinedSearch } from "../../modules/tabbed-programs/hooks/use-combined-search";
import { MomentGrid } from "../../modules/tabbed-programs/moment-grid";
import { ProgramGrid } from "../../modules/tabbed-programs/program-grid";

const TabbedProgramsPage: FC = () => {
  const { t } = useTranslation();

  const { data, loading } = useCombinedSearch();

  return (
    <Page title={t("Your Programs | ChangeEngine")}>
      <PageHeader display="flex">
        <PageHeading
          heading={t("Your Programs")}
          subheading={t("Manage your Programs and Moments")}
        >
          <Button LinkComponent={Link} href="/manage/programs" color="primary" variant="contained">
            {t("Create Program")}
          </Button>
        </PageHeading>
      </PageHeader>

      <PageContent>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 4 }}>
          <Box sx={{ minWidth: 200, maxWidth: 510 }}>
            <LiveSearchField label={t("Search Moments & Programs")} />
          </Box>
          <MomentFilter displayEventOptions={false} displaySegmentOptions={false} />
        </Stack>
        {loading || !data ? (
          <LinearProgress sx={{ mt: 2 }} />
        ) : (
          <>
            <ProgramGrid programs={data.programs} />

            <MomentGrid moments={data.moments} generateMoment={true} mt={4} showProgram={true} />
          </>
        )}
      </PageContent>
    </Page>
  );
};

export default TabbedProgramsPage;
