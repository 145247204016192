import { useDebouncedState, useDeepCompareEffect } from "@react-hookz/web";
import { PostgrestError } from "@supabase/postgrest-js";
import { uniq } from "lodash-es";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Filter } from "../../../server/supabase/base-supabase-service";
import {
  UseAsyncStateCallback,
  useAsyncStateCallback,
} from "../../../utils/hooks/use-async-state-callback";
import { notEmpty } from "../../../utils/not-empty";
import { SearchAttributesServer, makeArrayString, useSetQueryParams } from "./utils";

export type GetMoments<T extends SearchAttributesServer> = (options: {
  filter: MomentQueryFilters;
}) => Promise<
  { data: T[]; error: null } | { data: null; error: Error | PostgrestError } | undefined
>;

export type UseServerFilteredMoments<T> = Omit<
  UseAsyncStateCallback<T[], [MomentQueryFilters]>,
  "load"
> & {
  setQueryParams: (params: { [key: string]: string }) => void;
  load: () => Promise<T[]>;
};

export function useServerFilteredMoments<T extends SearchAttributesServer>(
  get: GetMoments<T>,
  queryFilterConfig: QueryFilterConfig,
): UseServerFilteredMoments<T> {
  const setQueryParams = useSetQueryParams();

  const queryFilters = useMomentQueryFilters(queryFilterConfig);

  const { load, ...loader } = useAsyncStateCallback(
    async (filters: MomentQueryFilters) => {
      if (!filters) return [];
      const maybe = await get({ filter: filters });
      if (!maybe) return [];
      const { data: filteredMoments, error } = maybe;
      if (error) throw error;

      return filteredMoments;
    },
    [get],
  );

  useEffect(() => {
    if (queryFilters) void load(queryFilters).catch(console.error);
  }, [queryFilters, load]);

  return {
    ...loader,
    load: () => (queryFilters ? load(queryFilters).then((r) => r ?? []) : Promise.resolve([])),
    setQueryParams,
  };
}

export type MomentQueryFilters = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: Filter<any>[];
  rawFilters: { q: string | null; other: { [key: string]: string[] } };
};

type QueryFilterConfig = {
  allowedFilters: ("q" | "program" | "status" | "channel" | "start" | "end")[];
  debounceInterval?: number;
  extraFilters?: { [key: string]: string };
};

const getValues = (key: string, searchParams: URLSearchParams): string[] | undefined =>
  searchParams.has(key) ? searchParams.getAll(key) : undefined;

export function useMomentQueryFilters(config: QueryFilterConfig): MomentQueryFilters | undefined {
  const [searchParams] = useSearchParams();

  for (const [key, value] of Object.entries(config.extraFilters || {})) {
    searchParams.set(key, value);
  }

  const values = {
    program: getValues("program", searchParams),
    status: getValues("status", searchParams),
    channel: getValues("channel", searchParams),
    q: searchParams.get("q"),
    start: searchParams.get("start"),
    end: searchParams.get("end"),
  };

  const [debouncedFilters, setDebouncedFilters] = useDebouncedState<MomentQueryFilters | undefined>(
    makeMomentQueryFilters(values, config.allowedFilters, config.extraFilters),
    config.debounceInterval ?? 0,
    500,
  );

  useDeepCompareEffect(() => {
    setDebouncedFilters(makeMomentQueryFilters(values, config.allowedFilters, config.extraFilters));
  }, [values, config.allowedFilters, config.extraFilters]);

  return debouncedFilters;
}

const makeFilter = (
  filterKey: QueryFilterConfig["allowedFilters"][number],
  value: string[] | string | undefined,
  filterField: string,
  operator: MomentQueryFilters["filters"][0]["operator"],
  allowedFilters: QueryFilterConfig["allowedFilters"],
): MomentQueryFilters["filters"][0][] => {
  if (value === undefined || !allowedFilters.includes(filterKey)) return [];

  return Array.isArray(value)
    ? [{ key: filterField, operator, value: makeArrayString(value) }]
    : [{ key: filterField, operator, value: `%${value}%` }];
};

const makeMomentQueryFilters = (
  values: {
    program: string[] | undefined;
    status: string[] | undefined;
    channel: string[] | undefined;
    q: string | null;
    start: string | null;
    end: string | null;
  },
  allowedFilters: QueryFilterConfig["allowedFilters"],
  extraFilters?: QueryFilterConfig["extraFilters"],
): MomentQueryFilters => {
  const consolidate = (key: string, vals: string[] | undefined): string[] => {
    return uniq([...(vals || []), extraFilters?.[key] ?? undefined].filter(notEmpty));
  };

  const filtersList = {
    program: makeFilter("program", values.program, "program_id", "in", allowedFilters),
    status: makeFilter("status", values.status, "status", "in", allowedFilters),
    channel: makeFilter(
      "channel",
      values.channel?.map((x) => (x === "email" ? "work_email" : x)),
      "channel",
      "in",
      allowedFilters,
    ),
    q: makeFilter("q", values.q ?? undefined, "title", "ilike", allowedFilters),
    start: makeFilter("start", values.start ?? undefined, "when", "gte", allowedFilters),
    end: makeFilter("end", values.end ?? undefined, "when", "lte", allowedFilters),
  };

  const rawFilters = {
    q: values.q,
    other: Object.fromEntries(
      allowedFilters
        .filter((k) => k !== "q")
        .map((key) => {
          return [key, consolidate(key, values[key] as string[] | undefined)];
        }),
    ),
  };

  return {
    filters: Object.values(filtersList).flat(),
    rawFilters,
  };
};
