import { PostgrestClient } from "@supabase/postgrest-js";
import { isEqual } from "lodash-es";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID, uuidv4 } from "../../../utils/uuid";
import { SegmentFormState } from "../../moment/types/moment-form-moment";
import { Segment, makeDefaultSegment } from "../types";
import { SupabaseSegmentService } from "./supabase-segment-service";

export class UniqueSegmentNameError extends Error {
  constructor() {
    super("Segment name already exists");
  }
}

export class SegmentStateService {
  private readonly supabase: PostgrestClient<Database>;
  constructor(supabase: PostgrestClient<Database>) {
    this.supabase = supabase;
  }
  async load(
    momentId: UUID,
    account_id: UUID,
    segment_id: UUID | undefined,
    allow_all_and_upcoming_segment: number | null,
  ): Promise<SegmentFormState> {
    const segmentService: SupabaseSegmentService = new SupabaseSegmentService(this.supabase);
    const { data: segments, error: allSegmentsError } = await segmentService.getAll(account_id, {
      filter: [
        { key: "name", operator: "not.is", value: null },
        { key: "is_default", operator: "not.is", value: true },
        { key: "deleted_at", operator: "is", value: null },
      ],
      order: [{ column: "id" }],
    });

    if (segments === null || allSegmentsError) {
      throw new Error(allSegmentsError?.message || "Problem retrieving segments");
    }

    if (segment_id && !segments?.find((s) => s.id === segment_id)) {
      const { data: currentSegments, error: currentSegmentError } = await segmentService.getAll(
        account_id,
        { filter: [{ key: "id", operator: "eq", value: segment_id }], order: [{ column: "id" }] },
      );

      if (currentSegmentError) throw new Error(currentSegmentError.message);

      if (currentSegments?.length > 0) segments.push(currentSegments[0]);
    }

    let editable = false;
    if (segment_id) {
      const { data: segmentMomentInfo, error: segmentMomentError } =
        await segmentService.getMomentIds(segment_id);

      if (segmentMomentError) throw new Error(segmentMomentError.message);
      editable = Boolean(segmentMomentInfo) && isEqual(segmentMomentInfo, [{ id: momentId }]);
    }

    const newId = uuidv4();
    const { data: defaultSegmentData, error: defaultSegmentError } =
      await segmentService.getDefaultSegment();

    if (defaultSegmentError) {
      console.error(defaultSegmentError);
    }

    const defaultSegment =
      defaultSegmentError || !defaultSegmentData
        ? makeDefaultSegment(newId)
        : segmentService.cloneSegment(defaultSegmentData, newId);

    const newSegment = { [newId]: { ...defaultSegment, unsaved: true } };

    return {
      editable: { new: newId, existing: editable ? segment_id : undefined },
      segments:
        allow_all_and_upcoming_segment === 1
          ? segments
          : segments?.filter((s) => s.name !== "Current employees and upcoming new hires"),
      cache: newSegment,
    };
  }

  async saveSegment(values: Segment): Promise<void> {
    const { error: saveSegmentError } = await new SupabaseSegmentService(
      this.supabase,
    ).upsertSegmentData(values);

    if (saveSegmentError) {
      if (saveSegmentError.message.includes("idx_unique_name_per_account_on_segments")) {
        throw new UniqueSegmentNameError();
      }
      throw new Error(saveSegmentError?.message ?? "");
    }
  }
}
