import { ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePopover } from "../../utils/hooks/use-popover";
import { CreateSequenceModal } from "./create-sequence-modal";
import { sequenceSelectedTemplatesLengthAtom } from "./store";

export const CreateSequenceButton: FC = () => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen, anchorRef } = usePopover<HTMLButtonElement>();

  const count = useAtomValue(sequenceSelectedTemplatesLengthAtom);

  return (
    <>
      <Button
        ref={anchorRef}
        color="primary"
        variant="contained"
        onClick={handleOpen}
        endIcon={<ChevronRight />}
      >
        {t("Continue with {{count}} templates", { count })}
      </Button>
      <CreateSequenceModal open={open} onClose={handleClose} anchorEl={anchorRef.current} />
    </>
  );
};
