import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CeDropdown } from "../generic/components/form-fields/ce-dropdown";
import { useBrandToneOptions } from "./hooks/use-brand-tone-options";
import { CreateContentRequest } from "./types";

export const ContentToneSelect: FC = () => {
  const { loading, error, data: brandToneOptions } = useBrandToneOptions();
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<CreateContentRequest>();

  const tone = watch("tone");

  useEffect(
    function selectDefaultToneOption() {
      if (brandToneOptions?.length === 0) return;

      if (tone === undefined) {
        setValue("tone", brandToneOptions?.find((x) => x.default)?.value);
      }
    },
    [brandToneOptions, setValue, tone],
  );

  if (error) {
    return null;
  }

  return (
    <CeDropdown<CreateContentRequest>
      label={t("Content Tone")}
      name="tone"
      loading={loading}
      options={brandToneOptions ?? []}
    />
  );
};
