import { Add, ContentCopy, Edit, RestoreFromTrash } from "@mui/icons-material";
import { Button, FormControlLabel, FormGroup, Stack, Switch, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { uniqWith } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { formatDatetimeShort, fromISO8601, toISO8601 } from "../../../../utils/iso8601";
import { UUID } from "../../../../utils/uuid";
import { CrudDataGrid } from "../../../generic/components/crud-data-grid/crud-data-grid";
import {
  RelatedMoments,
  RelatedMomentsDialog,
} from "../../../generic/components/crud-data-grid/related-moments-dialog";
import { Columns, Row, RowOptions } from "../../../generic/components/crud-data-grid/types";
import { useSegmentService } from "../../hooks/use-segment-service";
import { UniqueSegmentNameError } from "../../server/segment-state-service";
import { SegmentWithMoments, SupabaseSegmentService } from "../../server/supabase-segment-service";
import { Segment } from "../../types";
import { ArchiveDialog } from "./delete-dialog";
import { FavoriteButton } from "./favorite-button";
import {
  CloneSegmentModal,
  EditSegmentModal,
  NewSegmentModal,
  UnarchiveWithNewNameModal,
} from "./segment-modal";

export type SegmentRow = SegmentWithMoments & {
  id: UUID;
  moments: { id: UUID; title: string }[];
};

type SegmentRowWithOptions = SegmentRow & { options: RowOptions };

const rowOptions = { preventEditing: false, preventDeletion: false, preventEditingReason: "N/A" };

export const SegmentCrudDataGrid: FC<{
  segments: SegmentWithMoments[];
  showArchived: boolean;
  setShowArchived: (show: boolean) => void;
  is_recipient_type: boolean;
  gridConfig?: {
    disableVirtualization: boolean;
  };
  reload: () => void;
}> = ({ segments, showArchived, setShowArchived, is_recipient_type, gridConfig, reload }) => {
  const { t } = useTranslation();
  const saveSegment = useSegmentService();
  const getProcessedSegments = useCallback(
    () =>
      uniqWith(segments, (currentVal, otherVal) => currentVal.id === otherVal.id)
        .map((datum) => ({
          ...datum,
          id: datum.id!,
          moments: datum.moments ?? [],
          options: rowOptions,
        }))
        .filter((datum) =>
          showArchived ? !datum.is_default : !datum.is_default && datum.deleted_at === null,
        )
        .filter(({ name, moments }) => Boolean(name) || moments?.length === 1),
    [segments, showArchived],
  );
  const [processedSegments, setProcessedSegments] =
    useState<SegmentRowWithOptions[]>(getProcessedSegments);
  const relatedMomentsDialog = useDialog<RelatedMoments>();
  const editDialog = useDialog<{ segment_id: UUID | undefined }>();
  const cloneDialog = useDialog<{ segment_id: UUID | undefined }>();
  const addDialog = useDialog();
  const confirmDeleteDialog = useDialog<{
    segment_id: UUID;
    resolve: (value: boolean | PromiseLike<boolean>) => void;
  }>();
  const confirmUnarchiveDialog = useDialog<{ segment_id: UUID; segment_name: string | null }>();
  const unarchiveWithNewNameDialog = useDialog<{ segment_id: UUID; segment_name: string | null }>();

  useEffect(() => {
    setProcessedSegments(getProcessedSegments());
  }, [getProcessedSegments]);

  const handleDelete = useSupabaseCallback(
    async ({ supabase }, segment_id: UUID): Promise<boolean> => {
      const { error } = await new SupabaseSegmentService(supabase).archive(segment_id);

      if (error) {
        toast.error(t("An error occurred while archiving the Segment"));
        return false;
      } else {
        toast.success(t("Segment archived successfully"));
        reload();
        return true;
      }
    },
    [t, reload],
  );

  const handleUnarchive = useSupabaseCallback(
    async ({ supabase }, segment_id: UUID, segment_name: string | null): Promise<boolean> => {
      const { error } = await new SupabaseSegmentService(supabase).unarchive(segment_id);

      if (error) {
        error.message.indexOf("duplicate") !== -1
          ? unarchiveWithNewNameDialog.handleOpen({ segment_id, segment_name })
          : toast.error(t("An error occurred while unarchiving the Segment"));
        return false;
      } else {
        toast.success(t("Segment unarchived successfully"));
        reload();
        return true;
      }
    },
    [t, unarchiveWithNewNameDialog, reload],
  );

  const handleUnarchiveWithNewName = useSupabaseCallback(
    async ({ supabase }, segment: Segment | null) => {
      if (!segment?.name) return { success: false, errors: null };

      const { error } = await new SupabaseSegmentService(supabase).unarchiveWithNewName(
        segment.id,
        segment.name,
      );

      if (error) {
        return { success: false, errors: { "segment.name": error.message } };
      } else {
        toast.success(t("Segment unarchived successfully"));
        reload();
        return { success: true };
      }
    },
    [t, reload],
  );

  const handleEdit = useCallback(
    async (segment: Segment | null) => {
      if (!segment) return { success: false, errors: null };

      try {
        await saveSegment({ ...segment, is_recipient_type });
      } catch (e) {
        if (e instanceof UniqueSegmentNameError) {
          return {
            success: false,
            errors: {
              "segment.name": t("Segment name must be unique"),
            },
          };
        }

        console.error("Error saving Segment", e);
        toast.error(t("Error saving Segment"));
        return { success: false, errors: null };
      }

      toast.success(t("Segment saved successfully"));
      setProcessedSegments((prev) =>
        prev.map((row) => (row.id === segment.id ? { ...row, ...segment } : row)),
      );
      return { success: true };
    },
    [saveSegment, t, is_recipient_type],
  );

  const handleCreate = useCallback(
    async (segment: Segment | null) => {
      if (!segment) return { success: false, errors: null };
      const createdAt = toISO8601(new Date());

      try {
        await saveSegment({
          ...segment,
          is_recipient_type,
          created_at: createdAt,
          is_default: false,
        });
      } catch (e) {
        if (e instanceof UniqueSegmentNameError) {
          return {
            success: false,
            errors: {
              "segment.name": t("Segment name must be unique"),
            },
          };
        }

        console.error("Error creating Segment", e);
        toast.error(t("Error creating Segment"));
        return { success: false, errors: null };
      }

      toast.success(t("Segment created successfully"));
      const newSegment = {
        created_at: createdAt,
        is_favorite: false,
        is_default: false,
        is_recipient_type,
        id: segment.id,
        name: segment.name,
        description: segment.description,
        moments: [],
        options: rowOptions,
        account_id: null,
        deleted_at: null,
      };
      setProcessedSegments((prev) => [...prev, newSegment]);
      return { success: true };
    },
    [saveSegment, t, is_recipient_type],
  );

  const columns: Columns<SegmentRow> = useMemo(
    () => ({
      name: {
        headerName: t(is_recipient_type ? "Audience" : "Segment name"),
        flex: 3,
        editable: true,
        valueGetter: (_, row) =>
          row.name ??
          (row?.moments?.[0]?.title ? `Moment: ${row?.moments?.[0]?.title}` : null) ??
          "-",
      },
      description: {
        headerName: t("Description"),
        flex: 4,
        editable: true,
        valueGetter: (_, row) => row.description ?? "",
      },
      created_at: {
        headerName: t("Created"),
        flex: 1,
        editable: false,
        valueGetter: (_, row) => row.created_at,
        valueFormatter: (value) => (value ? formatDatetimeShort(fromISO8601(value)) : ""),
      },
      moments: {
        headerName: t("Related Moments"),
        flex: 1,
        editable: false,
        renderCell: (params) => {
          const numMoments = params.row.moments?.length ?? 0;
          return (
            <Button
              onClick={() =>
                relatedMomentsDialog.handleOpen({
                  title: params.row.name || "",
                  moments: params.row.moments,
                })
              }
              disabled={numMoments === 0}
            >
              {t("{{count}} moments", { count: numMoments })}
            </Button>
          );
        },
      },
      is_favorite: {
        headerName: t("Favorite"),
        flex: 1,
        editable: true,
        renderCell: (params) => {
          return <FavoriteButton key={`favorite${params.row.id}`} row={params.row} />;
        },
      },
    }),
    [t, relatedMomentsDialog, is_recipient_type],
  );

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Button color="primary" startIcon={<Add />} onClick={addDialog.handleOpen}>
          <Trans>Add {is_recipient_type ? "Audience" : "Segment"}</Trans>
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={showArchived} onChange={(_, checked) => setShowArchived(checked)} />
            }
            label={t("Show archived?")}
            labelPlacement="start"
          />
        </FormGroup>
      </Stack>
      <CrudDataGrid<Row<SegmentRow>>
        onDelete={async (row) => {
          return new Promise<boolean>((resolve) => {
            // Save the arguments to resolve or reject the promise later
            confirmDeleteDialog.handleOpen({ segment_id: row.id, resolve });
          });
        }}
        initialRows={processedSegments}
        columns={columns}
        gridOverrides={
          gridConfig ? { disableVirtualization: gridConfig.disableVirtualization } : {}
        }
        modifyActions={(actions, row) => {
          return [
            ...(row?.deleted_at
              ? [
                  <GridActionsCellItem
                    key={row.id}
                    icon={
                      <Tooltip title={t("Unarchive")}>
                        <RestoreFromTrash />
                      </Tooltip>
                    }
                    label="Unarchive"
                    className="textPrimary"
                    onClick={() => {
                      confirmUnarchiveDialog.handleOpen({
                        segment_id: row.id,
                        segment_name: row.name,
                      });
                    }}
                    color="inherit"
                    disabled={false}
                  />,
                ]
              : actions),
            row?.id ? (
              <>
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t("Edit")}>
                      <Edit />
                    </Tooltip>
                  }
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    editDialog.handleOpen({ segment_id: row?.id });
                  }}
                  color="inherit"
                  disabled={false}
                />
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t("Clone")}>
                      <ContentCopy />
                    </Tooltip>
                  }
                  label="Clone"
                  className="textPrimary"
                  onClick={() => {
                    cloneDialog.handleOpen({ segment_id: row?.id });
                  }}
                  color="inherit"
                  disabled={false}
                />
              </>
            ) : (
              <></>
            ),
          ];
        }}
      />
      <RelatedMomentsDialog subjectNamedInTitle="Segment" dialog={relatedMomentsDialog} />
      <ArchiveDialog
        title={t("Archive Segment")}
        content={t("Are you sure you wish to archive this Segment?")}
        open={confirmDeleteDialog.open}
        onCancel={() => {
          confirmDeleteDialog.handleClose();
          confirmDeleteDialog.data?.resolve(false);
        }}
        onConfirm={async () => {
          confirmDeleteDialog.handleClose();
          confirmDeleteDialog.data?.resolve(
            (confirmDeleteDialog.data &&
              (await handleDelete(confirmDeleteDialog.data?.segment_id))) ??
              false,
          );
        }}
      />
      <ArchiveDialog
        title={t("Unarchive Segment")}
        content={t("Are you sure you wish to unarchive this Segment?")}
        open={confirmUnarchiveDialog.open}
        onCancel={confirmUnarchiveDialog.handleClose}
        onConfirm={async () => {
          if (confirmUnarchiveDialog.data?.segment_id)
            await handleUnarchive(
              confirmUnarchiveDialog.data?.segment_id,
              confirmUnarchiveDialog.data?.segment_name,
            );
          confirmUnarchiveDialog.handleClose();
        }}
      />
      {unarchiveWithNewNameDialog.open && (
        <UnarchiveWithNewNameModal
          open={unarchiveWithNewNameDialog.open}
          onCancel={unarchiveWithNewNameDialog.handleClose}
          segmentId={unarchiveWithNewNameDialog.data?.segment_id}
          segmentName={unarchiveWithNewNameDialog.data?.segment_name}
          onSave={async (segment) => {
            const result = await handleUnarchiveWithNewName(segment);
            if (!result) return;

            if (result?.success) addDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
        />
      )}
      {editDialog.open && (
        <EditSegmentModal
          open={editDialog.open}
          segmentId={editDialog.data?.segment_id}
          onCancel={() => editDialog.handleClose()}
          onSave={async (segment) => {
            const result = await handleEdit(segment);
            if (result.success) editDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
          disabled={
            (processedSegments
              .find((x) => x.id === editDialog.data?.segment_id)
              ?.moments.filter(({ finished }) => !finished).length ?? 0) > 1
          }
        />
      )}
      {cloneDialog.open && (
        <CloneSegmentModal
          open={cloneDialog.open}
          segmentId={cloneDialog.data?.segment_id}
          onCancel={() => cloneDialog.handleClose()}
          onSave={async (segment) => {
            const result = await handleCreate(segment);
            if (result.success) cloneDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
        />
      )}
      {addDialog.open && (
        <NewSegmentModal
          isRecipientType={is_recipient_type}
          open={addDialog.open}
          onCancel={() => addDialog.handleClose()}
          onSave={async (segment) => {
            const result = await handleCreate(segment);
            if (result.success) addDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
        />
      )}
    </Stack>
  );
};
