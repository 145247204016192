import { Box, IconButton, Popover } from "@mui/material";
import { FC, ReactElement, ReactNode, useRef, useState } from "react";
import { MomentCard } from "../../../moment-card/components/moment-card";
import { MomentCardMoment } from "../../../moment-card/types";

/**
 * Wrapper component to display moment card when the child component is clicked
 * @param moment The moment to be rendered on the card
 * @param children The component to be wrapped
 * @returns Popover component
 */
export const MomentPopover: FC<{
  moment: MomentCardMoment;
  children: ReactNode;
  noButton?: boolean;
  momentCardType: "user_moment" | "library_moment";
}> = ({ moment, children, noButton, momentCardType }): ReactElement => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const momentCard =
    momentCardType === "user_moment" ? <MomentCard draggable={false} moment={moment} /> : null;

  return (
    <>
      {noButton ? (
        <Box
          sx={{
            minWidth: "100%",
            borderRadius: 1,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          ref={ref}
          onClick={() => setOpen(true)}
        >
          {children}
        </Box>
      ) : (
        <IconButton sx={{ py: 0.2, px: 1 }} onClick={() => setOpen(true)}>
          <Box ref={ref}>{children}</Box>
        </IconButton>
      )}
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        {momentCard}
      </Popover>
    </>
  );
};
