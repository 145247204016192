import { FC } from "react";
import { useFlags } from "../../../server/optimizely";
import { ApplicationLayout } from "./application-layout";
import { Layout } from "./layout";

export const GenerateLayout: FC = () => {
  const {
    flags: { banner_images_enabled },
  } = useFlags("banner_images_enabled");

  return banner_images_enabled ? (
    <ApplicationLayout
      layout="sequence"
      slots={{ root: { props: { sx: { bgcolor: "neutral.100" } } } }}
    />
  ) : (
    <Layout />
  );
};
