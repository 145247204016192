import { Box, Tooltip } from "@mui/material";
import { take } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../utils/hooks/use-dialog";
import { BrandKitColorChoiceRow } from "../../brand-kit/components/color-choice";
import { BrandKitColor } from "../../brand-kit/server/brand-kit-service";
import { BrandKitManager } from "../../brand-kit/types";
import { More } from "./branding-bar";

export const BrandColorSelect: FC<{
  colors: BrandKitColor[];
  manager: BrandKitManager;
  selected?: BrandKitColor;
}> = ({ colors, selected, manager }) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  if (colors.length === 0) return null;

  const sortedColours = [...colors].sort((a, b) => {
    return a.is_default_primary_color ? -1 : a.hex.localeCompare(b.hex);
  });

  // if selected color is not in the first 3, put it in last position so it's visible
  const selectedIndex = sortedColours.findIndex((color) => color.id === selected?.id);
  const firstThreeColours =
    selectedIndex > 2
      ? [...take(sortedColours, 2), sortedColours[selectedIndex]]
      : take(sortedColours, 3);

  return (
    <>
      {firstThreeColours.map((color) => {
        const isSelected = selected?.id === color.id;
        return (
          <Tooltip title={t(`${color.hex}`)} key={color.id}>
            <Box
              onClick={() => manager.color.setSelected(color.id)}
              sx={(theme) => ({
                width: "60px",
                height: "60px",
                borderRadius: `${theme.shape.borderRadius}px`,
                borderStyle: "solid",
                ...(isSelected
                  ? {
                      cursor: "default",
                      borderWidth: "3px",
                      borderColor: theme.palette.primary.main,
                    }
                  : {
                      cursor: "pointer",
                      borderWidth: "1px",
                      borderColor: "sidebar.divider",
                      "&:hover": {
                        borderColor: theme.palette.text.secondary,
                      },
                    }),
                backgroundColor: color.hex,
              })}
              role="button"
              data-testid={`brand-color-${color.hex}${isSelected ? "-selected" : ""}`}
            ></Box>
          </Tooltip>
        );
      })}
      {colors.length > 3 && (
        <More dialog={dialog} title={t("Apply Brand Colour")} tooltip={t("More Colours")}>
          <BrandKitColorChoiceRow
            colors={sortedColours}
            selected={selected}
            colorManager={{
              ...manager.color,
              setSelected: (value) => {
                manager.color.setSelected(value);
                dialog.handleClose();
              },
            }}
            showTitle={false}
            showDefaultIndicator={false}
          />
        </More>
      )}
    </>
  );
};
