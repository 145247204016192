import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const DesignHuddleLoadingModal: FC<{
  open: boolean;
  state: "connecting" | "initializing";
}> = ({ open, state }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle mt={2}>
        <Typography variant="h6">{t("Loading image editor")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} my={2}>
          <DialogContentText>
            {t("We're getting your content ready for you to edit.")}
          </DialogContentText>

          <DialogContentText>
            {state === "connecting" && t("Connecting to DesignHuddle...")}
            {state === "initializing" && t("Initializing editor...")}
          </DialogContentText>
          <LinearProgress />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
