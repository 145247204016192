import { marked } from "marked";
import { FC } from "react";

export const Markdown: FC<{ content: string }> = ({ content }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: marked(content) as string,
      }}
    />
  );
};
