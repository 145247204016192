import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../analytics/analytics";
import { previewsFamily } from "../create-content-card/store";
import { DhTemplateConfig, Layout } from "../create-content-card/types";
import { ContentPreviewImage, EditHandler } from "./content-preview-image";

export const ContentPreviews: FC<{
  formatId: string;
  contentTypeAppName: string;
  answers: { answer: string }[];
  templateConfig: DhTemplateConfig;
  layouts: Layout[];
}> = ({ formatId, contentTypeAppName, answers, templateConfig, layouts }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const numVariations = layouts.length;

  const [numVariationsToDisplay, setNumVariationsToDisplay] = useState(Math.min(numVariations, 4));

  const previews = useAtomValue(
    previewsFamily({
      formatId,
      answers,
      num: numVariationsToDisplay,
      templateConfig,
    }),
  );

  const ratio = useMemo(() => {
    if (templateConfig?.dimensions?.width && templateConfig?.dimensions?.height) {
      return templateConfig.dimensions.height / templateConfig.dimensions.width;
    }
    return 0;
  }, [templateConfig]);

  useEffect(() => {
    if (numVariations > 0) {
      setNumVariationsToDisplay(Math.min(numVariations, 4));
    }
  }, [numVariations, formatId, answers]);

  const { gaEvent } = useAnalytics();

  const loadMore = useRef<HTMLButtonElement>(null);

  const handleLoadMore = useCallback(() => {
    gaEvent("multiformat_button", { action: "click", value: "Load More" });

    setNumVariationsToDisplay((prev) => Math.min(prev + 4, numVariations));
    setTimeout(() => {
      loadMore.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }, [numVariations, gaEvent]);

  const handleEdit: EditHandler = useCallback(
    ({ page }) => {
      if (previews[page].preview.state === "hasData" && previews[page].preview.data) {
        const customizations = previews[page].preview.data.customizations;
        const d = btoa(
          JSON.stringify({
            customizations,
            templateConfig,
            page,
          }),
        );

        const params = new URLSearchParams({ d, contentTypeAppName });
        navigate({ pathname: "/create-content/edit", search: params.toString() });
      }
    },
    [navigate, previews, templateConfig, contentTypeAppName],
  );

  return (
    <>
      <Box
        sx={[
          (theme) => ({
            display: "grid",
            gap: 2,
            [theme.breakpoints.down("xs")]: {
              gridTemplateColumns: "1fr",
            },
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns:
                formatId === "zoom_background" ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
            },
          }),
        ]}
      >
        {previews.map((preview, i) => (
          <ContentPreviewImage
            key={i}
            image={preview.url}
            page={i}
            onEdit={handleEdit}
            ratio={ratio}
          />
        ))}
      </Box>
      {numVariationsToDisplay < numVariations && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <LoadingButton
            onClick={handleLoadMore}
            ref={loadMore}
            variant="contained"
            color="primary"
            size="large"
            loading={false}
          >
            {t("Load More")}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
