import { SvgIcon, SxProps, Theme, Tooltip } from "@mui/material";
import { FC, ReactElement } from "react";

export const ProgramIcon: FC<{
  data?: string | ReactElement;
  title?: string;
  sx?: SxProps<Theme>;
  fontSize?: "small" | "large";
}> = ({ data, title = "", sx = {}, fontSize = "large" }) => (
  <Tooltip title={title}>
    {typeof data === "string" ? (
      <SvgIcon fontSize={fontSize} sx={sx}>
        <g dangerouslySetInnerHTML={{ __html: data }} />
      </SvgIcon>
    ) : data ? (
      data
    ) : (
      <SvgIcon fontSize={fontSize} sx={sx}>
        {" "}
      </SvgIcon>
    )}
  </Tooltip>
);
