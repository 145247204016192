import { BaseSelectProps, MenuItem, Select } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BrandKitImageType } from "../server/brand-kit-service";
import { brandingImageTypeAtom } from "../store";

interface ImageTypeSelectProps extends Omit<BaseSelectProps, "onChange"> {
  onChange: (style: BrandKitImageType) => void;
}

export const ImageTypeSelect: FC<ImageTypeSelectProps> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const imageType = useAtomValue(brandingImageTypeAtom);

  return (
    <Select
      id="image-type-select"
      labelId="image-type-label"
      value={imageType ?? "square"}
      onChange={(e) => {
        const val = e.target.value;
        onChange(val as BrandKitImageType);
      }}
      sx={{ minWidth: "300px", maxWidth: "500px", mt: 4, display: "block" }}
      label={t("Image Type")}
      inputProps={{
        "aria-label": t("Image Type"),
      }}
      {...props}
    >
      <MenuItem value="square">{t("Square")}</MenuItem>
      <MenuItem value="banner">{t("Banner")}</MenuItem>
    </Select>
  );
};
