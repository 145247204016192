import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { IntegrationIcon } from "../../modules/generic/components/cards/integration-card";
import { useFlag } from "../../server/optimizely";
import { useSupabaseCallback, useSupabaseLoader } from "../../server/supabase/hooks";
import { TeamsIcon } from "../../styles/icons/teams";
import { useAsyncStateCallback } from "../../utils/hooks/use-async-state-callback";
import yup from "../../utils/yup";

const teamsIntegrationSchema = yup.object({
  tenant_id: yup.string().required("Enter a Tenant ID"),
  active: yup.boolean().required().default(false),
  default_bot_response: yup.string().nullable(),
  allow_channel_message_notify: yup.boolean().required().default(false),
  channel_notify_message: yup.string().required().default(""),
});

type TeamsIntegration = yup.InferType<typeof teamsIntegrationSchema>;

export const Teams: FC = () => {
  const { t } = useTranslation();

  const {
    reload,
    loading: integrationLoading,
    data: integration,
  } = useSupabaseLoader(async ({ supabase }) => {
    const { data, error } = await supabase.from("teams_integration").select().maybeSingle();
    if (error) throw error;

    return data;
  }, []);

  const { load: save, loading: saving } = useAsyncStateCallback(
    useSupabaseCallback(
      async ({ supabase }, upsertData: TeamsIntegration) => {
        const { error } = await supabase.from("teams_integration").upsert({
          id: integration?.id,
          ...upsertData,
        });

        if (error) throw error;

        reload();

        return true;
      },
      [reload, integration],
    ),
    [],
  );

  const doSave = useCallback(
    (data: TeamsIntegration): void =>
      void toast
        .promise(
          save(data),
          {
            loading: t(`Saving...`),
            success: t(`Saved`),
            error: t(`Error saving`),
          },
          { position: "top-right" },
        )
        .catch(console.error),
    [save, t],
  );

  const loading = integrationLoading || saving;

  if (loading) return <LinearProgress />;

  const hasIntegration = integration?.active ?? false;

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Avatar
            component="a"
            sx={{
              background: "transparent",
              mr: 2,
              mb: {
                xs: 2,
                md: 0,
              },
            }}
            variant="rounded"
          >
            <TeamsIcon />
          </Avatar>
          <Stack direction="column" spacing={1}>
            <Typography variant="h5">{t("Teams")}</Typography>
            <Stack direction="row" alignItems="center">
              <IntegrationIcon hasIntegration={hasIntegration} />
            </Stack>
            <TeamsForm data={integration ?? undefined} save={doSave} saving={saving} />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

const TeamsForm: FC<{
  data?: TeamsIntegration;
  save: (data: TeamsIntegration) => void;
  saving: boolean;
}> = ({ data, save, saving }) => {
  const { t } = useTranslation();

  const [teamsNotifyEnabled] = useFlag("teams_channel_notify_enabled");

  const methods = useForm<TeamsIntegration>({
    defaultValues: data ?? {
      tenant_id: "",
      active: false,
      default_bot_response: "",
    },
    resolver: yupResolver(teamsIntegrationSchema),
  });

  const { handleSubmit, control, watch } = methods;

  const allowChannelMessageNotify = watch("allow_channel_message_notify");

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(save, (errors) => console.log({ errors }))}>
        <Stack direction="column" spacing={2} sx={{ minWidth: "500px" }}>
          <Controller
            name="tenant_id"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("Tenant ID")}
                variant="standard"
                value={field.value}
                helperText={error?.message}
                error={Boolean(error)}
                disabled={data?.active}
              />
            )}
          />
          <Controller
            name="default_bot_response"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("Default Bot Response")}
                fullWidth
                variant="standard"
                multiline
                value={field.value}
                helperText={error?.message}
                error={Boolean(error)}
              />
            )}
          />
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label={t("Active")}
              />
            )}
          />
          {teamsNotifyEnabled && (
            <>
              <Controller
                name="allow_channel_message_notify"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} defaultChecked={field.value} />}
                    label={t("Enable notification option for Channel messages.")}
                  />
                )}
              />
              {allowChannelMessageNotify && (
                <Controller
                  name="channel_notify_message"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label={t("Message shown in the notification.")}
                      fullWidth
                      variant="standard"
                      multiline
                      value={field.value}
                      helperText={error?.message}
                      error={Boolean(error)}
                    />
                  )}
                />
              )}
            </>
          )}
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton loading={saving} disabled={saving} type="submit" variant="contained">
              {t("Save")}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
