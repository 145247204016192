import { Atom, SetStateAction, WritableAtom } from "jotai";
import { RESET } from "jotai/utils";
import { AtomFamily } from "jotai/vanilla/utils/atomFamily";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { Orientation } from "unsplash-js";
import { Basic } from "unsplash-js/dist/methods/photos/types";
import { NotNull } from "yup";
import { ISO8601_DATE } from "../../../../utils/iso8601";
import { UUID } from "../../../../utils/uuid";
import { BrandKitStyle } from "../../../brand-kit/server/brand-kit-service";
import { ChannelName } from "../../../channels/types";
import { Project } from "../../../design-huddle/types";
import { LibraryTemplate, LibraryTemplateImage } from "../../../discover/library/types";
import { ToneType } from "../../../self-driving/types";

export type TemplateImageGeneratedData = {
  header: string;
  subheader?: string;
  search?: string;
  Photo?: Basic;
};

export type TemplateImage = {
  url: Loadable<Promise<string>>;
  template_code: string;
  page_number: number;
  Photo?: string;
  header?: string;
  subheader?: string;
  unsplashExtra?:
    | {
        downloadUrl: string;
        attributionData: {
          url: string;
          name: string;
        };
      }
    | undefined;
  stableDiffusionImage: boolean;
  orientation?: Orientation;
  chatId?: UUID;
  style?: BrandKitStyle;
  dhProject?: Project | null;
};

export type LoadableTemplateImage = Loadable<Promise<TemplateImage | null>>;

export type Role = "user" | "system" | "assistant";

export type TemplateContent =
  | {
      type: "generated";
      data: {
        response: { content: string };
        chatId: UUID;
      };
    }
  | {
      type: "static";
      data: { response: { content: string } };
    };

export type LoadableTemplateContent = Loadable<Promise<TemplateContent | null>>;

export enum TemplateType {
  "moment" = "moment",
  "sequence" = "sequence",
}

export interface Template {
  type: TemplateType.moment;
  id: string;
  title: string;
  subject_line: string;
  what: string;
  slug: string;
  context: string;
  program: {
    color?: string;
    name: string;
    slug: string;
    photo?: string;
  };
  date: ISO8601_DATE | null;
  recommendations: {
    send_time: string;
    channel: string;
  };
  geo: string | null;
  end_date: ISO8601_DATE | null;
  trigger: string | null;
  segment: LibraryTemplate["segment"];
  trending: string | null;
  hero: boolean | null;
  top: number | null;
  content: {
    professional: LoadableTemplateContent;
    casual: LoadableTemplateContent;
    direct: LoadableTemplateContent;
    custom?: LoadableTemplateContent;
  };
  images: LoadableTemplateImage[];
  img?: LibraryTemplateImage;

  selectedImage: number;
  brandTone?: ToneType;
  channel: ChannelName;
}

export type TemplateAtom = Atom<Template>;

export type SequenceTemplate = {
  type: TemplateType.sequence;
  id: string;
  title: string;
  slug: string;
  description: string;
  img: LibraryTemplateImage;
  templates: Template[];
};

export type SequenceTemplateAtomType = Atom<Loadable<Promise<SequenceTemplate | null>>>;

export type TemplateImageFamilyAtom = AtomFamily<
  {
    num: number;
  },
  WritableAtom<
    Promise<{
      response: TemplateImageGeneratedData;
      chatId: UUID;
    } | null>,
    [
      | typeof RESET
      | SetStateAction<
          Promise<{
            response: TemplateImageGeneratedData;
            chatId: UUID;
          } | null>
        >,
    ],
    void
  >
>;

export interface ImagePreviewData {
  chatId: UUID;
  num: number;
  header: string;
  subheader?: string;
  search?: string;
  Photo?: Basic;
}

export type ImagePreviewDataFamilyAtom = AtomFamily<
  number,
  WritableAtom<
    Promise<ImagePreviewData | null>,
    [typeof RESET | SetStateAction<Promise<ImagePreviewData | null>>],
    void
  >
>;

export type ImagePreviewFamilyAtom = AtomFamily<
  number,
  Atom<Loadable<Promise<TemplateImage | null>>>
>;

export type Channel = { name: string; channel_name: NotNull<ChannelName> };
