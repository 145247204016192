import {
  Box,
  Button,
  Container,
  Typography,
  keyframes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onRetry: () => void;
};

const fade = keyframes`
0% {
  opacity: 0;
}
90% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const AppError: FC<Props> = ({ onRetry }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.title = t("Something went wrong");
    }, 4500);
    return () => clearTimeout(timeout);
  }, [t]);
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          backgroundColor: "background.paper",
          display: "flex",
          flexGrow: 1,
          py: "80px",
          animation: `${fade} 5s`,
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" variant={mobileDevice ? "h4" : "h1"}>
            {t("Something went wrong")}
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
            {t(
              "We've been told about the error and will try to fix it soon. Please try again by heading back home.",
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button href="/" component="a" variant="outlined" onClick={onRetry}>
              {t("Back Home")}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};
