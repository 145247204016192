import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CeDropdown } from "../generic/components/form-fields/ce-dropdown";
import { useContentTypeOptions } from "./hooks/use-content-type-options";
import { CreateContentRequest } from "./types";

export const ContentTypeSelect: FC = () => {
  const contentTypeOptions = useContentTypeOptions();
  const { t } = useTranslation();
  return (
    <CeDropdown<CreateContentRequest>
      label={t("Content Type")}
      name="format"
      loading={contentTypeOptions.loading}
      options={contentTypeOptions.data ?? []}
    />
  );
};
