import { Stack } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ContentPreviews } from "../../modules/content-previews/content-previews";
import { CreateContentCard } from "../../modules/create-content-card/create-content-card";
import { useMultiFormat } from "../../modules/create-content-card/hooks/use-multi-format";
import { ContentType, DhTemplateConfig } from "../../modules/create-content-card/types";
import { BrandingBar } from "../../modules/discover/branding-bar/branding-bar";
import { Page } from "../../modules/layout/components/page";
import { useFlag } from "../../server/optimizely";
import { toSlug } from "../../utils/create-slug";

const CreateContentPreviewPage: FC = () => {
  const navigate = useNavigate();
  const { data, loading } = useMultiFormat();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");
  useEffect(() => {
    if (multiFormatLoaded && !multiFormat) navigate("/create-moment");
  }, [multiFormat, multiFormatLoaded, navigate]);

  const format = searchParams.get("format");
  const contentType = data?.["content-types"].find(
    (c: ContentType) => c["Content Type"] === format,
  );
  const contentTypeAppName = contentType?.["CE App Name"] || "";

  const formatId = toSlug(format ?? "").replace(/-/g, "_");

  const dhTemplateConfig: DhTemplateConfig = {
    templateCode: contentType?.["AI File DesignHuddle ID"] ?? "AI Organic Test",
    dimensions: {
      width: contentType?.["DH Dimensions Width"] ?? undefined,
      height: contentType?.["DH Dimensions Height"] ?? undefined,
    },
  };

  const answers = useMemo(
    () => searchParams.getAll("answer").map((a) => ({ answer: a })),
    [searchParams],
  );

  if (!multiFormatLoaded) return null;

  return (
    <Page title={t("Create Content Preview | ChangeEngine")} sx={{ maxWidth: "xl" }}>
      <CreateContentCard />
      <Stack gap={4} m={5}>
        <Stack direction="row-reverse">
          <BrandingBar compact={true} />
        </Stack>
        {!loading && data && contentType && (
          <ContentPreviews
            templateConfig={dhTemplateConfig}
            formatId={formatId}
            contentTypeAppName={contentTypeAppName}
            answers={answers}
            layouts={data["breakdown"][contentType["Content Type"]]}
          />
        )}
      </Stack>
    </Page>
  );
};

export default CreateContentPreviewPage;
