import { FC, MutableRefObject, useRef } from "react";
import { Editor, EditorConfig } from "./types";
import { useDesignHuddle } from "./use-design-huddle";

export const DesignHuddleEditorFrame: FC<{
  project_id: string;
  setEditor: (v?: Editor) => void;
  state: "disabled" | "cloning" | "active";
  config?: Partial<EditorConfig>;
}> = ({ setEditor, project_id, state, config }) => {
  const div = useRef<HTMLDivElement>(null);

  useDesignHuddle(
    [project_id],
    div,
    (element: MutableRefObject<HTMLDivElement | null>) => {
      if (element.current) {
        DSHDLib.Editors.insert(element.current.id, { project_id, ...config }, (err, e) => {
          if (!err) setEditor(e);
        });
      }
    },
    [project_id],
  );

  return (
    <div
      ref={div}
      id={`designHuddle-${project_id}`}
      style={{
        height: "100%",
        ...(state !== "active" && { pointerEvents: "none" }),
        ...(state === "cloning" && { display: "none" }),
      }}
    />
  );
};
