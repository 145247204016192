import { FileUpload } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Link,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEmpty, snakeCase } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSegmentValueFieldCsvHelper } from "../hooks/use-segment-value-field-csv-helper";
import { useSegmentFieldValueOptions } from "../hooks/use-segment-value-options";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";
import { SegmentCondition } from "../types";

export const AutocompleteValueSelect: FC<{
  condition: SegmentCondition;
  segmentField?: UsableAccountPersonField;
  handleFieldChange: (value: string[]) => void;
  error?: string;
  disabled?: boolean;
  hidden?: boolean;
}> = ({ condition, handleFieldChange, error, disabled, hidden, segmentField }) => {
  const { t } = useTranslation();
  const selectedFieldOptionsState = useSegmentFieldValueOptions(segmentField, disabled);

  const {
    parsing,
    invalidEmailsDialog,
    handleEntries,
    exportCsvData,
    dropzoneState: { getRootProps, getInputProps, isDragActive, open: openFileUpload },
  } = useSegmentValueFieldCsvHelper({
    options: condition.values,
    allOptions: selectedFieldOptionsState.options,
    disabled: !!disabled,
    setOptions: handleFieldChange,
  });

  const isDisabled =
    disabled || selectedFieldOptionsState.loading || selectedFieldOptionsState.options.length === 0;

  return (
    <div {...getRootProps()}>
      <Autocomplete
        multiple
        size="small"
        limitTags={3}
        id={`value-${condition.id}`}
        options={selectedFieldOptionsState.options}
        hidden={hidden}
        disabled={isDisabled}
        fullWidth
        value={condition.values ? condition.values : []}
        data-testid="value-autocomplete"
        onChange={(_, value) => {
          handleFieldChange(value ? value.slice() : []);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} key={option} label={option} size="small" />
          ))
        }
        renderInput={(params) =>
          isDragActive ? (
            <Box
              sx={{
                mt: 0.4,
                border: "1px dashed",
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FileUpload
                onClick={openFileUpload}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    opacity: 0.5,
                  },
                  opacity: 0.5,
                }}
              />
            </Box>
          ) : (
            <Box sx={{ mt: 0.4 }}>
              <TextField
                {...params}
                error={Boolean(error)}
                helperText={
                  error ? (
                    error
                  ) : condition.values.length > 0 ? (
                    <Link
                      href={encodeURI(exportCsvData)}
                      download={`${snakeCase(condition.field)}.csv`}
                    >
                      <Typography variant="caption">
                        {t("Export CSV of existing values")}
                      </Typography>
                    </Link>
                  ) : null
                }
                label={segmentField?.display_name ?? t("Value")}
                variant="standard"
                onPaste={(e) => {
                  const pastedData = e.clipboardData.getData("Text");
                  handleEntries(pastedData.split(",").filter((x) => x.trim().length > 0));
                  e.preventDefault();
                }}
                InputProps={{
                  ...params.InputProps,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                  "data-testid": "segment-value-input",
                  endAdornment: (
                    <>
                      {selectedFieldOptionsState.loading || parsing ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        condition.values?.length === 0 && (
                          <InputAdornment position="end">
                            <input
                              data-testid="file-dropzone"
                              {...getInputProps()}
                              disabled={disabled}
                            />
                            <Tooltip
                              title={
                                <Link
                                  href="https://help.changeengine.com/Create-Segments-via-CSV-Upload-6ac96245f4ea4dc9b4d19ca54f24472a"
                                  target="_blank"
                                  color="inherit"
                                  underline="always"
                                  rel="noreferrer"
                                >
                                  {t(
                                    "You can upload a CSV containing the values you want to use to segment. Click here to find out more",
                                  )}
                                </Link>
                              }
                            >
                              <FileUpload
                                onClick={openFileUpload}
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                    opacity: 0.5,
                                  },
                                }}
                              />
                            </Tooltip>
                          </InputAdornment>
                        )
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            </Box>
          )
        }
      />
      {invalidEmailsDialog.open && invalidEmailsDialog.data && (
        <InvalidEmailsDialog
          valid={invalidEmailsDialog.data.valid}
          invalid={invalidEmailsDialog.data.invalid}
          duplicates={invalidEmailsDialog.data.duplicates}
          error={invalidEmailsDialog.data.error}
          onClose={invalidEmailsDialog.handleClose}
          onSubmit={(valid) => {
            invalidEmailsDialog.handleClose();
            handleFieldChange(valid);
          }}
        />
      )}
    </div>
  );
};

const InvalidEmailsDialog: FC<{
  valid: string[];
  invalid: string[];
  duplicates: Record<string, string[]>;
  error?: string;
  onClose: () => void;
  onSubmit: (valid: string[]) => void;
}> = ({ valid, invalid, duplicates, error, onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t("There was a problem uploading your data")}</DialogTitle>
      <DialogContent>
        {error && <Typography>{error}</Typography>}
        {invalid.length > 0 && (
          <>
            <Typography variant="subtitle1">{t("The following entries are invalid")}</Typography>
            <List>
              {invalid.map((entry, i) => (
                <ListItem key={i}>{entry}</ListItem>
              ))}
            </List>
          </>
        )}
        {!isEmpty(duplicates) && (
          <>
            <Typography variant="subtitle1">{t("The following entries are duplicated")}</Typography>
            <List>
              {Object.entries(duplicates).map(([key, values], i) => (
                <ListItem key={i}>
                  {key}: {values.join(", ")}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning">
          {t("Cancel")}
        </Button>
        {!error && (
          <Button onClick={() => onSubmit(valid)} color="info">
            {t("Continue")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
