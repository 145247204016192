import { atom } from "jotai";
import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { isEqual, zip } from "lodash-es";
import { brandKitStyles } from "../brand-kit/hooks/use-brand-kit";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { BrandTone } from "../brand-kit/server/brand-tone-service";
import { ChannelName } from "../channels/types";
import { Project } from "../design-huddle/types";
import { libraryAtom, libraryQueryAtom } from "../discover/library/store";
import {
  LibraryQuery,
  LibrarySegment,
  LibrarySequence,
  LibraryTemplate,
  isSequenceTemplateQuery,
} from "../discover/library/types";
import {
  customToneContentFamily,
  discoverContentFamily,
  discoverTemplateDHProjectFamily,
  discoverTemplateFamily,
  discoverTemplateImageFamily,
} from "../discover/store";
import { recommendationToChannel } from "../discover/transform";
import { channelsAtom, debugLabel } from "../generate/store";
import { Channel, Template, TemplateImage } from "../generic/atoms/types/template";

export const sequencesAtom = atom((get) => {
  const { sequences } = get(libraryAtom);
  return sequences;
});

/**
 * show/hide sequences in discover
 */
export const showSequencesAtom = atom<boolean>(false);

/**
 * The current library sequence (as per the query)
 */
export const sequenceAtom = atom<LibrarySequence | null>((get) => {
  const query = get(libraryQueryAtom);
  if (!query) return null;
  if (!isSequenceTemplateQuery(query)) return null;

  const { sequences } = get(libraryAtom);
  if (!sequences || sequences.length === 0) return null;

  const sequence = sequences.find((t) => t.slug === query.sequence_slug);
  if (!sequence) {
    console.info(`Sequence not found: ${query.sequence_slug}`);
    return null;
  }

  return sequence;
});

/**
 * The current template for the current sequence (as per the query)
 */
export const sequenceTemplateAtom = loadable(
  debugLabel(
    "discoverSequenceTemplateAtom",
    atom<Template | null>((get) => {
      return get(sequenceTemplateFamily(get(libraryQueryAtom)));
    }),
  ),
);

/**
 * a specific template in a specific sequence
 */
export const sequenceTemplateFamily = atomFamily(
  (query: LibraryQuery | null) =>
    debugLabel(
      `sequenceTemplateFamily/${JSON.stringify(query)}`,
      atom<Template | null>((get) => {
        const template = get(discoverTemplateFamily(query));
        if (!template) return null;

        const branding = get(sequenceTemplateBrandingFamily(query?.template_slug));
        return { ...template, selectedImage: branding ? branding.image : 0 };
      }),
    ),
  isEqual,
);

type SequenceBranding = { tone: ContentTone | "custom"; image: number; brandTone?: BrandTone };

/**
 * Branding for a specific template in the current sequence
 */
export const sequenceTemplateBrandingFamily = atomFamily((slug?: string) =>
  debugLabel(
    `sequenceTemplateWithBrandingFamily/${slug}`,
    atom<SequenceBranding>({
      tone: "professional" as ContentTone | "custom",
      image: 0,
      brandTone: undefined as BrandTone | undefined,
    }),
  ),
);

export const sequenceTemplateChannelFamily = atomFamily((slug?: string) =>
  debugLabel(
    `sequenceTemplateChannelFamily/${slug}`,
    atomWithDefault<Channel>((get) => {
      const template = get(sequenceAtom)?.templates.find((x) => x.slug === slug);
      const recommendedChannel = template?.recommendations.channel;
      if (!recommendedChannel || !slug) return get(channelsAtom)[0];

      const configuredChannels = get(channelsAtom);
      const channel = recommendationToChannel(
        recommendedChannel,
        undefined,
        configuredChannels.map((x) => x.channel_name),
      )?.channel;

      return configuredChannels.find((x) => x.channel_name === channel) ?? configuredChannels[0];
    }),
  ),
);

export const sequenceTemplateSegmentFamily = atomFamily((slug?: string) =>
  debugLabel(
    `sequenceTemplateSegmentFamily/${slug}`,
    atomWithDefault<LibrarySegment | undefined>((get) => {
      const template = get(sequenceAtom)?.templates.find((x) => x.slug === slug);
      return template?.segment;
    }),
  ),
);

const selectedTemplatesAtom = atom<LibraryTemplate[] | null>((get) => {
  const sequence = get(sequenceAtom);
  return sequence?.templates.filter(({ slug }) => get(sequenceTemplateSelectedFamily(slug))) ?? [];
});

/**
 * All selected templates in the current sequence
 */
export const sequenceNumSelectedTemplatesAtom = atom<number>((get) => {
  return get(selectedTemplatesAtom)?.length ?? 0;
});

/**
 * boolean for whether a template is selected in the current sequence
 */
export const sequenceTemplateSelectedFamily = atomFamily((slug: string) =>
  debugLabel(`template-selected-${slug}`, atom(true)),
);

/**
 * @async
 * Number of selected templates in the current sequence
 */
export const sequenceSelectedTemplatesLengthAtom = atom<number>((get) => {
  return get(selectedTemplatesAtom)?.length ?? 0;
});

/**
 * @async
 * All selected templates in the current sequence, with branding & image & content
 */
export const sequenceSelectedTemplatesAtom = loadable(
  atom<
    Promise<
      (LibraryTemplate & {
        dhProject: Project | null;
        image: TemplateImage | null;
        branding: SequenceBranding;
        channel: ChannelName;
      })[]
    >
  >(async (get) => {
    const selectedTemplates = get(selectedTemplatesAtom);

    if (!selectedTemplates || selectedTemplates.length === 0) return [];

    const templatesWithBranding = zip(
      selectedTemplates,
      selectedTemplates.map((template) => sequenceTemplateBrandingFamily(template.slug)).map(get),
    ).filter(([template, branding]) => template && branding) as [
      LibraryTemplate,
      SequenceBranding,
    ][];

    return await Promise.all(
      templatesWithBranding.map(async ([template, branding]) => {
        const dhProject = get(
          discoverTemplateDHProjectFamily({ slug: template.slug, pageNumber: branding.image + 1 }),
        );

        const image = get(
          discoverTemplateImageFamily({
            slug: template.slug,
            img: template.img,
            style: brandKitStyles[branding.image],
          }),
        );

        const { content, ...rest } = template;

        if (branding.tone === "custom" && branding.brandTone) {
          const customToneContent = await get(
            customToneContentFamily({ customTone: branding.brandTone, template }),
          );
          if (customToneContent) {
            content.custom = customToneContent.data.response.content;
          }
        } else {
          content[branding.tone] =
            (await get(discoverContentFamily({ template, contentKey: branding.tone })))?.data
              .response.content ?? "";
        }

        return {
          ...rest,
          content,
          branding,
          image,
          dhProject,
          channel: get(sequenceTemplateChannelFamily(template.slug))?.channel_name ?? null,
        };
      }),
    );
  }),
);
