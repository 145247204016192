import { ContentCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../utils/uuid";
import { useCopyDialogs } from "./use-copy-dialogs";

export const CopyAction: FC<{
  id: UUID;
  title: string | null;
  clonable: boolean | "warn_first";
}> = ({ id, title, clonable }) => {
  const { t } = useTranslation();

  const { handleCopy, dialogControl, CopyDialogComponent } = useCopyDialogs({
    clonable,
    id,
  });

  return (
    <>
      <Tooltip title={t("Copy Moment: {{title}}", { title })}>
        <IconButton onClick={handleCopy}>
          <ContentCopy fontSize="small" sx={{ marginLeft: 0 }} data-testid="moment-copy-icon" />
        </IconButton>
      </Tooltip>
      {CopyDialogComponent && (
        <CopyDialogComponent
          id={id}
          title={title}
          open={dialogControl.open}
          onClose={dialogControl.handleClose}
        />
      )}
    </>
  );
};
