import { createContext, FC, ReactNode, useEffect, useState } from "react";

export type DisplayTheme = "light" | "dark";

export interface Settings {
  theme: DisplayTheme;
  openAccordions: boolean;
  expandListView: boolean;
  newUi: boolean;
  newUiDialogAcknowledged: boolean;
  showedAiIntro?: boolean;
  showReleaseNotesOnStartup: boolean;
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
  set?: <T extends keyof Settings>(key: T, value: Settings[T]) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  theme: "light",
  openAccordions: false,
  expandListView: false,
  newUi: true,
  newUiDialogAcknowledged: false,
  showedAiIntro: false,
  showReleaseNotesOnStartup: true,
};

export const restoreSettings = (): Settings | null => {
  let settings: Settings | null = null;

  try {
    const storedData = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData) as Settings;
    } else {
      settings = {
        theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
        openAccordions: false,
        expandListView: false,
        newUi: false,
        newUiDialogAcknowledged: false,
        showedAiIntro: false,
        showReleaseNotesOnStartup: true,
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a stringified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

export const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => undefined,
  set: () => undefined,
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  const set = <T extends keyof Settings>(key: T, value: Settings[T]): void => {
    saveSettings({ ...settings, [key]: value });
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
        set,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
