import { Help } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { usePopover } from "../../utils/hooks/use-popover";
import { useSettings } from "../../utils/hooks/use-settings";
import { Item } from "../layout/components/sidebar/item";
import { Section } from "../layout/components/sidebar/section";
import { useReleaseNotes } from "./use-release-notes";

export const ReleaseNotes: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const popover = usePopover<HTMLButtonElement>();

  const { settings, set: setSetting } = useSettings();
  const { newReleaseNotes, seenReleaseNotes, handleView } = useReleaseNotes();

  const isActive = useCallback(
    (path: string, startsWith?: boolean) => (): boolean =>
      startsWith ? location.pathname.startsWith(path) : location.pathname === path,
    [location.pathname],
  );

  useEffect(() => {
    if (settings.showReleaseNotesOnStartup && newReleaseNotes.length > 0) {
      popover.handleOpen();
    }
  }, [settings, newReleaseNotes, popover]);

  const handleChange = useCallback(() => {
    setSetting?.("showReleaseNotesOnStartup", !settings.showReleaseNotesOnStartup);
  }, [setSetting, settings]);

  const handleClose = useCallback(() => {
    handleView(newReleaseNotes.map((note) => note.id));
    popover.handleClose();
  }, [handleView, newReleaseNotes, popover]);

  // console.log({ seenReleaseNotes, newReleaseNotes });

  return (
    <>
      {(newReleaseNotes.length > 0 || seenReleaseNotes.length > 0) && (
        <Section>
          {newReleaseNotes.length > 0 ? (
            <Badge
              color="primary"
              badgeContent="NEW"
              overlap="circular"
              sx={{ "& .MuiBadge-badge": { top: "18px" }, cursor: "pointer" }}
              onClick={popover.handleToggle}
              ref={popover.anchorRef}
            >
              <Item title={t("Product Updates")} icon={<Help />} active={isActive("/help")} />
            </Badge>
          ) : (
            <Item
              title={t("Product Updates")}
              icon={<Help />}
              active={isActive("/help")}
              onClick={{ type: "function", ref: popover.anchorRef, value: popover.handleToggle }}
            />
          )}
        </Section>
      )}
      <Dialog open={popover.open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Trans>ChangeEngine has been updated!</Trans>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 3 }} maxWidth={700}>
            {newReleaseNotes.map((note, i) => (
              <Box key={note.id}>
                <Trans i18nKey={`release-notes-${note.id}`}>{note.content}</Trans>
                {(i < newReleaseNotes.length - 1 || seenReleaseNotes.length > 0) && (
                  <Divider sx={{ my: 2 }} />
                )}
              </Box>
            ))}
            {newReleaseNotes.length === 0 &&
              seenReleaseNotes.map((note, i) => (
                <Box key={note.id}>
                  <Trans i18nKey={`release-notes-${note.id}`}>{note.content}</Trans>
                  {i < seenReleaseNotes.length - 1 && <Divider sx={{ my: 4 }} />}
                </Box>
              ))}
          </Box>
        </DialogContent>
        <Box display="flex" p={2}>
          <Box flexGrow={1}>
            <FormControlLabel
              control={
                <Checkbox checked={settings.showReleaseNotesOnStartup} onChange={handleChange} />
              }
              label={t("Show product updates on startup")}
            />
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleClose}>
              {t("OK")}
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              href="https://help.changeengine.com/Product-Update-c5a58214d12745a0958c068b498e21bb"
              target="_blank"
              rel="noreferrer"
              onClick={handleClose}
            >
              {t("Find out more")}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
