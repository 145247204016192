import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Account } from "../../../generic/server/supabase-account-service";
import { MomentCardDropData } from "../../types/moment-card-drop-data";
import { CopyMomentDialogForm } from "../actions/copy-dialog";

export const CopyDialog: FC<{
  open: boolean;
  momentExport: ({ status: "ready" } & MomentCardDropData) | { status: "loading" | "error" };
  destinationAccount: Pick<Account, "id" | "name">;
  handleClose: () => void;
  onConfirm: (data?: { name: string }) => Promise<void>;
}> = ({ open, momentExport, destinationAccount, handleClose, onConfirm }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirm = useCallback(
    async (data?: { name: string }) => {
      setButtonLoading(true);
      await onConfirm(data);
      handleClose();
      setButtonLoading(false);
    },
    [setButtonLoading, onConfirm, handleClose],
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{t("Copy Moment")}</DialogTitle>
      {momentExport.status === "ready" &&
        momentExport.sourceAccount.id !== destinationAccount.id && (
          <>
            <DialogContent>
              <Box>
                {t(`Moment: "{{momentTitle}}"`, { momentTitle: momentExport.moment.title })}
              </Box>
              <Stack sx={{ mt: 2 }} direction="row" spacing={1}>
                <Typography>{momentExport.sourceAccount.name}</Typography>
                <ArrowForward />
                <Typography>{destinationAccount.name}</Typography>
              </Stack>
              <Typography sx={{ mt: 2 }}>
                {t("Are you sure you wish to proceed with copying this Moment?")}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={buttonLoading}
                onClick={() => void handleConfirm().catch(console.error)}
              >
                {t("Confirm")}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      {momentExport.status === "ready" &&
        momentExport.sourceAccount.id === destinationAccount.id && (
          <CopyMomentDialogForm
            title={momentExport.moment.title}
            onSubmit={(x) => void handleConfirm(x).catch(console.error)}
            onClose={handleClose}
          />
        )}
      {momentExport.status === "loading" && (
        <>
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </DialogActions>
        </>
      )}
      {momentExport.status === "error" && (
        <>
          <DialogContent>
            <Alert severity="error">{t("Failed to export Moment.")}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
