import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { groupBy } from "lodash-es";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabase } from "../../../../server/supabase/hooks";
import { XCircle } from "../../../../styles/icons/x-circle";
import { SupabaseAccountLimitService } from "../../../generic/server/supabase-account-service";
import { Program, SupabaseProgramService } from "../../../programs/server/supabase-program-service";
import {
  Permissions,
  PersonTextDataJson,
  SupabasePermissionsService,
  SupabasePersonTextDataJsonService,
} from "../../server/supabase-person-service";
import { PermissionsLimits } from "../../types";
import { PermissionsForm } from "./permissions-form";

type ProgramMap = { [k: string]: Program };

export const PermissionsTable: FC = () => {
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);

  const [permissionsState, setPermissionsState] = useState<
    | {
        programs: Program[];
        permissions: Permissions[];
        employees: PersonTextDataJson[];
        limits: PermissionsLimits;
      }
    | "loading"
  >("loading");

  useSupabase(
    async ({ supabase, account_id }) => {
      if (permissionsState !== "loading") return;

      const [
        { data: programData, error: programError },
        { data: permsData, error: permsError },
        { data: personTextData, error: personTextError },
        { data: limitData, error: limitError },
      ] = await Promise.all([
        new SupabaseProgramService(supabase).getAll(null, { order: [{ column: "id" }] }),
        new SupabasePermissionsService(supabase).getAll(account_id, { order: [{ column: "id" }] }),
        new SupabasePersonTextDataJsonService(supabase).getAll(account_id, {
          order: [{ column: "person_id" }],
        }),
        new SupabaseAccountLimitService(supabase).getKeys(["contributors", "super_admins"]),
      ]);

      if (programError || permsError || personTextError || limitError)
        return setPermissionsState({
          programs: [],
          permissions: [],
          employees: [],
          limits: { contributors: 0, super_admins: 0 },
        });

      setPermissionsState({
        programs: programData || [],
        permissions: permsData || [],
        employees: personTextData || [],
        limits: {
          contributors: limitData?.find((d) => d.key === "contributors")?.count ?? 0,
          super_admins: limitData?.find((d) => d.key === "super_admins")?.count ?? 0,
        },
      });
    },
    [permissionsState],
  );

  const programs: ProgramMap =
    permissionsState === "loading"
      ? {}
      : Object.fromEntries(permissionsState.programs.map((p) => [p.id, p]));

  const { superAdmins, contributors } =
    permissionsState === "loading"
      ? { superAdmins: null, contributors: null }
      : groupBy(permissionsState.permissions, (p) =>
          p.super_admin ? "superAdmins" : "contributors",
        );

  const capacity =
    permissionsState === "loading"
      ? { contributors: 0, super_admins: 0 }
      : {
          contributors: Math.max(
            0,
            permissionsState.limits.contributors - (contributors?.length ?? 0),
          ),
          super_admins: Math.max(
            0,
            permissionsState.limits.super_admins - (superAdmins?.length ?? 0),
          ),
        };

  const availableEmployees =
    permissionsState === "loading"
      ? []
      : permissionsState.employees.filter(
          ({ person_id }) => !permissionsState.permissions.find((p) => p.id === person_id),
        );

  return (
    <>
      {permissionsState !== "loading" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 2,
          }}
        >
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => setOpenCreate(true)}
          >
            {t("Add New")}
          </Button>
          <PermissionsForm
            open={openCreate}
            close={() => setOpenCreate(false)}
            employees={availableEmployees}
            capacity={capacity}
            programs={permissionsState.programs}
            onComplete={(permissions) => setPermissionsState({ ...permissionsState, permissions })}
          />
          <Box>
            <Chip
              label={`Contributors ${Math.min(
                permissionsState.limits.contributors,
                contributors?.length ?? 0,
              )}/${permissionsState.limits.contributors}`}
            />
            <Chip
              sx={{ ml: 1 }}
              label={`Super Admins ${Math.min(
                permissionsState.limits.super_admins,
                superAdmins?.length ?? 0,
              )}/${permissionsState.limits.super_admins}`}
            />
          </Box>
        </Box>
      )}

      <TableContainer component={Paper}>
        {permissionsState === "loading" && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="35%">{t("Name")}</TableCell>
              <TableCell width="15%">{t("Permission Type")}</TableCell>
              <TableCell width="20%">{t("Read Only Programs")}</TableCell>
              <TableCell width="20%">{t("Editable Programs")}</TableCell>
              <TableCell align="right">{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          {permissionsState !== "loading" && (
            <TableBody>
              {permissionsState.permissions.map((perm) => (
                <Row
                  key={perm.id}
                  permission={perm}
                  rawPrograms={permissionsState.programs}
                  employees={availableEmployees.concat(
                    permissionsState.employees.filter((e) => e.person_id === perm.id),
                  )}
                  capacity={capacity}
                  programs={programs}
                  refresh={() => setPermissionsState("loading")}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

const Row: FC<{
  permission: Permissions;
  rawPrograms: Program[];
  employees: PersonTextDataJson[];
  capacity: PermissionsLimits;
  programs: ProgramMap;
  refresh: () => void;
}> = ({ permission, rawPrograms, employees, capacity, programs, refresh }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<"read_only_program" | "editable_program">();

  return (
    <>
      <TableRow key={permission.id}>
        <TableCell>{permission.email}</TableCell>
        <TableCell>{permission.super_admin ? t("Super Admin") : t("Contributor")}</TableCell>
        <TableCell>
          <Button
            onClick={() =>
              setOpen((prev) => (prev === "read_only_program" ? undefined : "read_only_program"))
            }
            disabled={permission.super_admin ?? false}
          >
            {permission.super_admin ? t("All") : permission.read_only_program?.length || 0}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            onClick={() =>
              setOpen((prev) => (prev === "editable_program" ? undefined : "editable_program"))
            }
            disabled={permission.super_admin ?? false}
          >
            {permission.super_admin ? t("All") : permission.editable_program?.length || 0}
          </Button>
        </TableCell>
        <TableCell align="right">
          <Actions
            permission={permission}
            employees={employees}
            capacity={capacity}
            programs={rawPrograms}
            refresh={refresh}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open !== undefined} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {open && (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    {open === "read_only_program"
                      ? t("Read Only Programs")
                      : t("Editable Programs")}
                  </Typography>
                  {permission[open]?.map((id) => (
                    <Chip sx={{ m: 1 }} key={id} label={programs[id]?.title} />
                  ))}
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Actions: FC<{
  permission: Permissions;
  employees: PersonTextDataJson[];
  capacity: PermissionsLimits;
  programs: Program[];
  refresh: () => void;
}> = ({ permission, employees, capacity, programs, refresh }) => {
  return (
    <>
      <EditAction
        permission={permission}
        employees={employees}
        capacity={capacity}
        programs={programs}
        onEdit={refresh}
      />
      <DeleteAction permission={permission} onDelete={refresh} />
    </>
  );
};

const DeleteAction: FC<{ permission: Permissions; onDelete: () => void }> = ({
  permission,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);

  useSupabase(
    async ({ supabase }) => {
      if (!deleting || !permission.id) return;

      const { error } = await new SupabasePermissionsService(supabase).removePermissions(
        permission.id,
      );
      if (error) toast.error(t("Unable to delete"));
      else {
        toast.success(t("Deleted"));
        onDelete();
      }

      setDeleting(false);
    },
    [deleting, permission.id, onDelete, t],
  );

  return (
    <>
      <Tooltip title={t("Delete")} placement="top" sx={{ cursor: "pointer", mr: 1 }}>
        {deleting ? (
          <CircularProgress size="small" />
        ) : (
          <XCircle fontSize="small" color={"info"} onClick={() => setDeleting(true)} />
        )}
      </Tooltip>
    </>
  );
};

const EditAction: FC<{
  permission: Permissions;
  employees: PersonTextDataJson[];
  capacity: PermissionsLimits;
  programs: Program[];
  onEdit: () => void;
}> = ({ permission, employees, capacity, programs, onEdit }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);

  return (
    <>
      <Tooltip title={t("Edit")} placement="top" sx={{ cursor: "pointer", mr: 1 }}>
        {editing ? (
          <CircularProgress size="small" />
        ) : (
          <Edit fontSize="small" color={"info"} onClick={() => setEditing(true)} />
        )}
      </Tooltip>
      {editing && (
        <PermissionsForm
          open={editing}
          close={() => setEditing(false)}
          employees={employees}
          capacity={capacity}
          programs={programs}
          existing={permission}
          onComplete={onEdit}
        />
      )}
    </>
  );
};
