import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CeTextInput } from "../../../generic/components/form-fields/ce-text-field";
import { CopyDialogProps, copyMomentSchema } from "./copy-dialog";

export const CopyWarningDialogWithRename: FC<CopyDialogProps> = ({ id, open, title, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: { name: `Copy of ${title ?? ""}` },
    resolver: yupResolver(copyMomentSchema),
  });

  const onSubmit = useCallback(
    (data: { name: string }, no_message: boolean) => {
      navigate(
        `/moments/copy/${id}?new_title=${encodeURIComponent(data.name)}${no_message ? "&no_message=1" : ""}`,
      );
    },
    [id, navigate],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <FormProvider {...methods}>
        <form>
          <DialogTitle>{t("Clone this Moment with Newsletter editor?")}</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <DialogContentText>
                {t(
                  "You are about to clone this Moment using the Newsletter Editor. This is discouraged in this use case. " +
                    "We recommend starting from scratch with our newer editor so that you can take advantage of our AI and Image creation tools.",
                )}
              </DialogContentText>
              <CeTextInput name="name" margin="dense" label={t("Name")} fullWidth />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={onClose}>
              {t("Cancel")}
            </Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button color="warning" onClick={methods.handleSubmit((data) => onSubmit(data, false))}>
              {t("Copy")}
            </Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button color="primary" onClick={methods.handleSubmit((data) => onSubmit(data, true))}>
              {t("Copy Without Message")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
