import { createHeadlessEditor } from "@lexical/headless";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { isValid } from "date-fns";
import { SerializedElementNode } from "lexical";
import { Json } from "../../server/supabase/types/database-definitions";
import { channelToFormat } from "../../utils/channel-to-format";
import { ISO8601_DATE } from "../../utils/iso8601";
import { userTimeZone } from "../../utils/time-zones";
import { UUID, uuidv4 } from "../../utils/uuid";
import { BrandKitImageType } from "../brand-kit/server/brand-kit-service";
import { ChannelName } from "../channels/types";
import { makeEmptySerializedState } from "../email-design/util";
import { DefaultProportionOfMaxWidth } from "../message/editor/nodes/design-huddle-node";
import { EDITOR_NODES_HEADLESS } from "../message/editor/utils/nodes-headless";
import { LibraryTemplate } from "./library/types";
import { MERGE_TAG, TRANSFORMERS } from "./transformers";

type DesignHuddleInfo = {
  project_id: string;
  account_id: string;
  page_number: number;
  image_type?: BrandKitImageType;
};

export function toImportMoment(
  template: LibraryTemplate,
  tone: keyof LibraryTemplate["content"],
  emailDesignId?: UUID,
  designHuddleInfo?: DesignHuddleInfo,
  selectedChannel?: ChannelName,
  designHuddleImage?: string,
  from?: string,
  enableTeams?: boolean,
): Json {
  const channelInfo = recommendationToChannel(
    template.recommendations.channel,
    selectedChannel,
    enableTeams ? ["teams"] : undefined,
  );
  const targetChannel = Boolean(channelInfo?.target_channel);

  return {
    title: template.title,
    program: { title: template.program.name },
    schedule: null,
    timezone: targetChannel ? userTimeZone() : null,
    immediate: false,
    description: template.context,
    cover_image_path: designHuddleImage ?? null,
    only_additional_recipients: targetChannel,
    use_lexical_editor: true,
    email_subject: template.content.email_subject,
    discover_moment_launched_from: template.title,
    email_design_id: emailDesignId ?? null,
    design: design(
      template.content[tone] ?? template.content["professional"],
      channelToFormat(selectedChannel ?? "work_email"),
      designHuddleInfo,
    ),
    from: from ?? null,
    ...target(template.segment),
    ...(channelInfo ?? {}),
    ...when(template.date, template.recommendations.send_time),
  };
}

export function recommendationToChannel(
  channelName: string,
  selectedChannel?: ChannelName,
  configuredChannels?: ChannelName[],
): {
  channel: NonNullable<ChannelName>;
  target_channel?: boolean;
} | null {
  let data: { channel: NonNullable<ChannelName>; target_channel?: boolean } | null = null;

  switch (channelName) {
    case "Work Email":
      data = { channel: "work_email" };
      break;
    case "Personal Email":
      data = { channel: "personal_email" };
      break;
    case "Slack/MS Teams 1:1":
      data = {
        channel: configuredChannels?.includes("teams") ? "teams" : "slack",
        target_channel: false,
      };
      break;
    case "Slack/MS Teams Channel":
      data = {
        channel: configuredChannels?.includes("teams") ? "teams" : "slack",
        target_channel: true,
      };
      break;
    default:
      break;
  }

  if (!selectedChannel) return data;

  if (data?.channel === selectedChannel) return data;

  // If the channel is not the same as the selected channel, we return the selected channel
  return { channel: selectedChannel, target_channel: false };
}

function target(
  segment: LibraryTemplate["segment"],
  preventSlack?: boolean,
): { [key: string]: Json } {
  if (segment === "Managers of all employees") {
    return { target_manager: true, target_channel: false, segment: { name: "Current employees" } };
  } else if (segment === "#general" && !preventSlack) {
    return { target_manager: false, target_channel: true };
  }

  const data = { target_manager: false, target_channel: false };
  if (segment === "All employees") {
    return { ...data, segment: { name: "Current employees" } };
  } else if (segment === "All managers") {
    return {
      ...data,
      segment: {
        name: "All Managers",
        children: [
          {
            condition: [
              {
                field: "event_became_manager",
                operation: "IS EMPTY",
                values: [],
              },
            ],
            operation: "NOT",
          },
        ],
        condition: null,
        operation: "AND",
      },
    };
  }

  return data;
}

function design(
  content: string,
  format: "email" | "chat" | "sms",
  designHuddleInfo?: DesignHuddleInfo,
): Json {
  const editor = createHeadlessEditor({
    nodes: EDITOR_NODES_HEADLESS[format],
  });

  editor.update(
    () =>
      // replace all nbsp with space to avoid markdown parser issues
      // Replace all "\\-" with "-" to avoid markdown parser issues
      $convertFromMarkdownString(content.replaceAll(/^\u00A0+/gm, " ").replaceAll(/\\-/g, "-"), [
        ...TRANSFORMERS(format),
        MERGE_TAG,
      ]),
    {
      discrete: true,
    },
  );

  const children = [
    ...(designHuddleInfo
      ? [
          {
            type: "design-huddle",
            id: designHuddleInfo.project_id,
            account_id: designHuddleInfo.account_id,
            version: 2,
            page_number: designHuddleInfo.page_number,
            format: "",
            aspect: designHuddleInfo.image_type === "banner" ? 3 : 1,
            proportionOfMaxWidth: DefaultProportionOfMaxWidth,
            cacheBustNumber: 1,
            mustBeCloned: null,
            customisations: null,
            href: null,
          },
        ]
      : []),
    ...editor.getEditorState().toJSON().root.children,
  ];

  const data = makeEmptySerializedState(children as SerializedElementNode[]);

  // HACK: At the moment lexical dose not implement markdown escapes; do the only one we use.
  const escapedData = JSON.parse(JSON.stringify(data).replaceAll("\\\\.", ".")) as Json;
  return escapedData;
}

export function when(date: string | null, recommended_send_time: string): { [key: string]: Json } {
  if (date !== null)
    return {
      schedule: {
        event: {
          key: `date-only-${uuidv4()}`,
          date: date,
          title: null,
          source: "IMPORT",
        },
        send_at: "12:00:00",
        event_repeat: false,
        on_event_key: null,
        event_initial: true,
        interval_json: {},
      },
    };

  const send_time = recommended_send_time.toLowerCase();

  if (send_time === "immediately") return { immediate: true, schedule: null };

  return handleNBeforeEvent(send_time) ?? handleDayOfOrOnOrStartOf(send_time) ?? {};
}

function handleNBeforeEvent(send_time: string): { [key: string]: Json } | undefined {
  // e.g 1 day before start date
  const match = send_time.match(
    /(\d+)\s+(day|days|week|weeks|month|months|year|years)\s(before|after)\s(start date|promotion to manager|birthday)/,
  );
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, number, unit, direction, event] = match;

    const pluralisedUnit = unit.endsWith("s") ? unit : `${unit}s`;
    const interval_json = {
      [pluralisedUnit]: parseInt(number) * (direction === "before" ? -1 : 1),
    };

    return {
      schedule: {
        send_at: "12:00:00",
        interval_json,
        on_event_key: null,
        ...eventInfo[event],
      },
    };
  }
}

function handleDayOfOrOnOrStartOf(send_time: string): { [key: string]: Json } | undefined {
  // Like Day of Independence Day (July 4th)
  // OR Day of World Health Day (7th April)
  // OR On Independence Day (July 4th)
  // OR Start Of Earth Month (April)
  const match = send_time.match(/(?:day of|on|start of) .*? \(([^)]+)\)/);
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, date] = match;

    const parsedDate = convertToISO8601(date);
    if (!parsedDate) return undefined;
    return {
      schedule: {
        event: {
          key: `date-only-${uuidv4()}`,
          date: parsedDate,
          title: null,
          source: "IMPORT",
        },
        send_at: "12:00:00",
        event_repeat: false,
        on_event_key: null,
        event_initial: true,
        interval_json: {},
      },
    };
  }
}

const eventInfo: { [key: string]: { [key: string]: Json } } = {
  "start date": {
    event_key: "start_date",
    event_initial: true,
    event_repeat: false,
  },
  "promotion to manager": {
    event_key: "became_manager",
    event_initial: true,
    event_repeat: false,
  },
  birthday: {
    event_key: "birthday",
    event_initial: true,
    event_repeat: true,
  },
};

function convertToISO8601(dateString: string): ISO8601_DATE | null {
  // Parse the input date string to get month and day
  const parsed =
    dateString.match(/(?<day>\d+)(?:st|nd|rd|th)?\s+(?<monthName>\w+)/) ??
    dateString.match(/(?<monthName>\w+)\s+(?<day>\d+)(?:st|nd|rd|th)?/) ??
    dateString.match(/(?<monthName>\w+)/);

  if (!parsed) return null;

  const { day, monthName } = parsed.groups as { day?: string; monthName: string };

  // Convert month name to month number (1-indexed)
  const monthIndex = new Date(Date.parse(`${monthName} 1, 1970`)).getMonth() + 1;

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Create a new date object with the parsed values and current year
  const date = new Date(`${currentYear}-${monthIndex}-${day ?? 1}`);

  // Check if the date is valid
  if (!isValid(date)) return null;

  // Return the date in ISO 8601 format
  return date.toISOString().substring(0, 10) as ISO8601_DATE;
}
