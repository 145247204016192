import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../utils/uuid";

export const EditAction: FC<{ id: UUID | null; title: string | null }> = ({ id, title }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("Edit Moment: {{title}}", { title })}>
      <IconButton href={`/moments/${id || ""}`}>
        <Edit fontSize="small" sx={{ marginLeft: 0 }} data-testid="moment-edit-icon" />
      </IconButton>
    </Tooltip>
  );
};
