import { R2Service } from "../server/cloudflare/r2-service";
import { uuidv4 } from "./uuid";

export const convertEmailSvgImage = async (
  url: string,
  getToken: () => Promise<string>,
): Promise<{ success: true; url: string } | { success: false; url: undefined }> => {
  const img = await fetch(url);
  const blob = await img.blob();

  if (!blob.type.includes("image/svg")) {
    return { success: true, url };
  }

  const imageService = new R2Service();
  const id = uuidv4() as string;
  const file = await convertSvgImage(blob);
  return await imageService.upload(file, id, await getToken());
};

export const convertSvgImage = (file: File | Blob): Promise<File> => {
  const url = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) return reject(Error("Could not get canvas context"));

      canvas.height = img.height;
      canvas.width = img.width;

      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        if (!blob) return reject(Error("Could not get blob from canvas"));

        resolve(
          new File([blob], file instanceof File ? file.name : "", {
            type: "image/jpeg",
          }),
        );
      });
    };
    img.onerror = reject;
    img.src = url;
  });
};
