import { useDeepCompareEffect } from "@react-hookz/web";
import { DependencyList, useCallback, useState } from "react";
import { Database } from "../types/database-definitions";
import { SupabaseHandlerArg, SupabaseSchemaName, UseSupabaseLoaderState } from "./types";
import { useSupabase } from "./use-supabase";

/**
 * An Effect for loading data from Supabase with a reload handler; may redirect if not authenticated.
 */
export function useSupabaseLoader<T, SchemaName extends SupabaseSchemaName<Database> = "public">(
  callback: (arg0: SupabaseHandlerArg<SchemaName>) => Promise<T>,
  deps: DependencyList,
  schema: SchemaName = "public" as SchemaName,
): UseSupabaseLoaderState<T> & { reload: () => void } {
  const [state, setState] = useState<UseSupabaseLoaderState<T>>({
    data: null,
    loading: true,
    error: null,
  });

  useSupabase(
    async (ctx) => {
      if (!ctx) return setState({ data: null, loading: true, error: null });

      if (!state.loading) return;

      try {
        const data = await callback(ctx);
        setState({ data, loading: false, error: null });
      } catch (error) {
        return setState({ data: null, loading: false, error: error as Error });
      }
    },
    [state.loading, callback],
    schema,
  );

  const reload = useCallback(() => setState({ data: null, loading: true, error: null }), []);

  useDeepCompareEffect(reload, deps);

  return { ...state, reload };
}
