import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDiscoverLibraryLoader } from "../../modules/discover/hooks/use-libary-loader";
import { libraryAtom } from "../../modules/discover/library/store";
import { SupabaseManageDiscoverService } from "../../modules/discover/server/supabase-discover-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useSupabase, useSupabaseCallback } from "../../server/supabase/hooks";
import yup from "../../utils/yup";
import CeAdminPageHeader from "./ce-admin-page-header";

export function filterUndef<T>(ts: (T | undefined)[]): T[] {
  return ts.filter((t: T | undefined): t is T => !!t);
}

const ManageDiscoverPage: FC = () => {
  useDiscoverLibraryLoader();
  const library = useAtomValue(libraryAtom);

  const [programCarousels, setProgramCarousels] = useState<string[] | "loading">("loading");

  const { t } = useTranslation();

  useSupabase(
    async ({ supabase }) => {
      if (programCarousels !== "loading") return;

      const service = new SupabaseManageDiscoverService(supabase);

      const { data, error } = await service.get(42);

      if (!data || error) {
        toast.error("Could not load carousel order info");
      }

      setProgramCarousels(data?.program_carousel_order ?? []);
    },
    [programCarousels],
  );

  const onSubmit = useSupabaseCallback(
    async ({ supabase }, { programs }: { programs: (string | undefined)[] }) => {
      const service = new SupabaseManageDiscoverService(supabase);

      const { data, error } = await service.update(42, {
        program_carousel_order: filterUndef(programs),
      });

      if (!data || error) {
        toast.error("Could not save carousel order");
      }

      toast.success("Carousel order saved");
      setProgramCarousels(filterUndef(programs));
    },
    [],
  );

  const availablePrograms = useMemo(() => {
    return library?.programs?.map((program) => program.name) ?? [];
  }, [library]);

  const loading = programCarousels === "loading";

  return (
    <Page title="Administration | ChangeEngine">
      <PageHeader display="flex">
        <PageHeading heading={<CeAdminPageHeader />} subheading={"Manage Discover Page"} />
      </PageHeader>
      <PageContent>
        {loading && <LinearProgress />}
        {!loading && availablePrograms && (
          <ManageDiscoverForm
            programCarousels={programCarousels}
            availablePrograms={availablePrograms}
            onSubmit={onSubmit}
          />
        )}
        {!loading && !availablePrograms && <div>{t("No Programs")}</div>}
      </PageContent>
    </Page>
  );
};

const ManageDiscoverForm: FC<{
  programCarousels: string[];
  availablePrograms: string[];
  onSubmit: (data: { programs: (string | undefined)[] }) => Promise<void>;
}> = ({ programCarousels, availablePrograms, onSubmit }) => {
  const { handleSubmit, control } = useForm({
    defaultValues: { programs: programCarousels },
    resolver: yupResolver(
      yup.object({
        programs: yup.array().of(yup.string().oneOf(programCarousels)).required(),
      }),
    ),
  });

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <Controller
          name="programs"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="program-carousels">Program Carousels</InputLabel>
              <Select
                labelId="program-carousels"
                multiple
                value={field.value}
                onChange={field.onChange}
                input={<OutlinedInput />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {availablePrograms.sort().map((program) => (
                  <MenuItem key={program} value={program}>
                    {program}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Stack direction="row-reverse">
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ManageDiscoverPage;
