import { Button, TextField } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { OmitUnion } from "../../utils/omit-union";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { libraryTemplateAtom } from "../discover/library/store";
import { brandToneAtom, queryAtom } from "../generate/store";
import { RefineHistory } from "../generic/atoms/types/refine";
import { historyAtomFamily, refineAtomFamily } from "./store";

export const AiRefineInput: FC<{
  tone: ContentTone | "custom";
  label?: string;
  config: OmitUnion<RefineHistory, "prompt">;
}> = ({ tone, label, config }) => {
  const { t } = useTranslation();
  const [prompt, setPrompt] = useState("");

  const query = useAtomValue(queryAtom);
  const template = useAtomValue(libraryTemplateAtom);
  const customTone = useAtomValue(brandToneAtom);
  const key = {
    contentKey: tone,
    ...(tone === "custom" && customTone && { customTone }),
    ...(config.store === "generate" ? { query } : { template }),
  };
  const [history, setHistory] = useAtom(historyAtomFamily(key));
  const chat = useAtomValue(refineAtomFamily(history.slice(-1)?.[0]));

  const handleRefine = useCallback(
    () => {
      if (history.length === 0) setHistory([{ ...config, prompt }]);
      else {
        if (chat.state !== "hasData" || !chat.data) return;

        const newItem =
          history[0].store === "discover"
            ? {
                store: "discover" as const,
                type: "generated" as const,
                prompt,
                extra: { chatId: chat.data.chatId },
              }
            : {
                store: "generate" as const,
                type: "generated" as const,
                prompt,
                extra: { chatId: chat.data.chatId },
              };

        setHistory([...history, newItem]);
      }
      setPrompt("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prompt, history, setHistory, chat.state, config],
  );

  return (
    <TextField
      fullWidth
      multiline
      maxRows={3}
      value={prompt}
      onChange={(e) => setPrompt(e.target.value)}
      placeholder={label ?? t("E.g. Add some emojis")}
      label={label ?? t("Send a request")}
      InputProps={{
        endAdornment: (
          <Button
            onClick={handleRefine}
            variant="outlined"
            size="small"
            disabled={chat.state !== "hasData"}
          >
            {t("Refine")}
          </Button>
        ),
      }}
    />
  );
};
