import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { Control, Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { notEmpty } from "../../../../utils/not-empty";
import { UUID } from "../../../../utils/uuid";
import { Program } from "../../../programs/server/supabase-program-service";
import {
  Permissions,
  PersonTextDataJson,
  SupabasePermissionsService,
} from "../../server/supabase-person-service";
import { PermissionsFormType, PermissionsLimits, permissionsSchema } from "../../types";

type ValidPerson = {
  account_id: UUID;
  person_id: UUID;
  person_text_data: { key: string; value: string }[];
};

export const PermissionsForm: FC<{
  onComplete: (perms: Permissions[]) => void;
  open: boolean;
  close: () => void;
  employees: PersonTextDataJson[];
  programs: Program[];
  capacity: PermissionsLimits;
  existing?: Permissions;
}> = ({ onComplete, existing, open, employees, programs, capacity, close }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  const methods = useForm({
    defaultValues: existing?.id
      ? {
          person_ids: [existing.id],
          type: existing.super_admin ? "super_admin" : "contributor",
          ...(existing.super_admin
            ? {}
            : {
                read_only_programs: existing.read_only_program?.filter(notEmpty),
                editable_programs: existing.editable_program?.filter(notEmpty),
              }),
        }
      : permissionsSchema.cast({}),
    resolver: yupResolver(permissionsSchema),
  });

  const { reset } = methods;

  useWatch({
    control: methods.control,
    name: ["type", "read_only_programs", "editable_programs"],
  });
  const [selectedType, readOnlyPrograms, editablePrograms] = methods.getValues([
    "type",
    "read_only_programs",
    "editable_programs",
  ]);

  const onSubmit = useSupabaseCallback(
    async ({ supabase }, values: PermissionsFormType): Promise<void> => {
      setSaving(true);

      const service = new SupabasePermissionsService(supabase);

      const { data, error } = await (values.type === "super_admin"
        ? service.setSuperAdmins(values.person_ids)
        : service.setPermissions(
            values.person_ids,
            values.editable_programs,
            values.read_only_programs,
          ));

      setSaving(false);

      if (error || !data) {
        toast.error(t(existing ? "Failed to update permissions" : "Failed to create permissions"));
        return;
      }

      toast.success(t(existing ? "Permissions Updated" : "Permissions created"));
      close();
      reset();
      onComplete(data);
    },
    [close, reset, onComplete, existing, t],
  );

  const validEmployees = getValidEmployees(employees);

  const atCapacity = reachedCapacity(capacity, selectedType, existing);

  return (
    <Dialog open={open} onClose={() => !saving && close()}>
      <DialogTitle>{t(existing ? "Edit User Permissions" : "Add User Permissions")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            Set users permissions below. Depending on your subscription plan, you can assign
            employees as Super Admins or Contributors.
          </Trans>
        </DialogContentText>
        <FormProvider {...methods}>
          <form
            id="permissions-create-form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <EmployeeSelect
              control={methods.control}
              employees={validEmployees}
              existing={existing}
            />
            <PermissionTypeSelect control={methods.control} existing={existing} />
            {!atCapacity && selectedType === "contributor" && (
              <>
                <ProgramSelect
                  control={methods.control}
                  type={"read_only"}
                  programs={programs.filter((x) => !editablePrograms?.includes(x.id))}
                />
                <ProgramSelect
                  control={methods.control}
                  type={"editable"}
                  programs={programs.filter((x) => !readOnlyPrograms?.includes(x.id))}
                />
              </>
            )}
          </form>
        </FormProvider>
        {atCapacity && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Trans>
              Your quota for this permission type has been reached. Contact your Customer Success
              representative for more information.
            </Trans>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={atCapacity || saving} type="submit" form="permissions-create-form">
          {saving ? <CircularProgress size={20} /> : t(existing ? "Update" : "Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EmployeeSelect: FC<{
  control: Control<PermissionsFormType>;
  employees: ValidPerson[];
  existing?: Permissions;
}> = ({ control, employees, existing }) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="person_ids"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={true}
          disabled={!!existing}
          id="employee-select"
          value={employees.filter((s) => field.value?.includes(s.person_id))}
          options={employees}
          renderOption={(renderProps, option) => (
            <Box component="li" {...renderProps} key={option.person_id}>
              {makeLabel(option)}
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.person_id} label={makeLabel(option)} />
            ))
          }
          renderInput={(params) => (
            <TextField
              label={t("Select Employee(s)")}
              variant="standard"
              {...params}
              helperText={error?.message}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                "data-testid": "employee-select",
              }}
            />
          )}
          getOptionLabel={(option) => makeLabel(option)}
          onChange={(_, v) => {
            field.onChange(v.map((x) => x.person_id));
          }}
        />
      )}
    />
  );
};

const PermissionTypeSelect: FC<{
  control: Control<PermissionsFormType>;
  existing?: Permissions;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="type"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={t("Permission Type")}
          select
          fullWidth
          sx={{ mt: 2 }}
          helperText={error?.message}
          error={!!error}
          {...field}
        >
          <MenuItem value="super_admin">{t("Super Admin")}</MenuItem>
          <MenuItem value="contributor">{t("Contributor")}</MenuItem>
        </TextField>
      )}
    />
  );
};

const ProgramSelect: FC<{
  control: Control<PermissionsFormType>;
  type: "read_only" | "editable";
  programs: Program[];
  existing?: Permissions;
}> = ({ control, type, programs }) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={`${type}_programs`}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={true}
          id={`${type}-select`}
          value={programs.filter((s) => field.value?.includes(s.id))}
          options={[{ title: t("Select All"), id: "All" }].concat(programs)}
          renderOption={(renderProps, option) => (
            <Box component="li" {...renderProps} key={option.id}>
              {option.title}
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.id} label={option.title} />
            ))
          }
          renderInput={(params) => (
            <TextField
              label={t(
                `Select Programs that are ${type === "editable" ? "Editable" : "Read Only"}`,
              )}
              variant="standard"
              {...params}
              sx={{ mt: 2 }}
              helperText={error?.message}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                "data-testid": `${type}-select`,
              }}
            />
          )}
          getOptionLabel={(option) => option.title}
          onChange={(_, v) => {
            const value = v.map((s) => s.id);
            field.onChange(value.includes("All") ? programs.map((p) => p.id) : value);
          }}
        />
      )}
    />
  );
};

export const makeLabel = (person: ValidPerson): string => {
  const first_name = person.person_text_data.find((d) => d.key === "first_name");
  const last_name = person.person_text_data.find((d) => d.key === "last_name");
  const work_email = person.person_text_data.find((d) => d.key === "work_email");

  return `${first_name?.value ?? ""} ${last_name?.value ?? ""} (${work_email?.value ?? ""})`;
};

const getValidEmployees = (employees: PersonTextDataJson[]): ValidPerson[] => {
  const isValid = (person: PersonTextDataJson): person is ValidPerson => {
    return person.person_id !== null;
  };

  return employees.filter(isValid);
};

const reachedCapacity = (
  capacity: PermissionsLimits,
  type: PermissionsFormType["type"],
  existing?: Permissions,
): boolean => {
  const isContributorLimit = type === "contributor" && capacity.contributors === 0;
  const isSuperAdminLimit = type === "super_admin" && capacity.super_admins === 0;

  if (!existing) return isContributorLimit || isSuperAdminLimit;

  const existingType = existing.super_admin ? "super_admin" : "contributor";

  return type === existingType ? false : isContributorLimit || isSuperAdminLimit;
};
