import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  StackProps,
  TextField,
  Typography,
} from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { libraryTemplateAtom } from "../discover/library/store";
import { discoverContentFamily } from "../discover/store";
import { brandToneAtom, queryAtom, templateContentFamily } from "../generate/store";
import { RefineHistory } from "../generic/atoms/types/refine";
import { TemplateContent } from "../self-driving/types";
import { historyAtomFamily, refineAtomFamily } from "./store";

export const RefineContentHistory: FC<
  {
    tone: ContentTone | "custom";
    onChange: () => void;
    store: "discover" | "generate";
    onUseSuggestion?: () => void;
  } & StackProps
> = ({ onChange, onUseSuggestion, tone, store, ...props }) => {
  return store === "discover" ? (
    <DiscoverRefineContentHistory
      tone={tone}
      onChange={onChange}
      onUseSuggestion={onUseSuggestion}
      {...props}
    />
  ) : (
    <GenerateRefineContentHistory
      tone={tone}
      onChange={onChange}
      onUseSuggestion={onUseSuggestion}
      {...props}
    />
  );
};

const DiscoverRefineContentHistory: FC<
  {
    tone: ContentTone | "custom";
    onChange: () => void;
    onUseSuggestion?: () => void;
  } & StackProps
> = ({ onChange, onUseSuggestion, tone, ...props }) => {
  const template = useAtomValue(libraryTemplateAtom);
  const customTone = useAtomValue(brandToneAtom);
  const key = useMemo(() => {
    return {
      contentKey: tone,
      template,
      ...(tone === "custom" && customTone ? { customTone } : {}),
    };
  }, [tone, template, customTone]);
  const [history] = useAtom(historyAtomFamily(key));
  const setContent = useSetAtom(discoverContentFamily(key));

  const handleUseSuggestion = useCallback(
    (suggestion: TemplateContent) => {
      setContent(Promise.resolve({ type: "generated" as const, data: suggestion }));
      onUseSuggestion?.();
    },
    [setContent, onUseSuggestion],
  );

  if (!template) return null;

  return (
    <RefineStack
      history={history}
      handleUseSuggestion={handleUseSuggestion}
      onChange={onChange}
      {...props}
    />
  );
};

const GenerateRefineContentHistory: FC<
  {
    tone: ContentTone | "custom";
    onChange: () => void;
    onUseSuggestion?: () => void;
  } & StackProps
> = ({ onChange, onUseSuggestion, tone, ...props }) => {
  const query = useAtomValue(queryAtom);
  const customTone = useAtomValue(brandToneAtom);

  const key = useMemo(() => {
    return { contentKey: tone, query, ...(tone === "custom" && customTone ? { customTone } : {}) };
  }, [tone, query, customTone]);
  const [history] = useAtom(historyAtomFamily(key));

  const setContent = useSetAtom(templateContentFamily(key));

  const handleUseSuggestion = useCallback(
    (suggestion: TemplateContent) => {
      setContent(Promise.resolve({ type: "generated" as const, data: suggestion }));
      onUseSuggestion?.();
    },
    [setContent, onUseSuggestion],
  );

  return (
    <RefineStack
      history={history}
      handleUseSuggestion={handleUseSuggestion}
      onChange={onChange}
      {...props}
    />
  );
};

const RefineStack: FC<
  {
    history: RefineHistory[];
    handleUseSuggestion: (suggestion: TemplateContent) => void;
    onChange: () => void;
  } & StackProps
> = ({ history, handleUseSuggestion, onChange, ...props }) => {
  return (
    <Stack direction="column" spacing={3} divider={<Divider />} {...props}>
      {history.map((h, i) => (
        <RefineContentHistoryItem
          key={i}
          request={h}
          onUseSuggestion={handleUseSuggestion}
          showPrompts={i === history.length - 1}
          onChange={onChange}
        />
      ))}
    </Stack>
  );
};

const RefineContentHistoryItem: FC<{
  request: RefineHistory;
  onUseSuggestion: (suggestion: TemplateContent) => void;
  showPrompts: boolean;
  onChange: () => void;
}> = ({ onUseSuggestion, onChange, request }) => {
  const { t } = useTranslation();
  const contentLoader = useAtomValue(refineAtomFamily(request));

  const handleUseSuggestion = useCallback(() => {
    contentLoader.state === "hasData" && contentLoader.data && onUseSuggestion(contentLoader.data);
  }, [contentLoader, onUseSuggestion]);

  useEffect(() => {
    if (["loading", "hasData"].includes(contentLoader.state)) onChange();
  }, [contentLoader, onChange]);

  return (
    <>
      <Typography variant="body2" color="text.discreet" fontStyle="italic">
        {request.prompt}
      </Typography>
      <Box position={"relative"}>
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={15}
          value={contentLoader.state === "hasData" ? contentLoader.data?.response.content : ""}
          contentEditable={false}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)",
              borderWidth: "1px !important",
            },
            transition: "ease-in-out 0.2s",
            mb: 2,
          }}
          {...(contentLoader.state === "loading" && {
            InputProps: {
              startAdornment: (
                <Box display="flex" gap="2">
                  <CircularProgress size={20} sx={{ mr: 2 }} />{" "}
                  <Box whiteSpace="nowrap">{t("Generating suggestion...")}</Box>
                </Box>
              ),
            },
          })}
        />
        {contentLoader.state === "hasError" && (
          <Alert severity="error">
            {t(
              "It appears Co-Pilot isn't working right now. Try again later, or contact your customer services representative.",
            )}
          </Alert>
        )}
        {contentLoader.state === "hasData" && (
          <Button
            variant="outlined"
            onClick={handleUseSuggestion}
            sx={{
              position: "absolute",
              bottom: "0",
              right: 15,
              backgroundColor: "#ffffff",
              ":hover": { bgcolor: "#f3f3f3" },
            }}
          >
            {t("Use suggestion")}
          </Button>
        )}
      </Box>
    </>
  );
};
