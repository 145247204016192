import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import { useAtom } from "jotai";
import { FC, MouseEventHandler, useCallback } from "react";
import { sequenceTemplateSelectedFamily } from "./store";

export const SequenceCardToggle: FC<{ slug: string } & IconButtonProps> = ({ slug, ...props }) => {
  const [toggle, setToggle] = useAtom(sequenceTemplateSelectedFamily(slug));

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setToggle((checked) => !checked);
    },
    [setToggle],
  );

  return (
    <IconButton onClick={handleClick} {...props}>
      {toggle ? <RemoveCircleOutline /> : <AddCircleOutline />}
    </IconButton>
  );
};
