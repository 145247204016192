import { Box, Tooltip } from "@mui/material";
import { take } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../utils/hooks/use-dialog";
import { BrandKitLogoChoiceRow } from "../../brand-kit/components/logo-choice";
import { BrandKitLogo } from "../../brand-kit/server/brand-kit-service";
import { BrandInfoSelected, BrandKitManager } from "../../brand-kit/types";
import { More } from "./branding-bar";

export const BrandLogoSelect: FC<{
  logos: BrandKitLogo[];
  manager: BrandKitManager;
  selected?: BrandKitLogo;
  defaultBranding?: BrandInfoSelected;
}> = ({ logos, selected, manager, defaultBranding }) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  if (logos.length === 0) return null;

  const sortedLogos = [...logos].sort((a, b) => {
    return a.id === defaultBranding?.logo?.id
      ? -1
      : b.id === defaultBranding?.logo?.id
        ? 1
        : a.src.localeCompare(b.src);
  });

  // if selected color is not in the first 3, put it in last position so it's visible
  const selectedIndex = sortedLogos.findIndex((logo) => logo.id === selected?.id);
  const firstThreeLogos =
    selectedIndex > 2
      ? [...take(sortedLogos, 2), sortedLogos[selectedIndex]]
      : take(sortedLogos, 3);

  return (
    <>
      {firstThreeLogos.map((logo) => {
        const isSelected = selected?.id === logo.id;

        return (
          <Tooltip title={t(`${logo.is_default_logo ? "Default" : ""} Logo`)} key={logo.id}>
            <Box
              key={logo.id}
              onClick={() => manager.logo.setSelected(logo.id)}
              sx={(theme) => ({
                width: "60px",
                height: "60px",
                borderStyle: "solid",
                borderRadius: `${theme.shape.borderRadius}px`,
                ...(isSelected
                  ? {
                      cursor: "default",
                      borderWidth: "3px",
                      borderColor: theme.palette.primary.main,
                    }
                  : {
                      cursor: "pointer",
                      borderWidth: "1px",
                      borderColor: "sidebar.divider",
                      "&:hover": {
                        borderColor: theme.palette.text.secondary,
                      },
                    }),
                bgcolor: "#efefef",
                verticalAlign: "middle",
                display: "flex",
                flexDirection: "row",
                padding: "3px",
              })}
              overflow={"hidden"}
              role="button"
              data-testid={`logo-${logo.id}${isSelected ? "-selected" : ""}`}
            >
              <Box component="img" src={logo.src} sx={{ objectFit: "contain", width: "100%" }} />
            </Box>
          </Tooltip>
        );
      })}
      {logos.length > 3 && (
        <More dialog={dialog} title={t("Apply Logo")} tooltip={t("More Logos")}>
          <BrandKitLogoChoiceRow
            logos={sortedLogos}
            selected={selected}
            logoManager={{
              ...manager.logo,
              setSelected: (id) => {
                manager.logo.setSelected(id);
                dialog.handleClose();
              },
            }}
            showTitle={false}
            showDefaultIndicator={false}
          />
        </More>
      )}
    </>
  );
};
