import { Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ContentTone } from "../../../modules/brand-kit/server/brand-kit-service";
import { BrandingBar } from "../../../modules/discover/branding-bar/branding-bar";
import { GenerateMomentButton } from "../../../modules/generate/generate-moment-button";
import { useLoadGenerateMomentPage } from "../../../modules/generate/hooks/use-load-generate-moment-page";
import {
  generatedTemplateAtom,
  generatePageHeadingAtom,
  imageAtom,
} from "../../../modules/generate/store";
import { BetaBadge } from "../../../modules/generic/components/beta/beta-badge";
import { FixedFooter } from "../../../modules/generic/fixed-footer";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { PageHeader } from "../../../modules/layout/components/page-header";
import { PageHeading } from "../../../modules/layout/components/page-heading";
import { TemplatePreview } from "../../../modules/template-preview/template-preview";
import { UUID } from "../../../utils/uuid";

const GenerateMomentPageV1: FC<{
  recipeId?: string;
}> = ({ recipeId }) => {
  useLoadGenerateMomentPage(recipeId);

  const { t } = useTranslation();

  const onImageSelect = useSetAtom(imageAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [contentTone, setContentTone] = useState<ContentTone | "custom">("professional");

  const headingInfo = useAtomValue(generatePageHeadingAtom);

  const brandToneId = searchParams.get("brand_tone");

  const handleBrandToneChange = useCallback(
    (tone: UUID) => setSearchParams((prev) => ({ ...prev, brand_tone: tone })),
    [setSearchParams],
  );

  return (
    <Page title={t("Create Moment | ChangeEngine")}>
      <PageHeader>
        <BetaBadge sx={{ width: "100%" }}>
          <PageHeading {...headingInfo} />
        </BetaBadge>
      </PageHeader>
      <BrandingBar visualStyleSelector />
      <PageContent mb={8} pt={0}>
        <TemplatePreview
          loadableTemplateAtom={generatedTemplateAtom}
          store="generate"
          contentTone={contentTone}
          brandTone={brandToneId as UUID}
          onBrandToneChange={handleBrandToneChange}
          onContentToneChange={setContentTone}
          onImageSelect={onImageSelect}
        />
        <FixedFooter>
          <Stack direction="row" spacing={2} justifyContent="end" my={2}>
            <GenerateMomentButton contentTone={contentTone} />
          </Stack>
        </FixedFooter>
      </PageContent>
    </Page>
  );
};

export default GenerateMomentPageV1;
