import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useToken } from "../../../server/auth/use-token";
import { R2Service } from "../../../server/cloudflare/r2-service";
import { uuidv4 } from "../../../utils/uuid";
import { FilePicker } from "../../generic/components/file-picker/file-picker";
import { BrandKitLogo } from "../server/brand-kit-service";

export const BrandKitFilePicker: FC<{
  open: boolean;
  handleClose: () => void;
  onChange: (logo: Pick<BrandKitLogo, "src" | "format">) => void;
}> = ({ open, handleClose, onChange }) => {
  const [currentValue, setCurrentValue] = useState<Pick<BrandKitLogo, "src" | "format">>();
  const { t } = useTranslation();
  const getToken = useToken();

  const handleUpload = useCallback(
    async (file: File) => {
      const name = uuidv4();

      const r2Service = new R2Service();
      const result = await r2Service.upload(file, name, await getToken());

      if (!result.success || !result.url) {
        toast.error(t("Something went wrong, please try again"));
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, format] = file.type.split("/");

      setCurrentValue({ src: result.url, format });
      return file;
    },
    [getToken, t, setCurrentValue],
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{t("Pick Logo")}</DialogTitle>
        <DialogContent>
          <FilePicker
            value={currentValue?.src ?? ""}
            handleUpload={handleUpload}
            accept={{ "image/*": [".png", ".jpeg", ".gif", ".jpg", ".svg"] }}
            text={{
              add: t("Add Image"),
              remove: t("Remove Image"),
              uploadError: t("Only one image can be uploaded at a time"),
            }}
            handleRemove={useCallback(() => setCurrentValue(undefined), [])}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                currentValue && onChange(currentValue);
                handleClose();
                setCurrentValue(undefined);
              }}
            >
              {t("OK")}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
