import { Box, IconButton } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useAtomValue, useSetAtom } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "../../../styles/icons/menu";
import { SettingsMenu } from "./sidebar/settings-menu";
import { sidebarOpenAtom, toggleSidebarAtom } from "./sidebar/store";
import { layoutAtom } from "./store";

export const ApplicationHeader: FC<BoxProps> = ({ sx = [] }) => {
  const toggleSidebar = useSetAtom(toggleSidebarAtom);
  const layout = useAtomValue(layoutAtom);

  const { t } = useTranslation();

  const sidebarOpen = useAtomValue(sidebarOpenAtom);

  return (
    <Box
      sx={[
        {
          display: "flex",
          width: "100%",
          alignItems: "center",
        },
        (theme) => ({
          "--ah-text-color": theme.palette.text.secondary,
          "--ah-bg-color": theme.palette.background.default,
          "--ah-button-text-color": theme.palette.text.secondary,
          "--ah-button-bg-color": theme.palette.background.default,
          "--ah-button-hover-text-color": theme.palette.text.primary,
          "--ah-button-hover-bg-color": theme.palette.background.selected,
          px: theme.spacing(3),
          py: theme.spacing(1.5),
          color: "var(--ah-text-color)",
          backgroundColor: "var(--ah-bg-color)",
          ".MuiIconButton-root": {
            color: "var(--ah-button-text-color)",
            "&:hover": {
              color: "var(--ah-button-hover-text-color)",
              backgroundColor: "var(--ah-button-hover-bg-color)",
            },
          },
        }),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
    >
      <Box flexGrow={1}>
        <IconButton
          sx={(theme) => ({
            color: layout === "default" ? theme.palette.text.primary : "var(--ah-text-color)",
            boxShadow: sidebarOpen
              ? `0 0 5px 5px ${theme.palette.text.primary}aa`
              : `0 0 0 0  ${theme.palette.text.primary}00`,
            transition: "box-shadow 2s ease-out",
          })}
          onClick={toggleSidebar}
          title={t("Open main nav")}
        >
          <Menu />
        </IconButton>
      </Box>
      <Box>
        <SettingsMenu />
      </Box>
    </Box>
  );
};
