import { atom } from "jotai";
import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { isEqual } from "lodash-es";
import { getTokenAtom } from "../../server/auth/store";
import { authHeader } from "../../utils/auth-header";
import { OmitUnion } from "../../utils/omit-union";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { BrandTone } from "../brand-kit/server/brand-tone-service";
import { brandingAtom } from "../brand-kit/store";
import { getPageAspect } from "../design-huddle/utils";
import { LibraryTemplate } from "../discover/library/types";
import {
  debugLabel,
  gptModelAtom,
  imagePreviewDirectFamily,
  queryAtom,
  templateImageFamily,
  unsplashSearchFamily,
} from "../generate/store";
import { GenerateQuery } from "../generate/types";
import { ContentRefineAtoms, ImageRefineAtoms, RefineHistory } from "../generic/atoms/types/refine";
import {
  ImagePreviewData,
  ImagePreviewDataFamilyAtom,
  ImagePreviewFamilyAtom,
} from "../generic/atoms/types/template";
import { selfDriving } from "../self-driving/self-driving";
import { ToneType } from "../self-driving/types";

export const historyAtomFamily = atomFamily(
  ({
    contentKey,
    query,
    template,
    customTone,
  }: {
    contentKey: ToneType;
    query?: GenerateQuery | null;
    template?: LibraryTemplate | null;
    customTone?: BrandTone | null;
  }) =>
    debugLabel(
      `historyAtomFamily?contentKey=${contentKey}&slug=${template?.slug}&customTone=${customTone?.id}&query=${JSON.stringify(query)}`,
      atomWithDefault(() => [] as RefineHistory[]),
    ),
  isEqual,
);

export const refineAtomFamily = atomFamily(
  (h?: RefineHistory) =>
    debugLabel(
      `refineAtomFamily(${h?.prompt})`,
      loadable(
        atom(async (get) => {
          if (!h) return null;

          const gptModel = get(gptModelAtom);
          const token = await get(getTokenAtom).getToken();

          if (!token || !gptModel) return null;

          let respPromise = null;
          if (h.store === "discover" && h.type === "static") {
            respPromise = selfDriving.POST("/discover/refine/text/initial", {
              body: { ...h.extra, prompt: h.prompt },
              params: { query: { model: gptModel } },
              ...authHeader(token),
            });
          } else if (h.store === "discover" && h.type === "generated") {
            respPromise = selfDriving.POST("/discover/refine/text", {
              body: { ...h.extra, prompt: h.prompt },
              params: { query: { model: gptModel } },
              ...authHeader(token),
            });
          } else if (h.store === "generate" && h.type === "generated") {
            const q = get(queryAtom);

            if (q)
              respPromise = selfDriving.POST("/recipes/{recipeId}/follow_up/text/refine", {
                body: { ...h.extra, prompt: h.prompt },
                params: { path: { recipeId: q.recipeId }, query: { model: gptModel } },
                ...authHeader(token),
              });
          }

          if (respPromise === null) return null;

          const { data, error } = await respPromise;

          if (error || !data) throw new Error(error.error || "Request failed");

          return data;
        }),
      ),
    ),
  isEqual,
);

export const imagePreviewDataFamily: ImagePreviewDataFamilyAtom = atomFamily((num: number) =>
  atomWithDefault<Promise<ImagePreviewData | null>>(async (get) => {
    const templateImage = await get(templateImageFamily({ num }));

    return templateImage
      ? {
          chatId: templateImage.chatId,
          num,
          header: templateImage.response.header,
          subheader: templateImage.response.subheader,
          search: templateImage.response.search || templateImage.response.header,
          Photo: undefined,
        }
      : null;
  }),
);

export const imagePreviewFamily: ImagePreviewFamilyAtom = atomFamily((num: number) =>
  loadable(
    atom(async (get) => {
      const data = await get(imagePreviewDataFamily(num));
      if (!data) return null;
      return get(imagePreviewDirectFamily({ ...data, chatId: data.chatId }));
    }),
  ),
);
export const imagePhotosFamily = atomFamily((num: number) =>
  loadable(
    atom(async (get) => {
      const imagePreviewData = await get(imagePreviewDataFamily(num));
      const query = imagePreviewData?.search || imagePreviewData?.header;
      const style = get(brandingAtom);
      const orientation = getPageAspect(style);
      if (!query) return null;
      return get(unsplashSearchFamily({ query, orientation }));
    }),
  ),
);

export const imageRefineAtomsAtom = atom<ImageRefineAtoms | null>(null);

export const contentRefineAtoms = atom<ContentRefineAtoms | null>(null);

export const contentRefineConfigFamily = atomFamily((tone: ContentTone | "custom") =>
  atom<OmitUnion<RefineHistory, "prompt"> | null>((get) => {
    const refineAtoms = get(contentRefineAtoms);

    if (!refineAtoms) return null;

    const content = refineAtoms.content[tone];

    if (content?.state !== "hasData" || !content.data) return null;

    return content.data.type === "static"
      ? {
          store: "discover",
          type: content.data.type,
          extra: { text: content.data.data.response.content },
        }
      : {
          store: refineAtoms.store,
          type: content.data.type,
          extra: { chatId: content.data.data.chatId },
        };
  }),
);
