import { Grid, Link } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageCard } from "../../generic/components/page-card";
import { CsvUploadCard } from "./csv-upload-card";

export const CsvUpload: FC<{ onUpload: () => Promise<void>; onComplete: () => void }> = ({
  onUpload,
  onComplete,
}) => {
  const { t } = useTranslation();

  return (
    <PageCard
      title={t("Upload CSV")}
      subTitle={
        <div>
          <Link
            href="https://help.changeengine.com/Add-employee-data-via-CSV-5099b764706c4596b8d70114f8efee8b"
            target="_blank"
            rel="noreferrer"
            underline="hover"
          >
            Learn more
          </Link>
          &nbsp;about using our upload function
        </div>
      }
      withMargin={false}
    >
      <Grid container>
        <Grid item xs={4}>
          <CsvUploadCard
            processingState="not started"
            onUpload={onUpload}
            onComplete={onComplete}
          />
        </Grid>
      </Grid>
    </PageCard>
  );
};
