import { useFormContext, useWatch } from "react-hook-form";
import { UseMomentProperties } from "../moment-card/types";
import { MomentFormMoment } from "../moment/types/moment-form-moment";

export type ToTarget = "channel" | "notify" | "manager" | "employee";

export function useToTarget(): ToTarget {
  const { control, getValues } = useFormContext<MomentFormMoment>();
  useWatch({
    control,
    name: ["only_additional_recipients", "target_manager", "target_channel", "notify_channel"],
  });
  const [only_additional_recipients, target_manager, target_channel, notify_channel] = getValues([
    "only_additional_recipients",
    "target_manager",
    "target_channel",
    "notify_channel",
  ]);

  return getToTarget({
    only_additional_recipients,
    target_manager,
    target_channel,
    notify_channel,
  });
}

export function getToTarget({
  only_additional_recipients,
  target_manager,
  target_channel,
  notify_channel,
}: Pick<
  UseMomentProperties,
  "only_additional_recipients" | "target_manager" | "target_channel" | "notify_channel"
>): ToTarget {
  if (target_channel || notify_channel) return "channel";

  if (only_additional_recipients) return "notify";

  if (target_manager) return "manager";

  return "employee";
}
