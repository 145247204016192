import { UseApiLoaderResponse, useApiLoader } from "../../../server/api/hooks/use-api-loader";
import { useToken } from "../../../server/auth/use-token";
import { authHeader } from "../../../utils/auth-header";
import { selfDriving } from "../../self-driving/self-driving";
import { Multiformat } from "../types";

export const useMultiFormat = (): UseApiLoaderResponse<Multiformat> => {
  const getToken = useToken();
  return useApiLoader(async () => {
    const { error, data } = await selfDriving.GET("/multiformat", {
      ...authHeader(await getToken()),
    });

    if (error) {
      console.error(error);
      throw new Error("Cannot not get /multiformat");
    }

    return data;
  }, [getToken]);
};
