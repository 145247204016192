import { LoadingButton } from "@mui/lab";
import { Box, BoxProps, Button, Grow, Popper, Slide, Stack } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { useSetAtom } from "jotai";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFlags } from "../../server/optimizely";
import { usePopover } from "../../utils/hooks/use-popover";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { useAccountChannels } from "./hooks/use-account-channels";
import { useLaunchTemplate } from "./hooks/use-launch-template";
import { gptModelAtom } from "./store";

interface GenerateMomentButtonProps extends BoxProps {
  contentTone: ContentTone | "custom";
  customToneName?: string;
}

export const GenerateMomentButton: FC<GenerateMomentButtonProps> = ({ contentTone }) => {
  const { t } = useTranslation();
  const createButtonPopover = usePopover<HTMLButtonElement>();
  const setgptModelAtom = useSetAtom(gptModelAtom);

  const { flags, flagsLoaded } = useFlags("new_gpt_model");
  const { channels, loading: channelsLoading } = useAccountChannels();
  const { launch, launching, savable } = useLaunchTemplate(contentTone);

  useEffect(() => {
    if (!flagsLoaded) return;

    setgptModelAtom(flags.new_gpt_model ? "alpha" : "ga");
  }, [flagsLoaded, flags.new_gpt_model, setgptModelAtom]);

  if (channelsLoading || !flagsLoaded) return null;

  return (
    <Box position="relative" onMouseOut={createButtonPopover.handleClose}>
      <Popper
        onMouseOut={createButtonPopover.handleClose}
        onMouseOver={createButtonPopover.handleOpen}
        open={!launching && createButtonPopover.open}
        anchorEl={createButtonPopover.anchorRef.current}
        placement="top-start"
        sx={{ zIndex: 2000 }}
        transition
      >
        {({ TransitionProps }) => (
          <Box
            width={createButtonPopover.anchorRef.current?.offsetWidth}
            display="flex"
            flexDirection="row-reverse"
            borderRadius="10px 10px 0 0"
            bgcolor="primary.light"
            p={2}
            overflow="hidden"
            zIndex={3000}
          >
            <Slide direction="up" in={createButtonPopover.open} mountOnEnter unmountOnExit>
              <Stack direction="column" spacing={1} width="100%">
                {channels.map(({ name, channel_name, icon }, i) => (
                  <Grow {...TransitionProps} timeout={100 * i} key={channel_name}>
                    <Button
                      startIcon={icon}
                      variant="outlined"
                      fullWidth
                      disabled={!savable}
                      sx={{
                        backgroundColor: "#fff",
                        "&:hover": {
                          backgroundColor: "rgba(255,255,255,0.8)",
                        },
                      }}
                      onClick={() => savable && void launch(channel_name).catch(console.error)}
                    >
                      {name}
                    </Button>
                  </Grow>
                ))}
              </Stack>
            </Slide>
          </Box>
        )}
      </Popper>
      <LoadingButton
        loading={launching || !savable}
        endIcon={
          <ArrowRightIcon
            sx={{
              transition: "transform 0.2s ease-in-out",
              ...(createButtonPopover.open && {
                transform: "rotate(-90deg)",
              }),
            }}
          />
        }
        variant="contained"
        sx={(theme) => ({
          position: "relative",
          zIndex: 2,
          cursor: "default",
          ...(createButtonPopover.open && {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            bgcolor: `${theme.palette.primary.light} !important`,
          }),
          transition: "transform 0.2s ease-in-out",
        })}
        onMouseOver={createButtonPopover.handleOpen}
        ref={createButtonPopover.anchorRef}
      >
        {t("Create your Moment")}
      </LoadingButton>
    </Box>
  );
};
