import { Box, BoxProps, Skeleton } from "@mui/material";
import { FC } from "react";
import { LoadableTemplateImage } from "../generic/atoms/types/template";

export const TemplateCarouselImage: FC<
  BoxProps & { selected?: boolean; image?: LoadableTemplateImage }
> = ({ image, selected = false, sx = [], ...props }) => (
  <Box
    sx={[
      (theme) => ({
        boxSizing: "content-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: selected ? theme.palette.primary.main : "divider",
        cursor: props.onClick ? "pointer" : "default",
        backgroundImage:
          image?.state === "hasData" && image.data?.url.state === "hasData"
            ? `url(${image.data?.url.data})`
            : "none",
        backgroundSize: "cover",
        position: "relative",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
        borderRadius: "8px",
      }),
      ...(sx instanceof Array ? sx : [sx]),
    ]}
    boxShadow={2}
    role="button"
    title={(image?.state === "hasData" && image.data?.style) || ""}
    {...props}
  >
    {(image?.state !== "hasData" || image.data?.url.state !== "hasData") && (
      <Skeleton sx={{ width: "100%", height: "100%" }} variant="rectangular" />
    )}
  </Box>
);
