import {
  Alert,
  Button,
  LinearProgress,
  Modal,
  Paper,
  PopoverProps,
  Stack,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSupabaseCallback } from "../../server/supabase/hooks";
import { Clock } from "../../styles/icons/clock";
import { UUID } from "../../utils/uuid";
import { SupabaseSequenceService } from "./server/supabase-sequence-service";
import { sequenceAtom, sequenceSelectedTemplatesLengthAtom } from "./store";
import { usePrepareTemplatesForImport } from "./use-prepare-templates-for-import";

export const CreateSequenceModal: FC<PopoverProps> = (props) => {
  const { t } = useTranslation();
  const sequence = useAtomValue(sequenceAtom);
  const [preparing, setPreparing] = useState<boolean>(false);
  const [launching, setLaunching] = useState<boolean>(false);
  const [prepareStatus, setPrepareStatus] = useState<{ step: number; total: number }>({
    step: 0,
    total: 0,
  });
  const prepareTemplates = usePrepareTemplatesForImport({ onChange: setPrepareStatus });
  const navigate = useNavigate();

  const handleClose = useCallback(
    (event: React.MouseEvent, reason: "backdropClick" | "escapeKeyDown") => {
      setPreparing(false);
      setLaunching(false);
      setPrepareStatus({ step: 0, total: 0 });
      setError(undefined);
      props.onClose?.(event, reason);
    },
    [props],
  );

  const [error, setError] = useState<string | undefined>();

  const handleLaunch = useSupabaseCallback(
    async ({ supabase, account_id }) => {
      if (!sequence) return;

      setPreparing(true);

      const preparedTemplates = await prepareTemplates();

      setPreparing(false);

      if (!preparedTemplates) return;

      setLaunching(true);

      const { data, error: err } = await new SupabaseSequenceService(supabase).importSequence({
        sequence: { name: sequence.title, thumbnail: sequence.img.thumbnail, account_id },
        moments: preparedTemplates,
      });

      if (err || !data) {
        setLaunching(false);
        setError(err?.message);
        toast.error(t("Error creating sequence"));
        return;
      }

      setLaunching(false);

      navigate(`/moments/${data[0] as UUID}`);
    },
    [sequence, prepareTemplates, navigate, t],
  );

  const numMoments = useAtomValue(sequenceSelectedTemplatesLengthAtom);

  const launchState = useMemo(() => {
    if (launching) return "launching";
    if (preparing) return "preparing";
    if (error) return "error";
    return "initial";
  }, [launching, preparing, error]);

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Paper
        sx={{
          position: "fixed",
          width: "35em",
          left: "50vw",
          top: "30vh",
          transform: "translateX(-50%)",
          p: 4,
        }}
      >
        {launchState === "error" && <Alert severity="error">{error}</Alert>}

        <Stack gap={4}>
          <Typography variant="h6">{t("Create Sequence")}</Typography>

          {launchState === "initial" && (
            <>
              <Typography variant="body1">
                {t(
                  "You are about to create {{numMoments}} Moments which you can edit and publish.",
                  {
                    numMoments,
                  },
                )}
              </Typography>

              <Stack direction="row" justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => handleClose(e, "escapeKeyDown")}
                  disabled={launching}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => void handleLaunch()}
                  disabled={launching}
                >
                  Create Draft Moments
                </Button>
              </Stack>
            </>
          )}

          {launchState === "preparing" && (
            <>
              <Alert variant="outlined" severity="warning" icon={<Clock />}>
                {t("Please keep this tab open while we launch your Moments")}
              </Alert>
              <LinearProgress />
              <Typography variant="body1">
                {t(
                  "Preparing {{prepareStatus.step}} of {{prepareStatus.total}} Moments for launch.",
                  { prepareStatus },
                )}
              </Typography>
            </>
          )}

          {launchState === "launching" && (
            <>
              <Alert variant="outlined" severity="warning" icon={<Clock />}>
                {t("Please keep this tab open while we launch your Moments")}
              </Alert>
              <LinearProgress />

              <Typography variant="body1">
                {t("Launching {{numMoments}} Moments...", { numMoments })}
              </Typography>
            </>
          )}
        </Stack>
      </Paper>
    </Modal>
  );
};
