/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  BaseSupabaseService,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

type BrandKit = Database["public"]["Views"]["brand_info"]["Row"];

export type BrandKitColor = Pick<
  NonNullable<Database["public"]["Views"]["brand_info"]["Row"]["colors"]>[0],
  "type" | "hex" | "is_default_primary_color"
> & { id?: UUID };

export type BrandKitLogo = Pick<
  NonNullable<Database["public"]["Views"]["brand_info"]["Row"]["logos"]>[0],
  "type" | "theme" | "src" | "background" | "format" | "is_default_logo"
> & { id?: UUID };

export type BrandKitStyle = NonNullable<
  Database["public"]["Tables"]["brand_defaults"]["Row"]
>["branding_type"];

export type BrandKitImageType = "square" | "banner";

export type ContentTone = NonNullable<
  Database["public"]["Tables"]["brand_defaults"]["Row"]
>["default_tone"];

export type BrandKitUpsert = Pick<BrandKit, "name" | "domain"> & {
  logos: BrandKitLogo[];
  colors: BrandKitColor[];
};

export class SupabaseSuperAdminBrandKitService extends BaseSupabaseService<"super_admin"> {
  async upsert(data: BrandKitUpsert) {
    return this.log_errors(this.client.rpc("upsert_brand_info", { data }));
  }

  async reset() {
    return this.log_errors(this.client.rpc("reset_brand_info"));
  }
}

export type SetDefaultsData =
  | { column: "logo_id" | "primary_color_id"; value?: UUID }
  | { column: "branding_type"; value?: BrandKitStyle }
  | { column: "default_tone"; value?: ContentTone };

export class SupabaseBrandKitService extends SupabaseServiceView<"brand_info"> {
  table = "brand_info" as const;

  async setDefault(account_id: UUID, data: SetDefaultsData[]) {
    return this.client.from("brand_defaults").upsert({
      id: account_id,
      ...data.reduce(
        (acc, { column, value }) => ({ ...acc, [column]: value ?? null }),
        {} as Record<string, unknown>,
      ),
    });
  }

  async getDefaultLogo(account_id: UUID) {
    return this.client
      .from("brand_defaults")
      .select("brand_logo(src)")
      .eq("id", account_id)
      .maybeSingle();
  }
}
