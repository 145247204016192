import { Avatar, Box, Grid } from "@mui/material";
import { DataGridPro, DataGridProProps, useGridApiRef } from "@mui/x-data-grid-pro";
import { lowerCase, sortBy } from "lodash-es";
import { FC } from "react";
import { ISO8601 } from "../../../utils/iso8601";
import { propertyNotEmpty } from "../../../utils/not-empty";
import { MomentCardMoment, MomentStatus } from "../../moment-card/types";
import { MomentFilter } from "../../moment-filter/components/moment-filter";
import { ExpandAll } from "../../program-list-view/program-list-view";
import { ProgramIcon } from "../../programs/components/program-icon";
import { programsOrder } from "../../programs/server/supabase-program-service";
import { columns, initialState } from "../config";
import { MomentTableProps } from "../types";

export function specific({
  schedule,
  immediate,
  published_at,
}: {
  immediate: boolean | null;
  schedule?: {
    when: string | null;
  } | null;
  published_at: ISO8601 | null;
}): boolean {
  return !!(schedule?.when || (immediate && published_at));
}

interface Props {
  moments: MomentCardMoment[];
}

export const ProgramOrchestratorView: FC<Props> = ({ moments }) => {
  const apiRef = useGridApiRef();

  const getTreeDataPath: DataGridProProps<MomentTableProps>["getTreeDataPath"] = (row) =>
    row.hierarchy;

  const groupingColDef: DataGridProProps<MomentTableProps>["groupingColDef"] = {
    headerName: "Program",
    minWidth: 310,
    hideDescendantCount: true,
    pinnable: true,
    sortable: false,
    valueGetter: (_, row) => {
      if (row.type === "program") {
        const { theme_colour, icon } = row.data;
        const id = lowerCase(row.title).replaceAll(" ", "-");

        return (
          <Grid container spacing={1} id={id}>
            <Grid item>
              <Avatar sx={{ bgcolor: theme_colour, width: "30px", height: "30px" }}>
                <ProgramIcon data={icon.value} fontSize="small" />
              </Avatar>
            </Grid>
            <Grid item sx={{ mt: 0.5 }}>
              {row.title}
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container sx={{ width: 1420 }}>
            <Grid sx={{ my: "auto" }}>{row.title}</Grid>
          </Grid>
        );
      }
    },
  };

  const momentsToRows = (momentList: MomentCardMoment[]): MomentTableProps[] => {
    const nonEmptyMoments = momentList.filter(propertyNotEmpty("program"));

    const momentRows: MomentTableProps[] = nonEmptyMoments.map((moment) => {
      const { title, status, immediate, published_at, schedule } = moment;

      return {
        id: (moment.id ?? "") + "_" + (title ?? ""),
        hierarchy: [moment.program.id, moment.id ?? ""],
        title: title ?? "",
        type: "moment",
        date: immediate ? published_at : schedule?.when,
        scheduleType: specific(moment) ? "specific" : "variable",
        status: status as MomentStatus,
        data: moment,
        theme_colour: moment.program.theme_colour,
      };
    });

    const programs = sortBy(
      Object.values(
        Object.fromEntries(
          nonEmptyMoments.map(({ program }) => [`${program.order}-${program.title}`, program]),
        ),
      ),
      programsOrder.map((p) => p.column),
    );

    programs.forEach((program) => {
      momentRows.push({
        id: `${program.id ?? ""}_${program.order}`,
        hierarchy: [program.id ?? ""],
        title: program.title,
        type: "program",
        theme_colour: program.theme_colour,
        data: program,
      });
    });

    return momentRows;
  };

  return (
    <>
      <Box display="flex" gap={1} flexDirection="row-reverse">
        <ExpandAll apiRef={apiRef} sx={{ display: "block" }} />
        <MomentFilter />
      </Box>

      <Box
        sx={{
          "& .cell": {
            borderRightWidth: "1px !important",
            borderRightStyle: "solid !important",
            borderRightColor: "divider",
            borderBottomWidth: "1px !important",
            borderBottomStyle: "solid !important",
            borderBottomColor: "divider",
          },
          "& .variable": {
            px: "0px !important",
          },
          "& .disabled": {
            backgroundColor: "background.contrast",
          },
        }}
      >
        <DataGridPro
          apiRef={apiRef}
          rows={momentsToRows(moments)}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          initialState={initialState}
          disableColumnReorder={true}
          hideFooterSelectedRowCount
          treeData
          hideFooter
          autoHeight
          disableColumnMenu
        />
      </Box>
    </>
  );
};
