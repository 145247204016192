import { Grid, GridProps, LinearProgress } from "@mui/material";
import { useDebouncedEffect } from "@react-hookz/web";
import { useAtomValue, useSetAtom } from "jotai";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFlag } from "../../server/optimizely";
import { useBrandTones } from "../brand-kit/hooks/use-brand-tones";
import { brandingAtom } from "../brand-kit/store";
import { TemplateType } from "../generic/atoms/types/template";
import { showSequencesAtom } from "../sequence/store";
import { DiscoverCard } from "./carousel/discover-card";
import { programNameGetter } from "./carousel/discover-carousels";
import { discoverSearchQuery, discoverSearchResultsAtom, unstableAtlasSearchAtom } from "./store";
import { DiscoverTemplate } from "./types";

export const DiscoverSearchResults: FC<GridProps> = ({ ...props }) => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const unstable = searchParams.get("unstable") === "1";
  const navigate = useNavigate();
  const setQuery = useSetAtom(discoverSearchQuery);
  const setUnstableDiscoverAtom = useSetAtom(unstableAtlasSearchAtom);
  const resultsLoader = useAtomValue(discoverSearchResultsAtom);

  const { t } = useTranslation();
  const branding = useAtomValue(brandingAtom);
  const { data: brandTones, loading } = useBrandTones();
  const brandTone = useMemo(() => brandTones?.find((b) => b.is_default), [brandTones]);
  const [sequences, , sequencesLoaded] = useFlag("sequences");

  const setShowSequencesAtom = useSetAtom(showSequencesAtom);

  useEffect(() => {
    if (sequencesLoaded) setShowSequencesAtom(sequences);
  }, [sequences, sequencesLoaded, setShowSequencesAtom]);

  useEffect(() => {
    setUnstableDiscoverAtom(unstable);
  }, [unstable, setUnstableDiscoverAtom]);

  useDebouncedEffect(
    () => {
      setQuery(q ?? null);
    },
    [q],
    500,
  );

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    template: DiscoverTemplate,
  ): void => {
    switch (template.type) {
      case TemplateType.moment:
        navigate({
          pathname: `/discover/${template.program.slug}/${template.slug}`,
          ...(brandTone
            ? {
                search: `?${new URLSearchParams({
                  brand_tone: brandTone?.id,
                }).toString()}`,
              }
            : {}),
        });
        break;
      case TemplateType.sequence:
        navigate(`/discover/sequence/${template.slug}`);
        break;
    }
  };

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={4}
      columns={{ xs: 2, sm: 3, md: 3, lg: 4, xl: 5 }}
      {...props}
    >
      {(resultsLoader.state === "loading" || loading) && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {resultsLoader.state === "hasError" && (
        <Grid item xs={12}>
          {t(
            "Sorry, we seem to be having trouble with that. Please try again later. If the problem persists, please contact support.",
          )}
        </Grid>
      )}
      {resultsLoader.state === "hasData" &&
        !loading &&
        resultsLoader.data?.map((template) => (
          <Grid item key={template.slug} xs={1} minWidth={250}>
            <DiscoverCard
              template={template}
              onClick={handleClick}
              brandKitSelections={branding}
              subheadingGetter={(_template: DiscoverTemplate): string | null =>
                programNameGetter(_template)
              }
            />
          </Grid>
        ))}
    </Grid>
  );
};
