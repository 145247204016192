import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CeTextInput } from "../generic/components/form-fields/ce-text-field";
import { CreateContentRequest } from "./types";

export const ContentDescriptionTextField: FC = () => {
  const { t } = useTranslation();
  return (
    <CeTextInput<CreateContentRequest>
      variant="outlined"
      name="q"
      label={t("Describe your content")}
      multiline
      fullWidth
      rows={3}
    />
  );
};
