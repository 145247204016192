import { Chip, ChipProps, Stack } from "@mui/material";
import { FC } from "react";

export interface PromptSuggestion {
  label: string;
  action: string;
}

export const PromptSuggestions: FC<{
  onUsePrompt: (prompt: string) => void;
  loading: boolean;
  suggestions: PromptSuggestion[];
}> = ({ onUsePrompt, loading, suggestions }) => (
  <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
    {suggestions.map((action) => (
      <PromptSuggestion key={action.action} onClick={onUsePrompt} disabled={loading} {...action} />
    ))}
  </Stack>
);

const PromptSuggestion: FC<
  { action: string; onClick: (action: string) => void } & Omit<ChipProps, "onClick">
> = ({ action, onClick, ...props }) => {
  const handleClick = (): void => {
    onClick(action);
  };
  return (
    <Chip role="button" variant="outlined" color="secondary" onClick={handleClick} {...props} />
  );
};
